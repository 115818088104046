import { addSnackbarMessage } from "../../Common/_plugin/error-response";
import api from "../_api";

/**
 * @memberof module:MailBox.store.actions
 * @param {Object} context store context
 * @param {Object} mailData mail data to be sent
 * @description sends mail data to the server, sets mail status
 */
const sendMail = (context, mailData) => {
  api
    .sendMail(mailData)
    .then(() => {
      context.commit("SET_MAIL_STATUS", "SUCCESS");
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Mail has been successfully sent!",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      context.commit("SET_MAIL_STATUS", "FAILURE");
      console.error(error);
      addSnackbarMessage(error, "Problem sending mail!", true);
    });
};

/**
 * @memberof module:MailBox.store.actions
 * @param {Object} context store context
 * @param {Object} template template data to be added
 * @description add template and commit it to the list
 */
const addTemplate = (context, template) => {
  api
    .addTemplate(template)
    .then((response) => {
      context.commit("ADD_TEMPLATE", response.data);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Template has been successfully added!",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem adding template!", true);
    });
};

/**
 * @memberof module:MailBox.store.actions
 * @param {Object} context store context
 * @param {String} key identifier for retrieving templates
 * @description fetch the templates
 */
const getTemplates = (context, key) => {
  if (key === "RunCampaign" || key === "MailTo") key = null;
  api
    .getTemplates(key)
    .then((response) => {
      context.commit("GET_TEMPLATES", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem loading template!", true);
    });
};

/**
 * @memberof module:MailBox.store.actions
 * @param {Object} context store context
 * @param {Number} templateId id of template to be deleted
 * @description delete the template and commit it to the list
 */
const deleteTemplate = (context, templateId) => {
  api
    .deleteTemplate(templateId)
    .then(() => {
      context.commit("DELETE_TEMPLATE", templateId);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Template successfully deleted!",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem deleting template!", true);
    });
};

/**
 * @memberof module:MailBox.store.actions
 * @param {Object} context store context
 * @param {Object} template template data to be updated
 * @description update the template and commit it to the list
 */
const updateTemplate = (context, template) => {
  api
    .updateTemplate(template)
    .then(() => {
      context.commit("UPDATE_TEMPLATE", template);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Template successfully updated!",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "problem updating template!", true);
    });
};

const updateTemplateOrder = (context, template) => {
  const extractedData = [];

  for (const item of template) {
    const { id, order } = item;
    extractedData.push({ id, order });
  }
  api
    .updateTemplateOrder(extractedData)
    .then(() => {
      context.commit("UPDATE_TEMPLATE_ORDER", extractedData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Template order successfully updated!",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "problem updating template!", true);
    });
};

/**
 * @memberof module:MailBox.store.actions
 * @param {Object} context store context
 * @param {Object} applicantsData data of applicants to be mailed
 * @description sets applicants data to be used in mailbox component for mailing purposes
 */
const setApplicantsData = (context, applicantsData) => {
  context.commit("SET_APPLICANTS_DATA", applicantsData.data);
};

const setEntitiesData = (context, entitiesData) => {
  context.commit("SET_ENTITIES_DATA", entitiesData.data);
};

/**
 * @memberof module:MailBox.store.actions
 * @param {Object} context store context
 * @param {Object} campusData data of campus applicants to be mailed
 * @description sets campus data to be used in mailbox component for mailing purposes
 */
const setCampusData = (context, campusData) => {
  context.commit("SET_CAMPUS_DATA", campusData);
};

/**
 * @memberof module:MailBox.store.actions
 * @param {Object} context store context
 * @param {Array} availableMailBodies contains id of available mail bodies
 * @description sets array of available mail body ids to be used in template component for generating checkbox of target bodies
 */
const setAvailableMailBodies = (context, availableMailBodies) => {
  context.commit("SET_AVAILABLE_MAIL_BODIES", availableMailBodies);
};

/**
 * @memberof module:MailBox.store.actions
 * @param {Object} context store context
 * @param {Array} updatedMailData contains mail data to be updated
 * @description make call to api for updating mail data and after that sets success status
 */
const updateMailData = (context, updatedMailData) => {
  api
    .updateMailData(updatedMailData)
    .then(() => {
      context.commit("MAIL_UPDATE_SUCCESS_STATUS");
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Mail Status Updated!",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem Updating Status!", true);
    });
};

/**
 * @memberof module:MailBox
 * @namespace store.actions
 * @description actions for mailbox module
 */
export default {
  sendMail,
  addTemplate,
  getTemplates,
  deleteTemplate,
  updateTemplate,
  setApplicantsData,
  setEntitiesData,
  setCampusData,
  setAvailableMailBodies,
  updateMailData,
  updateTemplateOrder,
};
