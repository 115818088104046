<template>
  <div>
    <label :for="'input-select-' + _uid" v-if="showLabels">
      {{ label }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div class="input-parent" :class="inputAttributes.parentClass">
      <select
        class="form-control"
        :id="'input-select-' + _uid"
        :value="value"
        @input="handleInput"
        :disabled="disabled"
        v-bind="inputAttributes.attrs"
        v-on="inputAttributes.vOn"
        :class="[
          inputAttributes.class || 'form-control-sm mb-1',
          !value ? 'no-value' : '',
        ]"
      >
        <option v-if="properties.placeholder" :value="null" selected disabled>
          {{ properties.placeholder }}
        </option>
        <option
          v-for="(option, idx) in properties.options"
          :key="idx"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>

    <template v-if="showErrors">
      <p class="text-danger" v-for="(error, idx) in errorList" :key="idx">
        <b-icon-exclamation-circle></b-icon-exclamation-circle>
        <span class="ms-2">{{ error }}</span>
      </p>
    </template>
  </div>
</template>

<script>
import { processAttributes } from "@/modules/User/_utils/actions";
import validators from "../../_utils/validators";

export default {
  name: "line-input-builder",
  props: {
    value: String,
    label: String,
    properties: Object,
    useValidation: Boolean,
    validators: Array,
    disabled: { type: Boolean, default: false },
    inputAttrs: { type: Object, default: () => ({}) },
    showLabels: { type: Boolean, default: true },
    showErrors: { type: Boolean, default: true },
  },
  data() {
    return {
      errorList: [],
      previewInputClass: "",
      previewError: "",
    };
  },
  computed: {
    inputAttributes() {
      return processAttributes(this.inputAttrs);
    },
    isRequired() {
      return (
        this.useValidation &&
        this.validators.some((e) => e.validator === "required")
      );
    },
  },
  methods: {
    checkError(input) {
      this.errorList = [];

      for (const validator of this.validators) {
        const err = validators[validator.validator](input, validator);
        if (err) {
          this.errorList.push(err);
        }
      }
      if (this.errorList.length !== 0) {
        this.$emit("error", {
          key: this.key,
          errors: this.errorList,
        });
        return true;
      }
      return false;
    },
    handleInput(e) {
      this.$emit("input", e.target.value);
      this.useValidation && this.checkError(e.target.value);
    },
  },
};
</script>
