import VueCookie from "vue-cookie";
import userApi from "../../User/_api/index";
import systemConfigApi from "../../SystemConfig/_api/index";
import store from "../../../store/index";
import { PROJECT_NAME } from "../../../config";

export const setLoggedInUser = () => {
  const user = VueCookie.get("user");
  if (user) {
    const userData = JSON.parse(user.substring(2));
    store.dispatch("user/setLoggedInUser", userData);
  }
};

export const setPermissions = async () => {
  try {
    const permissions = await userApi.getPermissions();
    store.dispatch("user/setPermissions", permissions.data.permissions);
  } catch (error) {
    store.dispatch("user/setPermissions", []);
  }
};
export const setPublicKeys = async () => {
  try {
    const keys = await systemConfigApi.fetchPublicSystemConfigsObject();
    store.dispatch("system_config/applyPublicConfigByRoute", keys.data);
  } catch (error) {
    store.dispatch("system_config/applyPublicConfigByRoute", {});
  }
};
export const hasAccess = ({ permissions, checkAll }) => {
  return hasPermission({ permissions, checkAll });
};

export const hasPermission = ({ permissions, checkAll = true }) => {
  const checkMethod = checkAll ? "every" : "some";
  const userPerms = store.getters["user/permissions"];

  if (
    !userPerms ||
    !Object.keys(userPerms).length ||
    !permissions ||
    !permissions.length
  )
    return false;

  const allPermissionTitle = PROJECT_NAME + "_ALL-PERMISSION";

  const hasAllPermissions = permissions[checkMethod]((permission) => {
    if (!permission) return false;
    const p_title = [PROJECT_NAME, ...permission.title].join("_");

    if (userPerms[allPermissionTitle]) return true;
    return userPerms[p_title];
  });

  return hasAllPermissions;
};

export const processAttributes = (attrs) => {
  const result = {
    vOn: {},
    class: "",
    attrs: {},
    parentClass: "",
    wrapperClass: "",
  };

  for (const [key, value] of Object.entries(attrs)) {
    if (key.startsWith("@")) {
      result.vOn[key.slice(1)] = value;
    } else if (key === "class") {
      result.class = value;
    } else if (key === "parentClass") {
      result.parentClass = value;
    } else if (key === "wrapperClass") {
      result.wrapperClass = value;
    } else {
      result.attrs[key] = value;
    }
  }

  return result;
};
