<template>
  <div :class="{ 'mb-3': !isFullHeight().marginBottom }">
    <CollapseCard
      class="theme-default"
      title="Events Calendar"
      :midDivider="false"
      header-class="px-0 py-0"
      :collapse="!isFullHeight().marginBottom"
      title-class="h5 m-0"
    >
      <template v-slot:right>
        <div class="d-flex justify-content-end align-items-center">
          <span class="ml-auto mr-1">Pending Events</span>
          <div>
            <span class="bg-secondary px-2 rounded ml-auto ml-2 text-white">{{
              pendingCampusEvents.length + pendingEvents.length
            }}</span>
          </div>
        </div>
      </template>
      <div class="p-2">
        <div class="">
          <div class="row">
            <div class="d-flex w-100 justify-content-between mb-3 px-3">
              <button
                class="btn btn-sm"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFilters"
                aria-expanded="false"
                aria-controls="collapseFilters"
                style="border: 1px solid #8f1452; color: #8f1452"
              >
                <b-icon icon="filter-left"></b-icon>Filters
              </button>
              <button
                class="btn btn-sm"
                type="button"
                data-toggle="collapse"
                data-target="#collapsePendingEvents"
                aria-expanded="false"
                aria-controls="collapsePendingEvents"
                style="border: 1px solid #8f1452; color: #8f1452"
              >
                Pending Events
              </button>
            </div>
            <div class="collapse w-100 filter-pending" id="collapseFilters">
              <div
                class="card card-body p-2"
                style="max-width: 300px; height: 100%"
              >
                <div style="display: flex; justify-content: space-between">
                  <h5>Filters</h5>
                  <div>
                    <button
                      class="btn btn-sm border"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFilters"
                      aria-expanded="false"
                      aria-controls="collapseFilters"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <div class="applicant-schedule-sidebar-section row pl-4">
                  <div class="col-12">
                    <input
                      v-if="$refs.calendar"
                      id="includeWeekends"
                      type="checkbox"
                      class="form-check-input"
                      :checked="
                        $refs['calendar']
                          ? $refs['calendar'].defaultCalendarOptions.weekends
                          : true
                      "
                      @change="weekendToggle"
                    />
                    <label for="includeWeekends"> Include Weekends</label>
                  </div>
                  <div class="col-12">
                    <input
                      id="showAll"
                      v-if="$refs.calendar"
                      type="checkbox"
                      class="form-check-input"
                      :checked="
                        $refs['calendar']
                          ? !$refs['calendar'].defaultCalendarOptions
                              .dayMaxEvents
                          : true
                      "
                      @change="dayToggle"
                    />
                    <label for="showAll"> Show All Events</label>
                  </div>
                  <div
                    class="col-12"
                    v-if="
                      hasAccess({
                        permissions: [
                          BASE_PERMISSION.READ,
                          PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                        ],
                        checkAll: false,
                      })
                    "
                  >
                    <input
                      id="mySchedule"
                      type="checkbox"
                      class="form-check-input"
                      @change="toggleMyRounds"
                    />
                    <label for="mySchedule">My Schedules</label>
                  </div>
                  <div
                    class="col-12"
                    v-if="
                      hasAccess({ permissions: [PERMISSIONS.APPLICANT.READ] })
                    "
                  >
                    <input
                      id="claimedByMe"
                      type="checkbox"
                      class="form-check-input"
                      @change="toggleClaimedByMe"
                    />
                    <label for="claimedByMe">Claimed By Me</label>
                  </div>
                </div>
                <div class="applicant-schedule-sidebar-section">
                  <h5>Filters</h5>
                  <div
                    class="col-12 p-0 mb-2"
                    v-if="
                      hasAccess({
                        permissions: [
                          PERMISSIONS.APPLICANT.READ,
                          PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                        ],
                        checkAll: false,
                      })
                    "
                  >
                    <label htmlFor="interviewedBy"
                      >Interviewers/Reviewers</label
                    >
                    <multiselect
                      v-model="selectedUsers"
                      :multiple="true"
                      :options="users"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      label="name"
                      track-by="name"
                      :preselect-first="true"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel="*"
                      @input="filterEvents"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars -->
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length && !isOpen"
                          >{{ values.length }} options selected</span
                        ></template
                      >
                    </multiselect>
                  </div>
                  <div class="col-12 p-0">
                    <label htmlFor="events">Include Events</label><br />
                    <select
                      v-model="selectedType"
                      class="form-control"
                      @change="filterEvents"
                    >
                      <option value="">All Events</option>
                      <option value="Aptitude">Aptitude Only</option>
                      <option value="Written Test">Written Test Only</option>
                      <option value="Interview">Interviews Only</option>
                      <option value="Review">Reviews Only</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="collapse w-100 filter-pending pending-list"
              id="collapsePendingEvents"
            >
              <div
                class="card card-body p-2"
                style="
                  max-width: 300px;
                  height: 100%;
                  margin-left: auto;
                  overflow-y: auto;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <h5>Pending Events</h5>
                  <div>
                    <button
                      class="btn btn-sm border"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsePendingEvents"
                      aria-expanded="false"
                      aria-controls="collapsePendingEvents"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="applicant-schedule-sidebar-section"
                  style="flex-grow: 1; overflow-y: auto"
                  v-if="
                    pendingEvents.length > 0 || pendingCampusEvents.length > 0
                  "
                >
                  <div class="pending-events-section m-0">
                    <h6>Applicant Events - ({{ pendingEvents.length }})</h6>
                    <div
                      class="mb-2"
                      v-for="event in pendingEvents"
                      :key="event.id"
                    >
                      <span
                        style="margin-top: 3px"
                        class="event-circle"
                        :title="event.roundName"
                      >
                        {{ event.round }}
                      </span>
                      <p class="mb-0 font-weight-bold">
                        {{ event.start | formatDateTime }}
                      </p>
                      <div style="margin-left: 30px">
                        <i :title="event.withUsers">
                          <router-link
                            target="_blank"
                            :to="{
                              name: 'applicantprofile',
                              params: { slug: event.slug },
                            }"
                            class="link-class"
                            >{{ event.title | truncate(30, "..") }}</router-link
                          >
                        </i>
                      </div>
                    </div>
                    <h6 class="mt-4">
                      Campus Events - ({{ pendingCampusEvents.length }})
                    </h6>
                    <div
                      class="schedule-app-list"
                      v-for="(event, index) in pendingCampusEvents"
                      :key="index"
                    >
                      <span
                        style="margin-top: 3px"
                        class="event-circle"
                        :title="event.round_name"
                        >{{ event.round }}</span
                      >
                      <b>
                        {{ formatCampusDate(event.round_schedule) }} -
                        {{ formatCampusDate(event.round_schedule_end) }}
                      </b>
                      <div
                        v-if="
                          hasAccess({
                            permissions: [
                              PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                            ],
                            checkAll: false,
                          })
                        "
                      >
                        <i>
                          <span
                            style="margin-top: 3px"
                            class="event-circle event-type-circle"
                            :title="'Campus Event'"
                            >C</span
                          >
                          <router-link
                            target="_blank"
                            :to="{
                              name: 'scheduleEventSlot',
                              params: { eventId: event.id },
                            }"
                            class="link-class"
                          >
                            {{ event.title | truncate(30, "..") }}</router-link
                          >
                        </i>
                      </div>
                      <div v-else>
                        <i>
                          <span
                            style="margin-top: 3px"
                            class="event-circle event-type-circle"
                            :title="'Campus Event'"
                            >C</span
                          >{{ event.title | truncate(30, "..") }}</i
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  <h5 class="m-0">No Pending Events</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <common-calendar
          ref="calendar"
          :is-calendar-view="isCalendarView"
          :calendarOption="calendarOptions"
          :filterObj="filters"
          :api="apiCall"
          :classes="'fullcalendar-header-style-md'"
          :setEventsandUsers="setEventsandUsers"
          :onEventClick="handleCalendarClick"
          :filters="{ setTableButton: true, selectedUsers }"
        ></common-calendar>
      </div>
      <div class="card_footer pb-2 d-flex justify-content-between">
        <div class="d-flex pl-2">
          <router-link
            v-if="
              hasAccess({
                permissions: [PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU],
              })
            "
            :to="{ name: 'RecruitmentCampusSchedule' }"
          >
            Campus Drive
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </router-link>
        </div>
        <div class="text-right pr-2">
          <router-link :to="{ name: 'applicantSchedule' }">
            Full Calendar
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </router-link>
        </div>
      </div>
    </CollapseCard>
    <b-modal
      ref="eventModal"
      :title="eventModalData.modalTitle"
      :size="eventModalData.modalSize"
      :hide-footer="eventModalData.hideFooter"
    >
      <EventModal
        :isEdit="eventModalData.isEdit"
        v-on:generateRound="generateRound($event)"
        v-on:updateEvent="updateEvent($event)"
        :editEventApplicant="editEventApplicantData"
      />
    </b-modal>
    <b-modal
      ref="campusEventModal"
      :title="eventModalData.modalTitle"
      :size="eventModalData.modalSize"
      :hide-footer="eventModalData.hideFooter"
    >
      <CampusEventModal
        :campusSchedule="false"
        v-on:closeEventModal="closeEventModal"
        :isEdit="eventModalData.isEdit"
        :selectedDate="selectedDate"
        :editEvent="editEvent"
        v-on:generateRound="generateRound($event)"
        v-on:updateEvent="updateEvent($event)"
      />
    </b-modal>
    <edit-round-modal
      v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.UPDATE] })"
      :showApplicantName="true"
      :applicantData="this.applicantData"
    />
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
    <schedule-round-modal
      v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.CREATE] })"
      :applicant="applicantData"
      :calendarDateTime="selectedDate"
      :inPlaceAction="true"
    />
  </div>
</template>

<script>
import campusRecruitmentApi from "../../CampusRecruitment/_api/index";
import apiApplicantProfile from "../../ApplicantProfile/_api";
import applicationApi from "../../Applications/_api/index";
import Multiselect from "vue-multiselect";
import EventModal from "../../Applications/_components/event-modal.vue";
import CommonModal from "../../Common/_components/modal.vue";
import scheduleRoundModal from "../../Applications/_components/schedule-round-modal.vue";
import EditRoundModal from "../../ApplicantProfile/_components/edit-round-modal.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import CampusEventModal from "../../CampusRecruitment/_components/event-modal.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";
import CommonCalendar from "../../Common/_components/common-calendar.vue";

export default {
  props: {
    leftLength: {},
    rightLength: {},
  },
  components: {
    Multiselect,
    EventModal,
    CommonModal,
    scheduleRoundModal,
    EditRoundModal,
    CampusEventModal,
    CollapseCard,
    CommonCalendar,
  },
  data: function () {
    return {
      isSmallScreen: false,
      myRounds: false,
      ovserver: null,
      users: [],
      selectedUsers: [],
      campusEvents: [],
      editEvent: null,
      selectedType: "",
      calendarOptions: {
        height: this.isFullHeight().height,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridWeek,dayGridDay",
        },
        initialView: window.innerWidth > 576 ? "dayGridWeek" : "dayGridDay",
        select: this.handleDateSelect,
      },
      claimedByMe: false,
      pendingEvents: [],
      pendingCampusEvents: [],
      allEvents: [],
      eventModalData: {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      },
      applicantData: null,
      selectedDate: null,
      isCalendarView: true,
      editEventApplicantData: null,
      currentDate: new Date(),
      rowSelection: null,
      columnDefs: [
        {
          headerName: "Round",
          field: "roundName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Candidate",
          field: "title",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Status",
          field: "status",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Position",
          field: "position",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Time",
          field: "start",
          valueFormatter: this.formatDate,
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            comparator: function (filterDate, cellValue) {
              const formattedDate = moment(cellValue).format("DD/MM/YY");
              const filterText = moment(filterDate).format("DD/MM/YY");
              return formattedDate.includes(filterText) ? 0 : 1;
            },
          },
        },
        {
          headerName: "Interviewer/Reviewer",
          field: "interviewers",
          filter: "agTextColumnFilter",
        },
      ],
    };
  },
  created() {
    this.rowSelection = "single";
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      applicant_Profile_Data: "$_applicant_profile/fetchApplicantData",
      applicantRoundsData: "$_applicant_profile/fetchRoundDetails",
      confirmRoundScheduleStatus:
        "$_applicants_data/fetchConfirmRoundScheduleStatus",
      loggedInUser: "user/loggedInUser",
    }),
    filters() {
      return {
        ...(this.claimedByMe ? { claimedBy: this.loggedInUser.user_id } : {}),
        myRounds: this.myRounds,
        round: this.selectedType,
        isValid: true,
      };
    },
    apiCall() {
      return applicationApi.fetchAllSchedule;
    },
    weekendToggle() {
      return this.$refs.calendar.handleWeekendsToggle;
    },
    dayToggle() {
      return this.$refs.calendar.handleDayMaxEventsToggle;
    },
  },
  methods: {
    isFullHeight() {
      const screenSize = window.innerWidth;
      const isSmallScreen = screenSize < 992;
      if (isSmallScreen) {
        let height = "480px";
        let fullHeight = this.rightLength === 0 && this.leftLength === 1;
        if (fullHeight) {
          if (screenSize >= 360) {
            height = "calc(100vh - 225px)";
          }
          if (screenSize < 360) {
            height = "calc(100vh - 250px)";
          }
          if (screenSize < 320) {
            height = "calc(100vh - 260px)";
          }
        }
        return {
          fullHeight,
          marginBottom: fullHeight,
          collapse: !fullHeight,
          height,
        };
      } else {
        let fullHeight = this.leftLength === 0 && this.rightLength === 1;
        return {
          fullHeight,
          marginBottom: fullHeight || this.rightLength === 1,
          collapse: !(fullHeight || this.rightLength === 1),
          height:
            fullHeight || this.rightLength === 1
              ? "calc(100vh - 225px)"
              : "480px",
        };
      }
    },
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth < 768; // Example breakpoint for small screens
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    closeEventModal() {
      this.$refs["campusEventModal"].hide();
    },
    handleCalendarClick(event, type) {
      if (type === "event") this.handleEventClick(event);
      if (type === "campus") this.handleCampusEventClick(event);
    },
    /**
     * @memberof module:CampusRecruitment.components.campus_schedule
     * @description handle click event and open edit event modal
     */
    handleCampusEventClick(clickInfo) {
      this.editEvent = null;
      const foundEvent = this.campusEvents.find((event) => {
        return event.id == clickInfo.event.id;
      });
      this.editEvent = foundEvent;
      this.$store.dispatch("$_campus_recruitment/resetSelectedCampus");
      const filter = "?campusId=" + clickInfo.event.extendedProps.campusId;
      campusRecruitmentApi
        .fetchCampuses(filter)
        .then((res) => {
          this.$store.dispatch(
            "$_campus_recruitment/setSelectedCampus",
            res.data[0]
          );
          this.eventModalData = {
            modalTitle: "Edit Event",
            modalSize: "xl",
            hideFooter: true,
            isEdit: true,
          };
          this.$refs["campusEventModal"].show();
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem retrieving data!",
            },
            { root: true }
          );
        });
    },
    toggleView() {
      this.isCalendarView = !this.isCalendarView;
    },
    toggleClaimedByMe() {
      this.claimedByMe = !this.claimedByMe;
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },
    toggleMyRounds() {
      this.myRounds = !this.myRounds;
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },
    handleDateSelect(selectInfo) {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.eventModalData = {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      };
      const selectDate = new Date(selectInfo.startStr).setHours(0, 0, 0, 0);
      const currentDate = new Date().setHours(0, 0, 0, 0);
      if (selectDate < currentDate) {
        // show invalid modal
        this.openDialog(
          "invalidEventModal",
          "Invalid Input",
          "Please select a future date & time for the event!",
          false,
          "danger",
          "modal-md"
        );
        return;
      }
      this.selectedDate = selectInfo;
      this.$refs["eventModal"].show();
    },

    tabularCreateEvent() {
      this.eventModalData = {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      };
      this.$refs["eventModal"].show();
    },
    tabularEventClick(data) {
      if (data.node.data.status !== "Pending") {
        return;
      }

      this.eventModalData = {
        modalTitle: "Edit Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };

      apiApplicantProfile
        .fetchApplicantData(data.node.data.slug)
        .then((response) => {
          this.editEventApplicantData = response.data;
        });
      this.$refs["eventModal"].show();
    },
    handleEventClick(clickInfo) {
      if (clickInfo.event.extendedProps.status !== "Pending") {
        return;
      }

      this.eventModalData = {
        modalTitle: "Edit Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };

      apiApplicantProfile
        .fetchApplicantData(clickInfo.event.extendedProps.slug)
        .then((response) => {
          this.editEventApplicantData = response.data;
        });
      this.$refs["eventModal"].show();
    },
    generateRound(applicant) {
      this.$refs["eventModal"].hide();
      this.applicantData = applicant;
      this.selectedDate = $("#addRound").modal("show");
    },
    updateEvent(applicant) {
      this.$refs["eventModal"].hide();
      this.applicantData = applicant;
      this.selectedDate = $("#editRound").modal("show");
    },
    formatDate(date) {
      if (date.value === null) return null;
      else if (date.value) return moment(date.value).format("llll");
      else return moment(date).format("llll");
    },
    formatCampusDate(dateString) {
      return moment(dateString).format("DD/MMM/YY");
    },
    setEventsandUsers({
      users,
      campusEvents,
      pendingApplicantEvents,
      pendingCampusEvents,
    }) {
      this.users = users;
      this.campusEvents = campusEvents;
      this.pendingEvents = pendingApplicantEvents;
      this.pendingCampusEvents = pendingCampusEvents;
    },

    filterEvents() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },

    getMoodleInstances() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"]["MOODLE"].MENU],
        })
      )
        return;
      this.$store.dispatch("$_applicant_profile/getMoodleInstanceList");
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantsData.components.applicant_schedule
     * @param {String} value
     * @description Checks whether round has been added successfully or not then call filter event
     */
    confirmRoundScheduleStatus(value) {
      if (value === "SUCCESS") {
        this.filterEvents(null, null);
      }
    },
  },
  mounted() {
    this.getMoodleInstances();
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>
<style scoped>
.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  /* position: relative; */
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-type-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: gray;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-side-div {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
.applicant-schedule {
  width: 100%;
  padding: 20px;
}
.applicant-schedule-sidebar {
  width: 100%;
  height: 77vh;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
}

.applicant-schedule-sidebar-section {
  padding: 5px;
}
.title-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .fc-event {
  margin-top: 15px;
}
::v-deep .fc table thead a {
  font-weight: normal;
}
.card_footer a {
  color: #903564;
  /* text-transform: uppercase; */
  font-weight: 600;
  text-decoration: none;
}
.filter-pending {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(172, 172, 172, 0.486);
}
.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}
</style>
