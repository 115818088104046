<template>
  <div
    class="modal fade"
    id="editRound"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Edit Round</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group" v-if="showApplicantName">
              <label class="col-sm-3 control-label"
                >Applicant<span class="text-danger">*</span></label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  disabled
                  class="form-control"
                  :value="applicantFullName"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label"
                >Round Type<span class="text-danger">*</span></label
              >
              <div class="col-sm-9">
                <select
                  disabled
                  class="form-control"
                  v-model="round.round_name"
                >
                  <option>Aptitude</option>
                  <option>Written Test</option>
                  <option>Interview</option>
                  <option>Review</option>
                </select>
              </div>
            </div>
            <div v-if="round.round_name === 'Interview'">
              <div class="form-group">
                <label class="col-sm-3 control-label"
                  >Interviewers<span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                  <select
                    id="interviewerpicker"
                    v-model="round.interviewers_id"
                    class="selectpicker"
                    data-live-search="true"
                    multiple
                    data-width="100%"
                  >
                    <option
                      v-for="(interviewer, index) in usersDetails"
                      :key="index"
                      :value="interviewer.user_id"
                    >
                      {{ interviewer.first_name }} {{ interviewer.last_name }}
                    </option>
                  </select>
                  <p
                    v-if="
                      !$v.round.interviewers_id.required &&
                      $v.round.interviewers_id.$error
                    "
                    class="alert alert-danger"
                  >
                    This field is required
                  </p>
                </div>
              </div>
            </div>
            <div v-if="round.round_name === 'Review'">
              <div class="form-group">
                <label class="col-sm-3 control-label"
                  >Reviewers<span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                  <select
                    id="reviewerpicker"
                    v-model="round.reviewers_id"
                    class="selectpicker"
                    data-live-search="true"
                    multiple
                    data-width="100%"
                  >
                    <option
                      v-for="(reviewer, index) in usersDetails"
                      :key="index"
                      :value="reviewer.user_id"
                    >
                      {{ reviewer.first_name }} {{ reviewer.last_name }}
                    </option>
                  </select>
                  <p
                    v-if="
                      !$v.round.reviewers_id.required &&
                      $v.round.reviewers_id.$error
                    "
                    class="alert alert-danger"
                  >
                    This field is required
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="
                round.round_name === 'Interview' ||
                round.round_name === 'Review'
              "
            >
              <div class="form-group">
                <label class="col-sm-3 control-label">Template</label>
                <div class="col-sm-9">
                  <select
                    v-if="
                      round.round_name === 'Interview' ||
                      round.round_name === 'Review'
                    "
                    id="formTemplatepicker"
                    v-model="round.formMasterId"
                    class="selectpicker"
                    data-live-search="true"
                    data-width="100%"
                    multiple
                    data-max-options="1"
                  >
                    <option
                      v-for="(template, index) in formTemplates.filter(
                        (f) => f.status === 'A'
                      )"
                      :key="index"
                      :value="template.id"
                    >
                      {{ template.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label"
                >Schedule
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <div class="input-group">
                  <date-picker
                    v-model="round.round_schedule"
                    :config="config"
                    id="datePickerEdit"
                    @dp-hide="datePickerRequired"
                  >
                  </date-picker>
                  <span class="input-group-addon" @click="toggleDatePicker">
                    <i class="fa fa-calendar m-2" aria-hidden="false"> </i>
                  </span>
                </div>
                <p v-if="datePickerFlag" class="alert alert-danger">
                  This field is required
                </p>
              </div>
            </div>
            <div class="form-group" v-if="round.round_name === 'Interview'">
              <label class="col-sm-3 control-label">Duration</label>
              <div class="col-sm-9">
                <select v-model="round.interviewDuration" class="form-control">
                  <option
                    v-for="(minutes, index) in interviewDurationMinutes"
                    :key="index"
                    :value="minutes"
                  >
                    {{ minutes + " minutes" }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="form-group"
              v-if="
                round.round_name === 'Aptitude' ||
                round.round_name === 'Written Test'
              "
            >
              <div>
                <label class="col-sm-9 control-label">Select Instance</label>
                <div class="col-sm-9">
                  <select
                    class="custom-select main college mr-2"
                    v-model="round.instanceId"
                  >
                    <option disabled value="">Select Instance</option>
                    <option
                      v-for="instance in moodleInstanceList"
                      :value="instance.id"
                      :key="instance.id"
                    >
                      {{ instance.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <label class="col-sm-9 control-label">Select Course</label>
                <div class="col-sm-9">
                  <select
                    class="custom-select main college mr-2"
                    v-model="round.courseId"
                  >
                    <option disabled value="">Select Course</option>
                    <option
                      v-for="course in courseList"
                      :value="course.id"
                      :key="course.id"
                    >
                      {{ course.displayname }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              v-if="applicantData && applicantData.belongsToCampusRecruitement"
            >
              <div class="form-group">
                <label class="col-sm-3 control-label"
                  >To be Mailed
                  <span class="text-danger">*</span>
                </label>
                <label class="checkbox-inline">
                  <input
                    type="checkbox"
                    value="Coordinator"
                    v-model="isCoordinator"
                  />
                  Coordinator
                </label>
                <label class="checkbox-inline">
                  <input type="checkbox" value="Students" v-model="isStudent" />
                  Applicants
                </label>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            v-if="applicantData && applicantData.belongsToCampusRecruitement"
            :disabled="
              $v.round.$invalid ||
              (isCoordinator == false && isStudent == false)
            "
            type="button"
            class="btn btn-dark"
            @click="cancelCampusRound"
            data-dismiss="modal"
          >
            Cancel Round and Mail
          </button>
          <button
            v-else
            :disabled="$v.round.$invalid"
            type="button"
            class="btn btn-dark"
            @click="cancelRound"
            data-dismiss="modal"
          >
            Cancel Round and Mail
          </button>
          <button
            v-if="applicantData && applicantData.belongsToCampusRecruitement"
            :disabled="
              $v.round.$invalid ||
              (isCoordinator == false && isStudent == false)
            "
            type="button"
            class="btn btn-cp ml-2"
            @click="editCampusRound"
            data-dismiss="modal"
          >
            Save and Mail
          </button>
          <button
            v-else
            :disabled="$v.round.$invalid"
            type="button"
            class="btn btn-cp ml-2"
            @click="editRound"
            data-dismiss="modal"
          >
            Save and Mail
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import datePicker from "vue-bootstrap-datetimepicker";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import campusRecruitmentApi from "../../CampusRecruitment/_api/index";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.edit_round_modal
 * @description Edit round modal component
 */
export default {
  props: ["applicantData", "showApplicantName"],
  data() {
    return {
      courseList: [],
      round: {
        id: null,
        round_name: null,
        round_schedule: null,
        isMailed: null,
        round_result: null,
        interviewers_id: [],
        reviewers_id: [],
        formMasterId: [],
        applicantIterationMasterId: null,
        interviewDuration: 30,
        instanceId: null,
        courseId: null,
      },
      interviewDurationMinutes: [15, 30, 45, 60, 90],
      config: {
        format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        minDate: new Date(),
        sideBySide: true,
      },
      datePickerFlag: false,
      isCoordinator: false,
      isStudent: false,
      interviewerChecker: false,
      reviewerChecker: false,
      formTemplateChecker: false,
    };
  },
  components: {
    datePicker,
  },
  computed: {
    applicantFullName() {
      let fn = "",
        ln = "";
      if (this.applicantData) {
        if (this.applicantData.first_name) {
          fn = this.applicantData.first_name;
        }
        if (this.applicantData.last_name) {
          ln = this.applicantData.last_name;
        }
      }
      return fn + " " + ln;
    },
    ...mapGetters({
      data: "$_applicant_profile/fetchModalRoundData",
      campusMails: "$_applicants_data/fetchCampusMails",
      usersDetails: "$_applicant_profile/fetchUsersDetails",
      formTemplates: "$_form_template/getForms",
      moodleInstanceList: "$_applicant_profile/fetchMoodleInstanceList",
    }),
  },
  validations() {
    const round = {
      round_schedule: {
        required,
      },
    };
    if (this.round.round_name === "Interview") {
      round.interviewers_id = {
        required,
      };
    }
    if (this.round.round_name === "Review") {
      round.reviewers_id = {
        required,
      };
    }
    return {
      round,
    };
  },
  methods: {
    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @description Date picker validations
     */
    datePickerRequired() {
      if (!this.round.round_schedule) {
        this.datePickerFlag = true;
      } else {
        this.datePickerFlag = false;
      }
    },

    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @description set the interviewers details
     */
    setInterviewersDetails() {
      this.round.interviewers_id.forEach((interviewer_id) => {
        this.usersDetails.forEach((element) => {
          if (element.user_id == interviewer_id) {
            this.round.interviewers.push({
              interviewer_id: element.user_id,
              interviewer_email: element.email_address,
              interviewer_name: element.first_name + " " + element.last_name,
            });
          }
        });
      });
    },

    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @description set the reviewers details
     */
    setReviewersDetails() {
      this.round.reviewers_id.forEach((reviewer_id) => {
        this.usersDetails.forEach((element) => {
          if (element.user_id == reviewer_id) {
            this.round.reviewers.push({
              reviewer_id: element.user_id,
              reviewer_email: element.email_address,
              reviewer_name: element.first_name + " " + element.last_name,
            });
          }
        });
      });
    },

    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @description Edit round details of applicant
     */
    editRound() {
      if (this.round.round_name == "Interview") {
        this.round.interviewers = [];
        this.setInterviewersDetails();
      } else if (this.round.round_name == "Review") {
        this.round.reviewers = [];
        this.setReviewersDetails();
      }
      this.round.isMailed = false;
      this.round.round_schedule = new Date(this.round.round_schedule);
      this.$store.dispatch("$_applicant_profile/editRound", {
        data: this.round,
        redirect: this.$route.fullPath,
      });
    },

    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @description Cancel round of applicant
     */
    cancelRound() {
      this.round.round_result = "Cancelled";
      this.round.isMailed = false;
      this.$store.dispatch("$_applicant_profile/cancelRound", {
        data: this.round,
        redirect: this.$route.fullPath,
      });
    },

    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @description Edit round of applicant belonging to campus
     */
    editCampusRound() {
      if (this.round.round_name == "Interview") {
        this.round.interviewers = [];
        this.setInterviewersDetails();
      } else if (this.round.round_name == "Review") {
        this.round.reviewers = [];
        this.setReviewersDetails();
      }

      this.round.isMailed = false;
      this.round.round_schedule = new Date(this.round.round_schedule);
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      this.$store.dispatch("$_applicant_profile/editCampusRound", {
        data: {
          roundData: this.round,
          type: key,
          toBeMailed: this.campusMails,
        },
        redirect: this.$route.fullPath,
      });
    },

    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @description Cancel round of applicant belonging to campus
     */
    cancelCampusRound() {
      this.round.round_result = "Cancelled";
      this.round.isMailed = false;
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      this.$store.dispatch("$_applicant_profile/cancelCampusRound", {
        data: {
          roundData: this.round,
          type: key,
          toBeMailed: this.campusMails,
        },
        redirect: this.$route.fullPath,
      });
    },
    toggleDatePicker() {
      $("#datePickerEdit").data("DateTimePicker").toggle();
    },
    getFormsTemplates() {
      this.$store.dispatch("$_form_template/getAllFormMasters");
    },
    setMinDate() {
      const today = new Date();
      const scheduleDate = this.round.round_schedule;
      this.config.minDate =
        today.toISOString().split("T")[0] >
        scheduleDate.toISOString().split("T")[0]
          ? today
          : scheduleDate;
    },
  },
  watch: {
    "round.instanceId"(value) {
      if (value) {
        campusRecruitmentApi
          .getAllCourses(this.round.instanceId)
          .then((res) => {
            this.courseList = res.data;
          })
          .catch(() => {
            this.courseList = [];
          });
      }
    },
    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @param {Object} value
     * @description Fill modal with round data for editing
     */
    data(value) {
      if (value === null) return;
      this.round.id = value.id;
      this.round.round_name = value.round_name;
      this.round.round_schedule = new Date(value.round_schedule);
      this.round.isMailed = value.isMailed;
      this.round.round_result = value.round_result;
      if (value.interviewers) {
        this.round.interviewers = value.interviewers;
        if (value.interviewers.length)
          this.round.formMasterId.push(+value.interviewers[0].formMasterId);
        this.round.interviewers_id.splice(0, this.round.interviewers_id.length);
        value.interviewers.forEach((element) => {
          this.round.interviewers_id.push(element.interviewer_id);
        });
      }
      if (value.reviewers) {
        this.round.reviewers = value.reviewers;
        if (value.reviewers.length)
          this.round.formMasterId.push(+value.reviewers[0].formMasterId);
        this.round.reviewers_id.splice(0, this.round.reviewers_id.length);
        value.reviewers.forEach((element) => {
          this.round.reviewers_id.push(element.reviewer_id);
        });
      }
      if (value.hasMoodle && value.hasMoodle.moodle_user_master) {
        this.round.instanceId =
          value.hasMoodle.moodle_user_master.moodle_instance_id;
        this.round.courseId = value.hasMoodle.moodle_course_id;
      }
      this.round.applicantIterationMasterId = value.applicantIterationMasterId;
      this.setMinDate();
    },

    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @param {Object} value
     * @description Fetch Co-ordinator emails belonging to particular campus
     */
    applicantData(value) {
      if (value.belongsToCampusRecruitement) {
        this.$store.dispatch(
          "$_applicants_data/fetchCampusEmails",
          value.belongsToCampusRecruitement.id
        );
      }
    },

    /**
     * @memberOf module:ApplicantProfile.components.edit_round_modal
     * @param {String} value
     * @description Marks if round is interview
     */
    "round.round_name"(value) {
      if (value === "Interview") {
        this.interviewerChecker = true;
        this.formTemplateChecker = true;
      }
      if (value === "Review") {
        this.reviewerChecker = true;
        this.formTemplateChecker = true;
      }
    },
    "round.formMasterId"(value) {
      this.round.formVersionId = [
        this.formTemplates.find((template) => template.id === value[0])
          ?.formVersion[0]?.id,
      ];
    },
  },
  updated() {
    if (this.interviewerChecker) {
      let interviewpicker = document.getElementById("interviewerpicker");
      if (interviewpicker != null) {
        $("#interviewerpicker")
          .selectpicker("destroy")
          .attr("title", "select interviewers")
          .selectpicker("render");
      }
      this.interviewerChecker = false;
    }
    if (this.reviewerChecker) {
      let reviewpicker = document.getElementById("reviewerpicker");
      if (reviewpicker != null) {
        $("#reviewerpicker")
          .selectpicker("destroy")
          .attr("title", "select reviewers")
          .selectpicker("render");
      }
      this.reviewerChecker = false;
    }
    if (this.formTemplateChecker) {
      let formpicker = document.getElementById("formTemplatepicker");
      if (formpicker != null) {
        $("#formTemplatepicker")
          .attr("title", "select template")
          .selectpicker("render");
      }
      this.formTemplateChecker = false;
    }
  },
  created() {
    this.getFormsTemplates();
  },
  beforeDestroy() {
    $("#editRound").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
