<template>
  <div
    class="modal fade"
    id="addRound"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Round Details</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group" v-if="applicant">
              <label class="col-sm-3 control-label" htmlFor="applicant"
                >Applicant</label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="roundApplicant"
                  v-model="applicantsFullName"
                  disabled
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" htmlFor="roundType"
                >Round Type</label
              >
              <div class="col-sm-9">
                <select
                  class="form-control"
                  id="roundTypeSelect"
                  v-model="round.round_name"
                >
                  <option value="Aptitude">Aptitude</option>
                  <option value="Written Test">Written Test</option>
                  <option value="Interview">Interview</option>
                  <option value="Review">Review</option>
                </select>
              </div>
            </div>
            <div v-if="round.round_name === 'Interview'">
              <div class="form-group">
                <label class="col-sm-3 control-label" htmlFor="interviewer"
                  >Interviewers</label
                >
                <div class="col-sm-9">
                  <select
                    v-if="round.round_name === 'Interview'"
                    id="interviewerpicker"
                    v-model="round.interviewers_id"
                    class="selectpicker"
                    data-live-search="true"
                    multiple
                    data-width="100%"
                  >
                    <option
                      v-for="(interviewer, index) in usersDetails"
                      :key="index"
                      :value="interviewer.user_id"
                    >
                      {{ interviewer.first_name }} {{ interviewer.last_name }}
                    </option>
                  </select>
                  <p v-if="isInterviewerTouched" class="alert alert-danger">
                    This field is required
                  </p>
                </div>
              </div>
            </div>
            <div v-if="round.round_name === 'Review'">
              <div class="form-group">
                <label class="col-sm-3 control-label" htmlFor="reviewers"
                  >Reviewers</label
                >
                <div class="col-sm-9">
                  <select
                    v-if="round.round_name === 'Review'"
                    id="reviewerpicker"
                    v-model="round.reviewers_id"
                    class="selectpicker"
                    data-live-search="true"
                    multiple
                    data-width="100%"
                  >
                    <option
                      v-for="(reviewer, index) in usersDetails"
                      :key="index"
                      :value="reviewer.user_id"
                    >
                      {{ reviewer.first_name }} {{ reviewer.last_name }}
                    </option>
                  </select>
                  <p v-if="isReviewerTouched" class="alert alert-danger">
                    This field is required
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="
                round.round_name === 'Interview' ||
                round.round_name === 'Review'
              "
            >
              <div class="form-group">
                <label class="col-sm-3 control-label" htmlFor="template"
                  >Template</label
                >
                <div class="col-sm-9">
                  <select
                    v-if="
                      round.round_name === 'Interview' ||
                      round.round_name === 'Review'
                    "
                    id="formTemplatepicker"
                    v-model="round.form_id"
                    class="selectpicker"
                    data-live-search="true"
                    data-width="100%"
                    multiple
                    data-max-options="1"
                  >
                    <option
                      v-for="(template, index) in activeTemplates"
                      :key="index"
                      :value="template.id"
                    >
                      {{ template.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" htmlFor="schedule"
                >Schedule</label
              >
              <div class="col-sm-9">
                <date-picker
                  v-model="round.round_schedule"
                  id="datePicker"
                  :config="config"
                  :minDate="new Date()"
                  @dp-hide="datePickerRequired"
                ></date-picker>
                <p v-if="datePickerFlag" class="alert alert-danger">
                  This field is required
                </p>
              </div>
            </div>
            <div v-if="isCampusStudent">
              <div class="form-group">
                <label class="col-sm-3 control-label" htmlFor="mailList"
                  >To be Mailed
                  <span class="text-danger">*</span>
                </label>
                <label class="checkbox-inline">
                  <input
                    type="checkbox"
                    value="Coordinator"
                    v-model="isCoordinator"
                  />
                  Coordinator
                </label>
                <label class="checkbox-inline">
                  <input type="checkbox" value="Students" v-model="isStudent" />
                  Applicants
                </label>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="$v.round.$invalid"
            type="button"
            class="btn btn-cp ml-2"
            @click="addRound"
            data-dismiss="modal"
          >
            Generate
          </button>
          <button
            :disabled="
              $v.round.$invalid ||
              (isCampusStudent && isCoordinator == false && isStudent == false)
            "
            type="button"
            class="btn btn-cp ml-2"
            @click="addRoundAndMail"
            data-dismiss="modal"
          >
            Generate and Mail
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import datePickerIcons from "../../Common/_utils/date-picker-icons";
/**
 * @memberOf module:ApplicantsData
 * @namespace components.schedule_round_modal
 * @description Schedule round modal component
 */
export default {
  props: {
    calendarDateTime: Object,
    applicant: Object,
    inPlaceAction: Boolean,
  },
  data() {
    return {
      bulkSchedules: [],
      isCampusStudent: false,
      toBeMailed: [],
      isCoordinator: false,
      isStudent: false,
      isInterviewerTouched: false,
      isReviewerTouched: false,
      isFormTemplateTouched: false,
      round: {
        round_name: "Aptitude",
        round_schedule: null,
        isMailed: false,
        round_result: "Pending",
        interviewers_id: [],
        reviewers_id: [],
        form_id: [],
      },
      interviewerChecker: false,
      reviewerChecker: false,
      formTemplateChecker: false,
      datePickerFlag: false,
      config: {
        format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
        icons: datePickerIcons,
      },
    };
  },
  validations() {
    const round = {
      round_schedule: {
        required,
      },
    };
    if (this.round.round_name === "Interview") {
      round.interviewers_id = {
        required,
      };
    }
    if (this.round.round_name === "Review") {
      round.reviewers_id = {
        required,
      };
    }
    return {
      round,
    };
  },
  computed: {
    applicantsFullName() {
      return this.applicant.first_name + " " + this.applicant.last_name;
    },
    ...mapGetters({
      selectedApplicants: "$_applicants_data/fetchSelectedApplicants",
      confirmRoundScheduleStatus:
        "$_applicants_data/fetchConfirmRoundScheduleStatus",
      campusMails: "$_applicants_data/fetchCampusMails",
      usersDetails: "$_applicant_profile/fetchUsersDetails",
      formTemplates: "$_form_template/getForms",
    }),
    activeTemplates() {
      return this.formTemplates.filter((template) => template.status === "A");
    },
  },
  components: {
    datePicker,
  },
  methods: {
    /**
     * @memberOf module:ApplicantsData.components.schedule_round_modal
     * @description Validation for date picker
     */
    datePickerRequired() {
      if (!this.round.round_schedule) {
        this.datePickerFlag = true;
      } else {
        this.datePickerFlag = false;
      }
    },

    /**
     * @memberOf module:ApplicantsData.components.schedule_round_modal
     * @description Builds an object which is used in adding round for applicant
     */
    generateRound() {
      this.selectedApplicants.forEach((applicant) => {
        let mydata = {
          round_name: this.round.round_name,
          round_schedule: new Date(this.round.round_schedule),
          isMailed: false,
          round_result: "Pending",
          interviewers_id: this.round.interviewers_id,
          reviewers_id: this.round.reviewers_id,
          applicantIterationMasterId: applicant.id,
          form_id: this.round.form_id,
          formversion_id: this.round?.formversion_id,
        };
        this.bulkSchedules.push(mydata);
      });
    },

    /**
     * @memberOf module:ApplicantsData.components.schedule_round_modal
     * @description Add round for applicant
     */
    addRound() {
      this.generateRound();
      this.$store.dispatch("$_applicants_data/addRound", this.bulkSchedules);
    },

    /**
     * @memberOf module:ApplicantsData.components.schedule_round_modal
     * @description Add round and mail for applicant
     */
    addRoundAndMail() {
      this.generateRound();
      let dataToBePassed = {
        applicantData: this.selectedApplicants,
        roundData: this.bulkSchedules,
        toBeMailed: {
          coordinatorEmails: this.campusMails,
        },
      };
      if (this.isCampusStudent) {
        if (this.isCoordinator && this.isStudent) {
          dataToBePassed.type = "both";
        } else if (this.isCoordinator) {
          dataToBePassed.type = "coordinator";
        } else if (this.isStudent) {
          dataToBePassed.type = "student";
        }
        this.$store.dispatch("$_applicants_data/addCampusRoundAndMail", {
          data: dataToBePassed,
          redirect: this.$route.fullPath,
        });
      } else {
        this.$store.dispatch("$_applicants_data/addRoundAndMail", {
          data: {
            applicantData: this.selectedApplicants,
            roundData: this.bulkSchedules,
          },
          redirect: this.$route.fullPath,
        });
      }
    },
    getUsers() {
      this.$store.dispatch("$_applicant_profile/usersDetails");
    },
    getFormsTemplates() {
      this.$store.dispatch("$_form_template/getAllFormMasters");
    },
  },
  watch: {
    calendarDateTime(value) {
      if (value.start) {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        let theDate = new Date(value.start);
        let formatedDate = "";
        formatedDate += theDate.getDate() + " ";
        formatedDate += months[theDate.getMonth()] + " ";
        formatedDate += theDate.getFullYear() + " ";
        if (theDate.getHours() == 0 && theDate.getMinutes() == 0) {
          formatedDate += "10:00";
        } else {
          formatedDate += `${theDate.getHours()}:${theDate.getMinutes()}`;
        }
        this.round.round_schedule = formatedDate;
      }
    },

    /**
     * @memberOf module:ApplicantsData.components.schedule_round_modal
     * @param {String} value
     * @description Checks whether round has been added successfully or not
     */
    confirmRoundScheduleStatus(value) {
      if (value === "SUCCESS") {
        if (this.inPlaceAction && this.inPlaceAction == true) {
          this.$store.dispatch("openSnackbar", {
            type: "success",
            message: "Round scheduled successfully!",
          });
        } else {
          this.$router.push({ name: "roundschedules" });
        }
      } else if (value === "FAILURE") {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Unable to perform required action",
        });
      }
      this.$store.commit("$_applicants_data/CONFIRM_ROUND_SCHEDULE_STATUS", "");
    },

    /**
     * @memberOf module:ApplicantsData.components.schedule_round_modal
     * @param {Array} value
     * @description Fetch Co-ordinator emails as soon as applicant is selected in grid
     */
    selectedApplicants(value) {
      if (!value[0].belongsToCampusRecruitement) {
        this.isCampusStudent = false;
      } else if (value[0].belongsToCampusRecruitement) {
        this.isCampusStudent = true;
      }
      if (value[0].belongsToCampusRecruitement) {
        this.$store.dispatch(
          "$_applicants_data/fetchCampusEmails",
          value[0].belongsToCampusRecruitement.id
        );
      }
    },

    /**
     * @memberOf module:ApplicantsData.components.schedule_round_modal
     * @param {Object} value
     * @description Checks whether selected data belong to campus or not
     */
    campusMails() {
      this.isCampusStudent = true;
    },

    /**
     * @memberOf module:ApplicantsData.components.schedule_round_modal
     * @param {String} value
     * @description Checks whether round is interview round or Review round
     */
    "round.round_name"(value) {
      if (value === "Interview") {
        this.interviewerChecker = true;
        this.formTemplateChecker = true;
      }
      if (value === "Review") {
        this.reviewerChecker = true;
        this.formTemplateChecker = true;
      }
    },
    "round.form_id"(value) {
      this.round.formversion_id = [
        this.formTemplates.find((template) => template.id === value[0])
          ?.formVersion[0]?.id,
      ];
    },
  },
  updated() {
    if (this.interviewerChecker) {
      let interviewpicker = document.getElementById("interviewerpicker");
      if (interviewpicker != null) {
        $("#interviewerpicker")
          .selectpicker("destroy")
          .attr("title", "select interviewers")
          .selectpicker("render");
      }
      this.interviewerChecker = false;
    }
    if (this.reviewerChecker) {
      let reviewpicker = document.getElementById("reviewerpicker");
      if (reviewpicker != null) {
        $("#reviewerpicker")
          .selectpicker("destroy")
          .attr("title", "select reviewers")
          .selectpicker("render");
      }
      this.reviewerChecker = false;
    }
    if (this.formTemplateChecker) {
      let formpicker = document.getElementById("formTemplatepicker");
      if (formpicker != null) {
        $("#formTemplatepicker")
          .attr("title", "select template")
          .selectpicker("render");
      }
      this.formTemplateChecker = false;
    }
  },
  created() {
    this.getUsers();
    this.getFormsTemplates();
  },
  mounted() {
    $("#interviewerpicker").on("hidden.bs.select", () => {
      this.isInterviewerTouched = true;
    });
    $("#reviewerpicker").on("hidden.bs.select", () => {
      this.isReviewerTouched = true;
    });
    $("#formTemplatepicker").on("hidden.bs.select", () => {
      this.isFormTemplateTouched = true;
    });
    $("#datePicker").on("dp.show", function (e) {
      $("#datePicker").data("DateTimePicker").minDate(e.date);
    });
  },
  beforeDestroy() {
    $("#addRound").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
