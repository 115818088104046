<template>
  <div class="home content">
    <common-header :data="['home', 'report', 'managePermissions']">
    </common-header>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex px-0 my-1">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Search by Id, Title, Category"
              v-model="searchedText"
              @keypress.enter="search()"
            />
            <div class="input-group-append">
              <button
                v-if="searchedText"
                class="btn btn-outline-secondary"
                type="button"
                @click="
                  searchedText = '';
                  search();
                "
              >
                <i class="fa fa-close" aria-hidden="false"></i>
              </button>
              <button class="btn btn-cp" type="button" @click="search()">
                <i class="fa fa-search p-0" aria-hidden="false"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          class="px-0 d-flex justify-content-between justify-content-md-end my-1 ml-auto"
        >
          <label for="groupId">Select Group:</label>
          <select
            class="select-cp form-control ml-1 cst-width"
            v-model="selectedGroupId"
            id="groupId"
            @change="onGroupChange"
          >
            <option v-for="group in groups" :key="group.id" :value="group.id">
              {{ group.g_title }}
            </option>
          </select>
          <button
            type="button toggle-user-btn"
            class="toggle-user-btn ml-2"
            :class="isUserInGroup ? 'in-group' : 'not-in-group'"
            @click="toggleUser"
            :disabled="!selectedGroupId || loadingToggle"
          >
            {{
              loadingToggle
                ? "Processing..."
                : isUserInGroup
                ? "In Group"
                : "Not In Group"
            }}
          </button>
        </div>
      </div>

      <div v-if="filteredPermissions.length > 0" class="permissions">
        <ag-grid-vue
          class="ag-theme-alpine"
          :column-defs="columnDefs"
          :row-data="filteredPermissions"
          :grid-options="gridOptions"
          :pagination="true"
          :dom-layout="'autoHeight'"
          @grid-ready="onGridReady"
        ></ag-grid-vue>
      </div>
      <div v-else class="no-data">No permissions found</div>
    </div>
  </div>
</template>

<script>
import { addSnackbarMessage } from "../../Common/_plugin/error-response";
import apiService from "../_api/index";
import { AgGridVue } from "ag-grid-vue";

export default {
  name: "Home",
  components: {
    AgGridVue,
  },
  data() {
    return {
      permissions: [],
      searchedText: "",
      filteredPermissions: [],
      loading: false,
      error: null,
      selectedGroupId: null,
      groups: [],
      loadingToggle: false,
      isUserInGroup: false,
      columnDefs: [
        {
          headerName: "Permission ID",
          field: "id",
          width: 100,
          minWidth: 100,
        },
        {
          headerName: "Title",
          field: "title",
          width: 150,
          minWidth: 150,
          cellRenderer: (params) => {
            const permissionId = params.data.id;
            const isChecked = params.data.permissionActive ? "checked" : "";
            const html = `
        <label class="toggle-switch">
          <input
            type="checkbox"
            ${isChecked}
            data-id="${permissionId}"
          />
          <span class="slider"></span>
        </label>
        ${params.value}  <!-- Display title text -->
      `;

            this.$nextTick(() => {
              const checkbox = document.querySelector(
                `input[data-id="${permissionId}"]`
              );
              if (checkbox) {
                checkbox.addEventListener("change", (event) =>
                  this.togglePermissionStatus(event)
                );
              }
            });

            return html;
          },
        },
        {
          headerName: "Description",
          field: "description",
          width: 250,
          minWidth: 250,
        },
        {
          headerName: "Category",
          field: "category",
          width: 150,
          minWidth: 150,
        },
        {
          headerName: "Group Permission Status",
          field: "groupPermissionActive",
          cellRenderer: (params) => this.renderGroupPermissionStatus(params),
          pinned: "right",
          width: 100,
          minWidth: 100,
        },
      ],
      gridOptions: {
        domLayout: "autoHeight",
      },
    };
  },
  methods: {
    async togglePermissionStatus(event) {
      const checkbox = event.target;
      const permissionId = checkbox.getAttribute("data-id");
      const newStatus = checkbox.checked;

      const permission = this.permissions.find((p) => p.id === permissionId);
      if (permission) {
        permission.permissionActive = newStatus;
      }

      checkbox.disabled = true;

      try {
        await apiService.togglePermissionActive(permissionId);
      } catch (err) {
        const permission = this.permissions.find((p) => p.id === permissionId);
        if (permission) {
          permission.permissionActive = !newStatus;
          checkbox.checked = !newStatus;
        }
        console.log(err);
        addSnackbarMessage(err, "Failed to update permission!", true);
      } finally {
        checkbox.disabled = false;
      }
    },
    renderGroupPermissionStatus(params) {
      const isChecked = params.value ? "checked" : "";
      const permissionId = params.data.id;

      const html = `
    <label class="toggle-switch">
      <input
        type="checkbox"
        ${isChecked}
        data-id="grp${permissionId}"
      />
      <span class="slider"></span>
    </label>
  `;

      this.$nextTick(() => {
        const checkbox = document.querySelector(
          `input[data-id="grp${permissionId}"]`
        );
        if (checkbox) {
          checkbox.addEventListener("change", (event) =>
            this.onToggleGroupPermissionStatus(event, permissionId)
          );
        }
      });

      return html;
    },

    async onToggleGroupPermissionStatus(event, permissionId) {
      const checkbox = event.target;
      const newStatus = checkbox.checked;

      checkbox.disabled = true;

      const permission = this.permissions.find((p) => p.id === permissionId);

      const oldStatus = permission
        ? permission.groupPermissionActive
        : newStatus;

      if (permission) {
        permission.groupPermissionActive = newStatus;
      }

      try {
        await apiService.toggleGroupPermissionActive(
          this.selectedGroupId,
          permissionId
        );
      } catch (err) {
        if (permission) {
          permission.groupPermissionActive = oldStatus;
          checkbox.checked = oldStatus;
        }
        console.log(err);
        addSnackbarMessage(err, "Failed to update permission!", true);
      } finally {
        checkbox.disabled = false;
      }
    },

    async fetchGroups() {
      this.loading = true;
      this.error = null;
      try {
        const response = await apiService.getAllAuthGroups();
        this.groups = response.data;

        const defaultGroup = this.groups.find(
          (group) => group.g_title === "JOB-PORTAL HR Manager"
        );
        if (defaultGroup) {
          this.selectedGroupId = defaultGroup.id;
        }

        if (this.selectedGroupId) {
          this.onGroupChange();
        }
      } catch (err) {
        this.error = "Failed to fetch groups.";
        console.log(err);
        addSnackbarMessage(err, "Failed to fetch groups.", true);
      } finally {
        this.loading = false;
      }
    },
    async fetchPermissions() {
      this.loading = true;
      this.error = null;
      try {
        const groupId = this.selectedGroupId;
        const response = await apiService.getPermissionsByGroup(groupId);
        this.permissions = response.data;
        this.filteredPermissions = this.permissions;
      } catch (err) {
        this.error = "Failed to fetch permissions.";
        console.log(err);
        addSnackbarMessage(err, "Failed to fetch permissions.", true);
      } finally {
        this.loading = false;
      }
    },
    async search() {
      if (!this.searchedText) {
        this.filteredPermissions = this.permissions;
      } else {
        const lowerCaseSearch = this.searchedText.toLowerCase();
        this.filteredPermissions = this.permissions.filter((permission) => {
          return (
            permission.id.toString().includes(lowerCaseSearch) ||
            permission.title.toLowerCase().includes(lowerCaseSearch) ||
            permission.category.toLowerCase().includes(lowerCaseSearch)
          );
        });
      }
    },
    async checkUserStatus() {
      if (!this.selectedGroupId) return;
      try {
        const response = await apiService.checkUserInGroup(
          this.selectedGroupId
        );
        this.isUserInGroup = response.data;
      } catch (err) {
        this.error = "Failed to check user status.";
        console.log(err);
        addSnackbarMessage(err, "Failed to check user status.", true);
      }
    },
    async toggleUser() {
      if (!this.selectedGroupId) {
        this.error = "No group selected.";
        return;
      }

      this.loadingToggle = true;
      this.error = null;
      try {
        await apiService.toggleUserInGroup(this.selectedGroupId);
        this.isUserInGroup = !this.isUserInGroup;
      } catch (err) {
        this.error = "Failed to toggle user in the group.";
        console.log(err);
        addSnackbarMessage(err, "Failed to toggle user in the group.", true);
      } finally {
        this.loadingToggle = false;
      }
    },
    async onGroupChange() {
      this.checkUserStatus();
      this.fetchPermissions();
    },

    onGridReady(event) {
      event.api.sizeColumnsToFit();
    },
  },
  mounted() {
    this.fetchGroups();
  },
};
</script>

<style scoped>
.home {
  padding: 20px;
}

label {
  font-weight: bold;
  margin-right: 10px;
}

select {
  padding: 8px;
  font-size: 16px;
}

.loading,
.error {
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: red;
}

.permissions {
  margin-top: 20px;
}

.ag-theme-alpine {
  width: 100%;
  height: 100%;
}

.no-data {
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: gray;
}

.toggle-user-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-user-btn {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
}

.toggle-user-btn.in-group {
  background-color: #8f1452;
}

.toggle-user-btn.not-in-group {
  background-color: red;
}

.toggle-switch {
  display: inline-block;
  position: relative;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch input:checked + .slider {
  background-color: #4caf50;
}

.toggle-switch input:checked + .slider:before {
  transform: translateX(26px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 50px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
.cst-width {
  max-width: 205px;
}
</style>
