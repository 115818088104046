<template>
  <div>
    <label :for="'input-checkbox-' + _uid" v-if="showLabels">
      {{ label }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div class="input-parent" :class="inputAttributes.parentClass">
      <div
        class="form-check"
        :class="[inputAttributes.wrapperClass || 'my-2']"
        v-for="(option, idx) in options"
        :key="'input-checkbox-' + _uid + '-option-' + idx"
      >
        <input
          class="form-check-input"
          type="checkbox"
          :value="option.value"
          :id="'input-checkbox-' + _uid + '-' + idx"
          v-model="option.selected"
          @change="handleInput"
          :disabled="disabled"
          v-bind="inputAttributes.attrs"
          v-on="inputAttributes.vOn"
          :class="[inputAttributes.class]"
        />
        <label
          class="form-check-label"
          :for="'input-checkbox-' + _uid + '-' + idx"
        >
          {{ option.label }}
        </label>
      </div>
      <div
        class="form-check"
        :class="[inputAttributes.wrapperClass || 'my-2']"
        v-if="properties.otherOption"
      >
        <input
          class="form-check-input"
          :class="[inputAttributes.class]"
          type="checkbox"
          :id="'input-checkbox-' + _uid + '-other'"
          v-model="selectOther"
          @change="handleInput"
        />
        <input
          class="form-control form-control-sm border-top-0 border-left-0 border-right-0 rounded-0"
          type="text"
          placeholder="Other"
          v-model="selectOtherValue"
          @change="handleInput"
        />
      </div>
    </div>

    <template v-if="showErrors">
      <p
        class="text-danger"
        v-for="(error, idx) in errorList"
        :key="'input-checkbox-' + _uid + '-error-' + idx"
      >
        <b-icon-exclamation-circle></b-icon-exclamation-circle>
        <span class="ms-2">{{ error }}</span>
      </p>
    </template>
  </div>
</template>

<script>
import { processAttributes } from "@/modules/User/_utils/actions";
import validators from "../../_utils/validators";

export default {
  name: "checkbox-input-renderer",
  props: {
    value: Array || Object,
    label: String,
    properties: Object,
    useValidation: Boolean,
    validators: Array,
    disabled: { type: Boolean, default: false },
    inputAttrs: { type: Object, default: () => ({}) },
    showLabels: { type: Boolean, default: true },
    showErrors: { type: Boolean, default: true },
  },
  data() {
    return {
      options: [],
      selectOther: false,
      selectOtherValue: null,
      errorList: [],
      previewInputClass: "",
      previewError: "",
      isExpanded: false,
    };
  },
  computed: {
    inputAttributes() {
      return processAttributes(this.inputAttrs);
    },
    isRequired() {
      return (
        this.useValidation &&
        this.validators.some((e) => e.validator === "required")
      );
    },
  },
  watch: {
    properties: {
      deep: true,
      handler: function (properties) {
        this.options = properties.options;
      },
      immediate: true,
    },
    value: {
      immediate: true,
      handler: function (val) {
        if (typeof val === "object" && Array.isArray(val)) {
          for (const value of val) {
            const idx = this.options.findIndex((o) => o.value === value);
            if (idx === -1) {
              this.selectOther = true;
              this.selectOtherValue = value;
            } else {
              this.options[idx].selected = true;
            }
          }
        }
      },
    },
  },
  methods: {
    checkError(input) {
      this.errorList = [];
      for (const validator of this.validators) {
        const err = validators[validator.validator](input, validator);
        if (err) {
          this.errorList.push(err);
        }
      }
      if (this.errorList.length !== 0) {
        this.$emit("error", {
          key: this.key,
          errors: this.errorList,
        });
        return true;
      }
      return false;
    },
    handleInput() {
      const selected = [];
      this.options.forEach((o) => {
        if (o.selected) {
          selected.push(o.value);
        }
      });
      if (this.properties.otherOption && this.selectOther) {
        selected.push(this.selectOtherValue);
      }
      this.$emit("input", selected);
      this.useValidation && this.checkError(selected);
    },
  },
  beforeMount() {
    this.options = this.properties.options;
    if (
      (this.value == "" && !Array.isArray(this.value)) ||
      this.value == null ||
      this.value == undefined ||
      (typeof this.value === "object" && !Array.isArray(this.value))
    ) {
      this.$emit("input", []);
    }
  },
};
</script>
