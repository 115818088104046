export const gradeBodyData = {
  value: "Select Grade",
  type: "dropdown",
  properties: {
    placeholder: "Select Grade",
    options: [
      {
        value: "Excellent",
        label: "Excellent",
      },
      {
        value: "Very Good",
        label: "Very Good",
      },
      {
        value: "Good",
        label: "Good",
      },
      {
        value: "Moderate",
        label: "Moderate",
      },
      {
        value: "Lacking",
        label: "Lacking",
      },
      {
        value: "Poor",
        label: "Poor",
      },
    ],
  },
  key: "grade",
  useValidation: false,
  validators: [],
};

export const noFormBodyData = {
  value: "Response",
  type: "line",
  properties: {
    placeholder: "Response",
  },
  key: "reponse",
  useValidation: false,
  validators: [],
};

export const getRoundData = (data, eventData) => {
  if (
    data?.hasRounds &&
    eventData?.eventId &&
    eventData?.interviewerId &&
    data.hasRounds[eventData.eventId]
  ) {
    return data.hasRounds[eventData.eventId];
  }
  return null;
};

export const getRoundInterviewData = (roundData, eventData) => {
  if (roundData && roundData["mappedInterviewers"]) {
    const interData = roundData["mappedInterviewers"][eventData.interviewerId];
    if (
      interData &&
      interData.formVersionId === eventData.formVersionId &&
      interData.formMasterId === eventData.formMasterId
    ) {
      return interData;
    }
  }
  return null;
};

export const parseJsonData = (data) => {
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

export const parseJsonDataAndProcess = (data) => {
  if (data?.model) {
    return data.model;
  }
  return data;
};

export const getEmptyArray = (value) => {
  let data = value;
  if (
    value == "" ||
    value == null ||
    value == undefined ||
    (typeof value === "object" && !Array.isArray(value))
  ) {
    data = [];
  }
  return data;
};
export const getInterviewData = (data, eventData, needValue) => {
  let roundData = null;
  let value = "";
  let valueValid = true;
  let interviewData = null;
  roundData = getRoundData(data, eventData);
  interviewData = getRoundInterviewData(roundData, eventData);
  if (needValue) {
    let parseData = null;
    if (interviewData) {
      parseData = parseJsonData(interviewData.interviewer_remarks);
      parseData = parseJsonDataAndProcess(parseData);
    }
    if (typeof parseData === "string") {
      value = parseData;
      valueValid = false;
    } else if (parseData && parseData[eventData.bodyData.key]) {
      value = parseData[eventData.bodyData.key];
      if (eventData.bodyData.type === "checkbox") {
        value = getEmptyArray(value);
      }
    } else if (eventData.bodyData.type === "checkbox") {
      value = getEmptyArray(value);
    }
  }
  return {
    interviewData,
    value: JSON.parse(JSON.stringify(value)),
    valueValid,
  };
};

export const getNoFormInterviewData = (data, eventData, needValue) => {
  let roundData = null;
  let value = "";
  let interviewData = null;
  roundData = getRoundData(data, eventData);
  if (roundData && roundData["mappedInterviewers"]) {
    const interData = roundData["mappedInterviewers"][eventData.interviewerId];
    interviewData = interData;
  }
  if (needValue) {
    value = interviewData?.interviewer_remarks;
  }
  return { interviewData, value };
};
