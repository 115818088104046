export const SCHEDULE_APPLICANTS_DATA_COL_DEFS = [
  {
    headerName: "Personal Info",
    headerGroupComponent: "CustomHeaderGroup",
    children: [
      {
        cellRenderer: "profile-link",
        width: 35,
        minWidth: 35,
        maxWidth: 35,
        suppressFilter: true,
        filter: false,
        valueGetter: "node.id",
        headerTooltip: "Profile Info",
        tooltipValueGetter: function (params) {
          if (params.data != undefined) {
            return "Profile of " + params.data.first_name;
          }
          return "";
        },
        pinned: "left",
        display_only: true,
        cellStyle: { textAlign: "center", padding: 0 },
        columnGroupShow: "false",
      },
      {
        headerName: "Name",
        field: "name",
        valueGetter: function mergeLastNameFirstName(params) {
          if (params.data != undefined) {
            return params.data.first_name + " " + params.data.last_name;
          }
        },
        sortable: true,
        comparator: (valueA, valueB) => {
          if (valueA && valueB) {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          }
          return 0;
        },
        width: 200,
        minWidth: 100,
        checkboxSelection: true,
        pinned: "left",
        suppressHeaderMenuButton: true,
        filterParams: {
          newRowsAction: "keep",
        },
        columnGroupShow: "false",
      },
      {
        headerName: "Email",
        field: "email",
        sortable: true,
        comparator: (valueA, valueB) => {
          if (valueA && valueB) {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          }
          return 0;
        },
        width: 200,
        minWidth: 50,

        suppressHeaderMenuButton: true,
        pinned: "left",
        columnGroupShow: "open",
      },
      {
        headerName: "Mobile",
        field: "mobile_no",
        sortable: true,
        width: 100,
        minWidth: 50,

        suppressHeaderMenuButton: true,
        pinned: "left",
        columnGroupShow: "open",
      },
      {
        headerName: "Gender",
        field: "gender",
        sortable: true,
        width: 100,
        minWidth: 50,
        suppressHeaderMenuButton: true,
        valueGetter: (params) => {
          const genderValue = params.data.gender;
          return (
            genderValue.charAt(0).toUpperCase() +
            genderValue.slice(1).toLowerCase()
          );
        },

        pinned: "left",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Applicant Status",
    headerGroupComponent: "CustomHeaderGroup",
    children: [
      {
        headerName: "State",
        field: "application_status",
        sortable: true,
        filter: true,
        width: 150,
        minWidth: 150,
        suppressHeaderMenuButton: true,
        columnGroupShow: "false",
      },
      {
        headerName: "Status",
        field: "status",
        sortable: true,
        filter: true,
        width: 100,
        minWidth: 80,
        suppressHeaderMenuButton: true,
        columnGroupShow: "open",
      },
      {
        headerName: "Date",
        field: "createdAt",
        width: 120,
        minWidth: 120,
        sortable: true,
        valueGetter: function dateFormat(params) {
          if (params.data != undefined) {
            let dt = new Date(params.data.createdAt);
            return dt.toLocaleDateString().toString();
          }
        },
        filter: "agDateColumnFilter",
        suppressHeaderMenuButton: true,
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "College Info",
    headerGroupComponent: "CustomHeaderGroup",
    align: "center",
    children: [
      {
        headerName: "Name",
        width: 150,
        minWidth: 150,
        field: "college_name",
        sortable: true,
        suppressHeaderMenuButton: true,
        columnGroupShow: "false",
      },
      {
        headerName: "Degree",
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.degree;
          }
          return "NA";
        },
        columnGroupShow: "open",
      },
      {
        headerName: "Stream",
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.stream;
          }
          return "NA";
        },
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Grade Info",
    headerGroupComponent: "CustomHeaderGroup",
    align: "center",
    children: [
      {
        headerName: "Agg. Pr.",
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.aggregate_percentage;
          }
          return "NA";
        },
        columnGroupShow: "fasle",
      },
      {
        headerName: "HSC Pr.",
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.hsc_percentage;
          }
          return "NA";
        },
        columnGroupShow: "open",
      },
      {
        headerName: "SSC Pr.",
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.ssc_percentage;
          }
          return "NA";
        },
        columnGroupShow: "open",
      },
      {
        headerName: "Grad. Pr.",
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
        valueGetter: function getValue(params) {
          if (params.data && params.data.education_details) {
            return params.data.education_details.grad_percentage;
          }
          return "NA";
        },
        columnGroupShow: "open",
      },
    ],
  },
];
