<template>
  <div class="content _registered-applicant-list">
    <common-header
      :data="['home', 'campusDrive', 'campusRecruitments', 'singleRecruitment']"
      :componentName="
        selectedCampus ? selectedCampus.recruitment_session_name : 'None'
      "
    >
      <p class="m-0 p-2" v-if="selectedCampus">
        <b>Last date - </b>{{ selectedCampus.last_date | dateFormat }}
      </p>
    </common-header>
    <div id="applicationList" style="height: inherit" class="container-fluid">
      <div class="d-block d-sm-none row flex-row-reverse p-0 m-0 pt-2 pb-3">
        <div class="px-0 col-md-6 col-xs-6 d-flex">
          <div class="flex-grow-1 row w-100">
            <div
              class="col-12"
              v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
            >
              <applicant-search-bar
                :apiPromise="searchApiPromise"
                :routerLink="searchRouterLink"
                :selectedCampus="selectedCampus"
                :isCampusRecruitment="true"
                color="#8f1452"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-7 col-xs-7 p-0 pr-md-2">
          <campus-event-modal
            ref="campusModal"
            :onHidden="onHidden"
            :selectSessionEvents="selectSessionEvents"
            :setEditEvent="setEditEvent"
            :selectedEventModal="selectedEventModal"
            :showEditEvent="showEditEvent"
            :setEventModalData="setEventModalData"
          />
        </div>
        <div
          class="col-md-5 col-xs-5 border-md-left mt-4 mt-md-0 p-0 pl-md-2"
          v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
        >
          <div
            class="d-none d-sm-block mb-3"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
          >
            <applicant-search-bar
              :apiPromise="searchApiPromise"
              :routerLink="searchRouterLink"
              :selectedCampus="selectedCampus"
              :isCampusRecruitment="true"
              color="#8f1452"
            />
          </div>

          <div class="d-flex justify-content-end flex-wrap">
            <div class="d-flex align-items-center mx-2 my-1">
              <label class="checkbox-inline m-0 d-flex align-items-center">
                <input
                  style="margin-right: 5px"
                  type="checkbox"
                  v-model="includeAll"
                />
                <span
                  title="Differed, Joined, Talent Pool"
                  class="pe-none"
                  tabindex="-1"
                  aria-disabled="true"
                  >Include All</span
                >
              </label>
            </div>
            <div class="m-0 my-1" v-if="formData">
              <div class="d-flex align-items-center flex-wrap">
                <div class="entity-dropdown mx-1">
                  <select
                    id="entityPicker"
                    class="select selectpicker"
                    data-live-search="true"
                    data-width="100%"
                    v-model="parentEntity_id"
                    :data-dropdown-align-right="true"
                  >
                    <option :value="null">All Entities</option>
                    <option
                      :value="option.entityDetail.id"
                      v-for="(option, index) in formData.entitiesInvolved"
                      :key="index"
                    >
                      {{
                        `${option.entityDetail.entity_name} - (${option.entityDetail.entity_type})`
                      }}
                    </option>
                  </select>
                </div>
                <div
                  v-if="showSubSelection"
                  class="entity-dropdown btn-group dropdown mx-1"
                >
                  <select
                    v-if="showSubSelection"
                    id="childEntityPicker"
                    class="selectpicker"
                    data-live-search="true"
                    data-width="100%"
                    v-model="subCollege_id"
                    :data-dropdown-align-right="true"
                  >
                    <option
                      :value="option.entityDetail.id"
                      v-for="(option, index) in subColleges"
                      :key="index"
                    >
                      {{
                        `${option.entityDetail.entity_name} - (${option.entityDetail.entity_type})`
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="my-1">
              <button class="btn btn-cp mx-1" @click="refreshData()">
                <i class="fa fa-repeat px-0" aria-hidden="true"></i>
              </button>
            </div>
            <div class="my-1">
              <button class="btn btn-cp btn-filter mx-1" @click="filterCards">
                <em class="fa fa-filter px-0"></em>
                <span
                  v-if="!isFilterOptionsEmpty()"
                  class="filter-indicator"
                ></span>
              </button>
            </div>
          </div>
          <div>
            <common-chart
              chartId="status-chart"
              chartTitle="Status-wise"
              :colorIdx="0"
              :dataSlices="statusCountSlices"
              v-on:filter-data="filterApplicationStatus($event)"
            >
              <div class="filter-text" v-if="applicationStatus">
                <span v-if="applicationStatus"
                  ><em class="fa fa-filter"></em> Application Status :
                  {{ applicationStatus }}</span
                ><br />
              </div>
            </common-chart>
          </div>
        </div>
      </div>
      <div class="row pl-0 flex-wrap justify-content-between">
        <div class="d-flex">
          <div
            style="width: 100%"
            class="flex-wrap"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
          >
            <div class="btn-group dropup">
              <button
                class="btn dropdown-toggle select-cp my-1"
                type="button"
                data-toggle="dropdown"
                id="dropdownMenuButtonli"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-tasks" aria-hidden="true"></i>
              </button>
              <ul
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuParent"
              >
                <template
                  v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
                >
                  <li class="dropdown-header">Change Applicant Status</li>
                  <li class="dropdown-item">
                    <a @click="handleSendMailButton">Send Mail</a>
                  </li>
                  <li class="dropdown-item">
                    <a @click="processApplicants"
                      >Process Applicant(s) and Mail</a
                    >
                  </li>
                  <li class="dropdown-item">
                    <a @click="shortlistApplicants"
                      >Shortlist Applicant(s) and Mail</a
                    >
                  </li>
                  <li class="dropdown-item">
                    <a @click="differApplicants"
                      >Differ Applicant(s) and Mail</a
                    >
                  </li>
                </template>
                <li class="divider"></li>
                <li class="dropdown-header">Add Applicants</li>
                <li
                  class="dropdown-item"
                  v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
                >
                  <a @click="openUploadApplicant">Upload</a>
                </li>
                <li class="dropdown-item"><a @click="copyURL">Copy URL</a></li>
                <li class="divider"></li>
                <template
                  v-if="hasAccess({ permissions: [PERMISSIONS.MAIL.CREATE] })"
                >
                  <li class="dropdown-header">Campaign</li>
                  <li class="dropdown-item">
                    <a @click="RunCampaign">Run Campaign</a>
                  </li>
                </template>

                <li class="dropdown-header">Download</li>
                <li class="dropdown-item">
                  <safe-link :link="formatFileLink">Format File </safe-link>
                </li>
                <li class="dropdown-item">
                  <a @click="downloadData">Applicant Data</a>
                </li>
              </ul>
              <upload-applicants
                v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
                ref="uploadapplicants"
                @refreshData="refreshData"
                :selectedCampus="selectedCampus"
              >
              </upload-applicants>
            </div>
            <div class="entity-dropdown btn-group dropdown my-1 ml-2">
              <select
                id="eventPicker"
                class="select selectpicker bt-select"
                data-live-search="true"
                data-width="100%"
                v-model="selectedEvent"
                :data-dropdown-align-left="true"
              >
                <option :value="null">All Event</option>
                <option
                  :value="event.id"
                  v-for="(event, index) in events"
                  :key="index"
                >
                  {{ `${event.round_title}` }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <p
            class="alert-info alert-sm mr-2 button-curve"
            v-if="selectedRowCount > 0"
          >
            <span class="hide-on-mobile">{{ selectedRowCount }} selected</span>
            <span class="hide-on-desktop px-2">{{ selectedRowCount }}</span>
          </p>
          <div>
            <button
              class="btn btn-cp hide-on-mobile"
              v-if="
                selectedRowCount > 0 &&
                (rowCountOnCurrentPage === -1 ||
                  selectedRowCount < rowCountOnCurrentPage)
              "
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-minus-square" aria-hidden="true"></i> Select all
            </button>
            <button
              class="btn btn-cp hide-on-mobile"
              v-else-if="selectedRowCount && rowCountOnCurrentPage !== -1"
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-check-square" aria-hidden="true"></i> Deselect all
            </button>
            <button
              class="btn btn-cp hide-on-mobile"
              v-else
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-square" aria-hidden="true"></i> Select all
            </button>
            <button
              class="btn btn-cp hide-on-desktop ml-0"
              @click="selectAllOnCurrentPage"
            >
              <i
                class="fa"
                :class="{
                  'fa-check-square':
                    selectedRowCount && rowCountOnCurrentPage !== -1,
                  'fa-square':
                    selectedRowCount === 0 || rowCountOnCurrentPage === -1,
                  'fa-minus-square':
                    selectedRowCount > 0 &&
                    (rowCountOnCurrentPage === -1 ||
                      selectedRowCount < rowCountOnCurrentPage),
                }"
                aria-hidden="true"
              ></i>
            </button>
            <button
              class="btn btn-cp ml-2"
              @click="openDeleteModal"
              v-if="selectedRowCount > 0 && selectedApplicants.length > 0"
            >
              Delete
            </button>
          </div>
          <div class="ml-2">
            <select
              @change="onPageSizeChanged($event)"
              class="select-cp form-control"
            >
              <option class="pagesizeoption" value="50">50</option>
              <option class="pagesizeoption" value="100" selected="">
                100
              </option>
              <option class="pagesizeoption" value="200">200</option>
              <option class="pagesizeoption" value="500">500</option>
              <option class="pagesizeoption" value="1000">1000</option>
              <option class="pagesizeoption" value="2000">2000</option>
            </select>
          </div>
        </div>
      </div>
      <div class="py-1">
        <ag-grid-vue
          v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
          class="ag-theme-alpine ag-grid-style"
          style="height: 70vh"
          :enableBrowserTooltips="true"
          :gridOptions="gridOptions"
          @selection-changed="onSelectionChanged"
          rowSelection="multiple"
          @filter-changed="onFilterChanged"
          @sort-changed="onSortChanged"
          @gridReady="onGridReady"
          :key="paginationSize"
        >
        </ag-grid-vue>
      </div>
    </div>
    <b-modal
      id="confirm-delete-modal"
      ref="confirmDeleteModal"
      title="Confirm Delete"
      @ok.prevent="confirmDelete"
    >
      <div class="col-12 pr-sm-2 mb-3">
        <p>Are you sure you want to delete the selected items?</p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()"
          >Cancel</b-button
        >
        <b-button size="sm" @click="ok()" class="btn btn-cp ml-2"
          >Delete</b-button
        >
      </template>
    </b-modal>
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
    <select-to-be-mailed
      :flag="flag"
      :selectedCampusApplicants="selectedApplicants"
      :refreshParentPage="refreshData"
    >
    </select-to-be-mailed>
    <b-modal
      ref="eventModal"
      :title="eventModalData.modalTitle"
      :size="eventModalData.modalSize"
      :hide-footer="eventModalData.hideFooter"
    >
      <EventModal
        v-on:closeEventModal="closeEventModal"
        :isEdit="eventModalData.isEdit"
        :selectedDate="selectedDate"
        :editEvent="editEvent"
        v-on:generateRound="generateRound($event)"
        v-on:updateEvent="updateEvent($event)"
      />
    </b-modal>
    <schedule-event-modal
      v-if="
        hasAccess({
          permissions: [
            PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.CREATE,
            PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.UPDATE,
          ],
          checkAll: true,
        })
      "
      :editEvent="editEvent"
      :calendarDateTime="selectedDate"
      ref="scheduleRoundModel"
      :inPlaceAction="true"
    />
    <filter-data-modal-rec
      :filterOptions="filterOptions"
      :updateFilterOptions="updateFilterOptions"
    ></filter-data-modal-rec>
    <UpdateApplicationStatusModal
      actionType="SHORTLIST"
      :modalId="'update-shortlist-modal'"
      :selectedApplicants="selectedApplicants"
      :jobProfiles="jobProfiles"
    />
  </div>
</template>

<script>
import { getFilterColumns } from "../../Common/_plugin/validation";
import { AgGridVue } from "ag-grid-vue";
import SelectToBeMailed from "../../Applications/_components/select-to-be-mailed.vue";
import CommonModal from "../../Common/_components/modal";
import axios from "axios";
import CommonChart from "../../Common/_components/common-highchart.vue";
import ApplicantSearchBar from "../../Common/_components/applicant-search-bar.vue";
import api from "../_api/index";
import { mapGetters } from "vuex";
import { APPLICANTS_DATA_COL_DEFS } from "../_utils/campus_application_data_col_defs";
import config from "../../../config";
import UploadApplicants from "./campus-upload-applicants.vue";
import moment from "moment";
import CampusEventModal from "./campus-event-modal.vue";
import ResolveDuplicateLink from "./resolve-duplicate-link.vue";
import EventModal from "./event-modal.vue";
import scheduleEventModal from "./schedule-event-modal.vue";
import FilterDataModalRec from "./filter-data-modal-rec.vue";
import UpdateApplicationStatusModal from "./campus-update-application-status-modal.vue";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";
import EditApplicantLink from "../../Common/_components/edit-applicant-link.vue";
import getFilterByType from "@/modules/Common/_utils/get-filter-by-type";

export default {
  data() {
    return {
      paginationSize: 100,
      loadingTemplate: "Loading...",
      noRowsTemplate: "No applicants found",
      recruitmentId: this.$route.params.recruitmentId,
      filterOptions: {
        applicationDate: {
          filterType: "",
          fromDate: null,
          toDate: null,
        },
        moreFilters: {
          duplicateType: "",
          campusType: "",
        },
      },
      applicantList: [],
      totalVisibleRows: null,
      selectedEvent: null,
      events: [],
      selectedDate: null,
      editEvent: null,
      config,
      currentDate: moment(),
      columnDefs: APPLICANTS_DATA_COL_DEFS,
      selectedApplicants: [],
      rowCountOnCurrentPage: -1,
      selectedRowCount: 0,
      gridOptions: null,
      flag: null,
      includeAll: true,
      applicationStatus: null,
      employmentStatus: "P",
      selectSessionEvents: null,
      selectedEventModal: null,
      actionStatus: {
        shortlist: {
          options: ["Written Test Passed", "Interview Passed"],
          regexString: /Passed/,
          regexStringI: /Passed/i,
        },
        process: {
          options: ["Received", "Differed"],
          regexString: /Received|Differed/,
          regexStringI: /Received|Differed/i,
        },
        differ: { notOptions: ["Differed"] },
      },
      parentEntity_id: null,
      subColleges: null,
      subCollege_id: null,
      formId: null,
      formData: null,
      eventModalData: {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      },
      showRowStyles: true,
      maxApplicantCountToCurrentPage: null,
      formatFileLink:
        config.ROOT_API +
        "/campus-drive/recruitments/download/freshers-format/",
    };
  },
  components: {
    CommonModal,
    SelectToBeMailed,
    // eslint-disable-next-line vue/no-unused-components
    "resolve-link": ResolveDuplicateLink,
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{name:'applicantprofile', params:{slug:params.data.slug}}"><i title="View Profile" class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "edit-profile-data": EditApplicantLink,
    ApplicantSearchBar,
    CommonChart,
    UploadApplicants,
    CampusEventModal,
    EventModal,
    scheduleEventModal,
    FilterDataModalRec,
    UpdateApplicationStatusModal,
  },
  updated() {
    $("#entityPicker").selectpicker("refresh");
    $("#childEntityPicker").selectpicker("refresh");
    $("#eventPicker").selectpicker("refresh");
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT;
    },
    selectedEventDetails() {
      return this.events.find((event) => {
        return event.id == this.selectedEvent;
      });
    },
    ...mapGetters({
      statusCount: "$_campus_recruitment/applicationStatusCount",
      selectedCampus: "$_campus_recruitment/getSelectedCampus",
      jobProfiles: "$_applicant_profile/fetchJobProfiles",
    }),
    showSubSelection() {
      const selectedEntity = this.formData.entitiesInvolved.find((entity) => {
        return entity.entityDetail.id === this.parentEntity_id;
      });
      if (selectedEntity) {
        return selectedEntity.entityDetail.entity_type === "University";
      } else {
        return false;
      }
    },
    parentEntity() {
      return this.formData.entitiesInvolved.find((entity) => {
        return entity.entityDetail.id == this.parentEntity_id;
      });
    },
    childCollege() {
      return this.subColleges.find((entity) => {
        return entity.entityDetail.id == this.subCollege_id;
      });
    },
    statusCountSlices() {
      return this.statusCount.map((status) => {
        if (parseInt(status.status_count) !== 0) {
          return {
            name: status.status_name,
            y: parseInt(status.status_count),
          };
        }
      });
    },
  },
  watch: {
    filterOptions() {
      this.refreshData();
      this.applyDateFilter();
    },
    includeAll() {
      this.refreshData();
    },
    selectedCampus(selectedCampus) {
      if (selectedCampus) {
        this.refreshData();
      }
    },
    parentEntity_id: {
      handler(entityId) {
        if (entityId) {
          const entity = this.formData.entitiesInvolved.find((ent) => {
            return ent.entityDetail.id == entityId;
          });
          if (entity.subColleges) {
            this.subColleges = entity.subColleges;
            this.subCollege_id = entity.subColleges[0].entityDetail.id;
          } else {
            this.subColleges = null;
            this.subCollege_id = entityId;
          }
        } else {
          this.subColleges = null;
          this.subCollege_id = null;
        }
      },
      deep: true,
    },
    subCollege_id() {
      this.refreshData();
    },
    selectedEvent() {
      this.refreshData();
    },
  },
  methods: {
    resetRowCountData() {
      this.rowCountOnCurrentPage = -1;
      this.selectedRowCount = 0;
    },
    openDeleteModal() {
      this.$refs.confirmDeleteModal.show();
    },
    confirmDelete() {
      this.deleteSelected();
    },
    deleteSelected() {
      const ids = this.selectedApplicants.map((applicant) => {
        return applicant.id;
      });
      api
        .deleteRecruitmentMultipleApplicants(ids, this.selectedCampus.id)
        .then(() => {
          this.$refs.confirmDeleteModal.hide();
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Selected applicants has been deleted!",
            },
            {
              root: true,
            }
          );
          this.refreshData();
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: err.response?.data?.message,
            },
            {
              root: true,
            }
          );
        });
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    async applyDateFilter() {
      if (this.filterOptions.applicationDate.filterType) {
        let fromDate = "";
        let toDate = "";
        if (
          typeof this.filterOptions.applicationDate.fromDate === "object" &&
          this.filterOptions.applicationDate.fromDate != null
        ) {
          fromDate = this.filterOptions.applicationDate.fromDate.toJSON();
          toDate =
            this.filterOptions.applicationDate.toDate || new Date().toJSON();
        } else {
          fromDate = this.filterOptions.applicationDate.fromDate;
          toDate =
            this.filterOptions.applicationDate.toDate || new Date().toJSON();
        }
        fromDate = this.dateFormatter(fromDate);
        toDate = this.dateFormatter(toDate);

        const instance = await this.gridOptions.api.getColumnFilterInstance(
          "createdAt"
        );
        if (instance) {
          const data = await instance.getModel();
          if (data) {
            data.filterType = "date";
            data.type = "inRange";
            data.dateFrom = fromDate;
            data.dateTo = toDate;
            await instance.setModel(data);
          }
        }
      } else {
        const instance = await this.gridOptions.api.getColumnFilterInstance(
          "createdAt"
        );
        if (instance) {
          await instance.setModel(null);
        }
      }
      this.gridOptions.api.onFilterChanged();
    },
    filterCards() {
      $("#filter-data-modal-rec").modal("show");
    },
    updateFilterOptions(filterOptions) {
      this.filterOptions = {
        applicationDate: { ...filterOptions.applicationDate },
        moreFilters: { ...filterOptions.moreFilters },
      };
    },
    getCampusEvents() {
      let filters = { campusId: this.recruitmentId };
      api
        .fetchRoundSchedules(filters)
        .then((response) => {
          this.events = response.data;
        })
        .catch(() => {});
    },

    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @description open event round modal to update
     */
    updateEvent() {
      this.$refs["eventModal"].hide();
      $("#addEvent").modal("show");
    },

    setEventModalData() {
      this.eventModalData = {
        modalTitle: "Edit Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };
    },
    setEditEvent(event) {
      this.editEvent = event;
    },
    showEditEvent() {
      this.$refs["eventModal"].show();
    },

    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @description close event modal and fetch events
     */
    closeEventModal() {
      this.$refs["eventModal"].hide();
      this.refreshEvents();
    },

    setParentEntityId(parentEntity_id) {
      this.parentEntity_id = parentEntity_id;
    },

    setChildEntityId(subCollege_id) {
      this.subCollege_id = subCollege_id;
    },
    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @description mail about campgain to selected applicants
     */
    RunCampaign() {
      if (this.isAtleastOneSelected()) {
        this.$store.dispatch("$_applicants_data/RunCampaign", {
          data: this.selectedApplicants,
          redirect: this.$route.fullPath,
        });
      }
    },
    getCampusColleges() {
      api
        .fetchCampusRecruitmentDetailsByUniqueId(
          this.selectedCampus.unique_id,
          true
        )
        .then((response) => {
          this.formData = response.data;
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Unable to fetch form data",
            },
            {
              root: true,
            }
          );
        });
    },
    downloadData() {
      if (this.isAtleastOneSelected()) {
        let columns = this.gridOptions.api.getAllGridColumns();
        columns = columns.filter(function (col) {
          return !col.colDef.display_only;
        });
        let excel_param = {
          columnKeys: columns,
          onlySelected: this.selectedApplicants,
          fileName: "Applicant's Data.csv",
        };
        this.gridOptions.api.exportDataAsCsv(excel_param);
      }
    },
    campusValid() {
      if (moment(this.selectedCampus.last_date).isBefore(this.currentDate)) {
        this.$store.dispatch(
          "openSnackbar",
          { type: "danger", message: "Last Date has been expired!" },
          { root: true }
        );
        return;
      }
      if (this.selectedCampus.status != "inProgress") {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "danger",
            message: "Campus status is " + this.selectedCampus.status,
          },
          { root: true }
        );
        return;
      }
      return true;
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @returns {Boolean}
     * @description Check whether there is atleast one applicant selected to perform any action
     */
    isAtleastOneSelected() {
      if (this.selectedApplicants.length > 0) {
        return true;
      } else {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select atleast one applicant",
          false,
          "danger",
          "modal-m"
        );
        return false;
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @returns {Boolean}
     * @description Checks for all those validations which are required to perform any action
     */
    isCommonConflict() {
      if (this.isAtleastOneSelected()) {
        if (!this.isUnsolvedConflict()) {
          if (!this.isDuplicate()) {
            if (!this.isCampusConflict()) return false;
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    isDuplicate() {
      let isDuplicate = false;
      for (const applicant of this.selectedApplicants) {
        if (applicant.status == "Duplicate") {
          isDuplicate = true;
          break;
        }
      }
      if (!isDuplicate) {
        return false;
      } else {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Duplicate applicant selected!",
          false,
          "danger",
          "modal-m"
        );
        return true;
      }
    },
    handleSendMailButton() {
      setTimeout(() => {
        this.$store.dispatch("$_mailbox/setApplicantsData", {
          data: this.selectedApplicants,
        });
      }, 300);
      this.$router.push({
        name: "mailbox",
        query: {
          key: "MailTo",
          redirect: this.$route.fullPath,
        },
      });
    },
    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @returns {Boolean}
     * @description Check if there is any applicant from selected applicants who has matching profile
     */
    isUnsolvedConflict() {
      let unsolvedConflict = false;
      this.selectedApplicants.forEach((element) => {
        if (element.hasDuplicate.length > 0) {
          unsolvedConflict = true;
        }
      });
      if (unsolvedConflict) {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "There are conflicts in one or more selected applicants",
          false,
          "danger",
          "modal-m"
        );
        return true;
      } else {
        return false;
      }
    },
    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @returns {Boolean}
     * @description Check whether all the applicants belong to same campus or none of them are from campus
     */
    isCampusConflict() {
      let campusConflict = false;
      this.selectedApplicants.forEach((element) => {
        if (
          !element.belongsToCampusRecruitement ||
          element.belongsToCampusRecruitement.id != this.selectedCampus.id
        ) {
          campusConflict = true;
        }
      });
      if (campusConflict) {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Selected applicants should belong to selected campus",
          false,
          "danger",
          "modal-m"
        );
        return true;
      } else {
        return false;
      }
    },
    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @param {String} matchRegex
     * @returns {Boolean}
     * @description Check whether selected applicants are valid to perform required action
     */
    isMatchSelected(matchRegex) {
      let matchSelected = true;
      this.selectedApplicants.forEach((element) => {
        if (matchRegex.test(element.application_status)) {
          matchSelected = false;
        }
      });
      return matchSelected;
    },

    getActionList(type) {
      let wantedApplicants = [];
      let unwantedApplicants = [];
      if (type == "shortlist") {
        this.selectedApplicants.forEach((applicant) => {
          if (
            this.actionStatus.shortlist.regexString.test(
              applicant.application_status
            )
          ) {
            wantedApplicants.push(applicant);
          } else {
            unwantedApplicants.push(applicant);
          }
        });
      } else if (type == "differ") {
        this.selectedApplicants.forEach((applicant) => {
          if (
            this.actionStatus.differ.notOptions.includes(
              applicant.application_status
            )
          ) {
            unwantedApplicants.push(applicant);
          } else {
            wantedApplicants.push(applicant);
          }
        });
        wantedApplicants = this.selectedApplicants;
      } else if (type == "process") {
        this.selectedApplicants.forEach((applicant) => {
          if (
            this.actionStatus.process.regexString.test(
              applicant.application_status
            )
          ) {
            wantedApplicants.push(applicant);
          } else {
            unwantedApplicants.push(applicant);
          }
        });
      } else {
        wantedApplicants = [];
        unwantedApplicants = [];
      }
      return {
        wantedApplicants: wantedApplicants,
        unwantedApplicants: unwantedApplicants,
      };
    },

    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @description Check whether there is atleast one applicant selected to perform any action
     */
    shortlistApplicants() {
      if (!this.isCommonConflict()) {
        const type = "shortlist";
        const filtered = this.getActionList(type);
        if (this.selectedApplicants.length == 0) {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Please select at least one applicant(s)",
            false,
            "danger",
            "modal-m"
          );
          return;
        }
        if (filtered.unwantedApplicants.length > 0) {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Please select only " + this.actionStatus[type].options.join(", "),
            false,
            "danger",
            "modal-m"
          );
          return;
        } else {
          if (filtered.wantedApplicants.length > 0) {
            this.openDialog(
              "commonModal",
              "Confirmation",
              "Are you sure you want to process these applicant(s)?",
              true,
              "warn",
              "modal-m",
              true
            )
              .then(() => {
                this.flag = "ShortlistApplicant";
                if (this.selectedCampus) {
                  $("#commonModal").one("hidden.bs.modal", () => {
                    $("#update-shortlist-modal").modal("show");
                  });
                  return;
                } else {
                  let dataToBeUpdated = [];
                  this.selectedApplicants.forEach((element) => {
                    dataToBeUpdated.push({
                      id: element.id,
                      application_status: "Shortlisted",
                    });
                  });
                  this.$store.dispatch(
                    "$_applicants_data/shortlistApplicants",
                    {
                      data: {
                        dataToBeUpdated: dataToBeUpdated,
                        selectedApplicants: this.selectedApplicants,
                        isApplicantView: false,
                        campusId: this.selectedCampus.id,
                      },
                      redirect: this.$route.fullPath,
                    }
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @description Shortlist multiple applicants and change main application status to selected
     */
    differApplicants() {
      const type = "differ";
      const filtered = this.getActionList(type);
      if (filtered.unwantedApplicants.length > 0) {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select other than Differed applicants(s).",
          false,
          "danger",
          "modal-m"
        );
        return;
      } else {
        if (filtered.wantedApplicants.length > 0) {
          this.openDialog(
            "commonModal",
            "Confirmation",
            "Are you sure you want to process these applicant(s)?",
            true,
            "warn",
            "modal-m",
            true
          )
            .then(() => {
              $("#commonModal").one("hidden.bs.modal", () => {
                this.flag = "DifferApplicant";
                if (this.selectedCampus) {
                  $("#selectToBeMailed").modal("show");
                } else {
                  let dataToBeUpdated = [];
                  filtered.wantedApplicants.forEach((element) => {
                    dataToBeUpdated.push({
                      id: element.id,
                      application_status: "Differed",
                    });
                  });
                  this.$store.dispatch("$_applicants_data/differApplicants", {
                    data: {
                      dataToBeUpdated: dataToBeUpdated,
                      selectedApplicants: filtered.wantedApplicants,
                      isApplicantView: false,
                      campusId: this.selectedCampus.id,
                    },
                    redirect: this.$route.fullPath,
                  });
                }
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.registered_applicant_list
     * @description Differ multiple applicants and change main application status to differed
     */
    processApplicants() {
      if (!this.isCommonConflict()) {
        const type = "process";
        const filtered = this.getActionList(type);
        if (this.selectedApplicants.length == 0) {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Please select at least one applicant(s)",
            false,
            "danger",
            "modal-m"
          );
          return;
        }
        if (filtered.unwantedApplicants.length > 0) {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Please select only " + this.actionStatus[type].options.join(", "),
            false,
            "danger",
            "modal-m"
          );
          return;
        } else {
          if (filtered.wantedApplicants.length > 0) {
            this.openDialog(
              "commonModal",
              "Confirmation",
              "Are you sure you want to process these applicant(s)?",
              true,
              "warn",
              "modal-m",
              true
            )
              .then(() => {
                this.flag = "ProcessApplicant";
                if (this.selectedCampus) {
                  $("#selectToBeMailed").modal("show");
                } else {
                  let dataToBeUpdated = [];
                  this.selectedApplicants.forEach((element) => {
                    dataToBeUpdated.push({
                      id: element.id,
                      application_status: "Processed",
                    });
                  });
                  this.$store.dispatch("$_applicants_data/processApplicants", {
                    dataToBeUpdated: dataToBeUpdated,
                    selectedApplicants: this.selectedApplicants,
                    isApplicantView: false,
                    campusId: this.selectedCampus.id,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }
    },
    showEventModal() {
      // this.selectedEventModal = event
      $("#campusRoundModal").modal("show");
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description method to run on upload applicant page load
     */
    openUploadApplicant() {
      if (this.selectedCampus.status != "inProgress") {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "danger",
            message: "Campus status is " + this.selectedCampus.status,
          },
          { root: true }
        );
        return;
      }
      this.$refs.uploadapplicants.clearFile();
      this.$refs.uploadapplicants.createdUploadPage();
      $("#upload-applicant-model").modal("show");
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description method to check if event is valid
     */
    checkEventValid(event) {
      const currentDate = moment();
      return (
        moment(event.round_schedule).isBefore(currentDate) &&
        !moment(event.round_schedule_end).isBefore(currentDate)
      );
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description method to copy selected active session URL
     */
    copyURL() {
      if (this.campusValid()) {
        const url = `${config.BASE_URL}campus-recruitment-form/${this.selectedCampus.unique_id}`;
        navigator.clipboard.writeText(url).then(() => {
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Successfully Copied URL !" },
            { root: true }
          );
        });
      }
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description method to refresh page data
     */
    refreshData() {
      this.currentDate = moment();
      if (this.selectedCampus) {
        const filters = {
          includeAll: this.includeAll,
          campusId: this.selectedCampus.id,
          employmentStatus: "G",
        };
        if (this.subCollege_id) {
          filters.entityId = this.subCollege_id;
        }
        if (this.filterOptions.applicationDate.fromDate) {
          filters.fromDate = this.filterOptions.applicationDate.fromDate;
        }
        if (this.filterOptions.applicationDate.toDate) {
          filters.toDate = this.filterOptions.applicationDate.toDate;
        }
        if (this.filterOptions.moreFilters.duplicateType) {
          filters.duplicateType = this.filterOptions.moreFilters.duplicateType;
        }
        this.getApplicationStatusCount(filters);
      }
      this.gridOptions.api.setFilterModel(null);
      this.$refs.campusModal.clickToday();
      this.refreshGrid();
    },

    getApplicationStatusCount(filters) {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      this.$store.dispatch(
        "$_campus_recruitment/fetchApplicationStatusCount",
        filters
      );
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description method to set applicant list in data table
     */
    setDataSource() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      let dataSource = {
        rowCount: null,
        rowCountPagination: 0,
        rowCountFiltering: 0,
        _self: this,
        getRows: function (params) {
          if (!this._self.selectedCampus?.id) {
            params.successCallback([], -1);
            return;
          }

          let queryParams = {
            includeAll: this._self.includeAll,
            campusId: this._self.selectedCampus.id,
            includeLastRound: true,
            startRow: params.startRow,
            size: params.endRow - params.startRow,
          };
          if (this._self.subCollege_id) {
            queryParams["entityId"] = this._self.subCollege_id;
          }
          if (this._self.selectedEvent) {
            queryParams["event_round_id"] = this._self.selectedEvent;
          }
          if (this._self.filterOptions.moreFilters.duplicateType) {
            queryParams["duplicateType"] =
              this._self.filterOptions.moreFilters.duplicateType;
          }
          let queryObj = {};
          if (
            this._self.filterOptions.applicationDate.toDate &&
            this._self.filterOptions.applicationDate.fromDate
          ) {
            queryObj["createdAt"] = {
              op: "BETWEEN",
              val: {
                from: this._self.filterOptions.applicationDate.fromDate,
                to: this._self.filterOptions.applicationDate.toDate,
              },
            };
          }
          if (params.sortModel) {
            queryObj.sort = params.sortModel[0];
          }
          for (const key in params.filterModel) {
            if (Object.prototype.hasOwnProperty.call(params.filterModel, key)) {
              const queryData = getFilterByType(params.filterModel[key]);
              queryObj[key] = queryData;
            }
          }
          if (
            Object.keys(params.filterModel).length != 0 ||
            Object.keys(params.sortModel).length != 0
          ) {
            this.rowCountPagination = 0;
            if (params.startRow == 0) {
              this.rowCountFiltering = 0;
            }
            axios
              .post(
                "campus-drive/recruitments/applicants/data-list",
                queryObj,
                { params: queryParams }
              )
              .then(
                (data) => {
                  this.totalVisibleRows = data.data.length;
                  this.rowCountFiltering += data.data.length;
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountFiltering,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.rowCountFiltering = 0;
            axios
              .post(
                "campus-drive/recruitments/applicants/data-list",
                queryObj,
                { params: queryParams }
              )
              .then(
                (data) => {
                  if (params.startRow == 0) {
                    this.rowCountPagination = data.data.length;
                  } else {
                    this.rowCountPagination += data.data.length;
                  }
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountPagination,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
          this._self.resetRowCountData();
        },
      };

      this.gridOptions.api.setGridOption("datasource", dataSource);
    },

    getLastRowValue(rowCount, endRow, defaultValue = -1) {
      if (rowCount < endRow) return rowCount;
      return defaultValue;
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description method to filter data in grid
     */
    async filterApplicationStatus(event) {
      const applicationStatus = event.point.name;
      const selected = !event.point.selected;
      const instance = await this.gridOptions.api.getColumnFilterInstance(
        "application_status"
      );
      if (instance) {
        if (selected) {
          const data = {};
          data.filterType = "text";
          data.type = "equals";
          data.filter = applicationStatus;
          await instance.setModel(data);
          this.applicationStatus = applicationStatus;
        } else {
          const data = await instance.getModel();
          if (data) {
            data.filterType = "text";
            data.type = "equals";
            data.filter = "";
            await instance.setModel(data);
          }
          this.applicationStatus = "";
        }
        this.gridOptions.api.onFilterChanged();
      }
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description handle on change in pag size
     */
    onPageSizeChanged(event) {
      const rowCount = Number(event.target.value);
      this.gridOptions.paginationPageSize = rowCount;
      this.gridOptions.cacheBlockSize = rowCount;
      this.paginationSize = rowCount;
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description handles on refresh grid
     */
    refreshGrid() {
      this.gridOptions.api.paginationGoToFirstPage();
      this.setDataSource();
      this.applicationStatus = null;
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description select all applicant on current page
     */
    selectAllOnCurrentPage() {
      const result = this.rowCountOnCurrentPage !== this.selectedRowCount;
      this.selectedRowCount = 0;
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(result);
        this.selectedRowCount++;
      });
      this.rowCountOnCurrentPage = this.selectedRowCount;
    },
    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description select all applicant on current page
     */
    deselectAllOnCurrentPage() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description Called everytime the selected rows get changed in the grid
     */
    onSelectionChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description Called everytime the filter applied in the grid
     */
    onFilterChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description Called everytime the column sorted in the grid
     */
    onSortChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description method to get data table list on ag grid ready
     */
    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.setDataSource();
    },
    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description method to set date format
     */
    dateFormatter(date) {
      let tempDate = new Date(date);
      tempDate = new Date(
        tempDate.getTime() - tempDate.getTimezoneOffset() * 60 * 1000
      );
      tempDate = JSON.stringify(tempDate);
      tempDate = tempDate.slice(1, -1);
      return tempDate;
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description method to set date format
     */
    getRowStyle(params) {
      if (params && params.data && this.showRowStyles) {
        if (params.data.hasDuplicate && params.data.hasDuplicate.length > 0) {
          return { background: "#fff396" };
        } else if (params.data.status == "Duplicate") {
          return { background: "#fff396" };
        } else if (/Passed/.test(params.data.application_status)) {
          return { background: "#b2ff8f" };
        } else if (/Failed|Differ/.test(params.data.application_status)) {
          return { background: "#f58f82" };
        } else if (/Shortlisted/.test(params.data.application_status)) {
          return { background: "#3788d8" };
        }
      }
    },
    onHidden() {
      console.log("the modal was hidden");
    },
    onPaginationChanged() {
      if (this.gridOptions && this.gridOptions.api) {
        this.maxApplicantCountToCurrentPage =
          this.gridOptions.api.paginationGetPageSize() *
          (this.gridOptions.api.paginationGetCurrentPage() + 1);
      }
    },
    configGridOptions() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      this.gridOptions = {
        overlayLoadingTemplate: this.loadingTemplate,
        overlayNoRowsTemplate: this.noRowsTemplate,
        columnDefs: getFilterColumns(this.columnDefs, 2, "lg"),
        rowHeight: 30,
        suppressPropertyNamesCheck: true,
        suppressRowClickSelection: true,
        enableServerSideFilter: true,
        enableCellTextSelection: true,
        enableColResize: true,
        rowSelection: "multiple",
        suppressRowDeselection: true,
        rowModelType: "infinite",
        getRowStyle: (event) => this.getRowStyle(event),
        defaultColDef: {
          flex: 1,
          resizable: true,
          floatingFilter: true,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
          filter: true,
        },
        infiniteInitialRowCount: 0,
        animateRows: true,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        pagination: true,
        paginationPageSize: 100,
        paginationPageSizeSelector: false,
        cacheBlockSize: 100,
        context: {
          componentParent: this,
        },
      };
    },
    fetchCampusData() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      const id = this.recruitmentId;
      const filter = "?campusId=" + id;
      api
        .fetchCampuses(filter)
        .then((res) => {
          this.$store.dispatch(
            "$_campus_recruitment/setSelectedCampus",
            res.data[0]
          );
          this.getCampusColleges();
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    fetchJobs() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.UPDATE] })) return;
      this.$store.dispatch("$_applicant_profile/fetchJobProfiles");
    },
    searchApiPromise(searchQuery) {
      let query = "searchQuery=" + searchQuery;
      if (this.selectedCampus) {
        query += "&campusId=" + this.selectedCampus.id;
      }
      return axios.post("applicants/search?" + query);
    },
    searchRouterLink(slug) {
      return { name: "applicantprofile", params: { slug } };
    },
    dateFormatMethod(date) {
      return moment(date).format(" MMMM Do YYYY, h:mm a");
    },

    isFilterOptionsEmpty() {
      // Compare filterOptions with its initialized value
      return (
        JSON.stringify(this.filterOptions) ===
        JSON.stringify({
          applicationDate: {
            filterType: "",
            fromDate: null,
            toDate: null,
          },
          moreFilters: {
            duplicateType: "",
            campusType: "",
          },
        })
      );
    },
  },

  beforeMount() {
    this.configGridOptions();
  },
  destroyed() {
    this.$store.dispatch("$_campus_recruitment/resetSelectedCampus");
    this.$store.dispatch("$_campus_recruitment/resetStatusCount");
  },
  mounted() {
    $(document).on("click", "div.dropdown-filters.dropdown-menu", function (e) {
      e.stopPropagation();
    });
    this.getCampusEvents();
    this.title = "Campus Recruitment Applications";
    this.fetchCampusData();
    this.fetchJobs();
  },
  filters: {
    dateFormat(date) {
      return moment(date).format(" MMMM Do YYYY, h:mm a");
    },
  },
};
</script>
<style>
.modal {
  transition: none;
}
</style>

<style scoped>
.ag-theme-balham
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
}

.ag-grid-style {
  width: 100%;
  height: 86%;
}

.dashboard-card {
  padding: 10px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 15px;
  overflow-x: auto;
}

.dashboard-box-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.application-container {
  margin: 20px 40px 20px 20px;
}

.dropdown-menu {
  top: 50px;
}

.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 3px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #803059;
  color: white;
}

.dropdown-menu .dropdown-header {
  padding: 3px 20px;
  color: #823e5b;
  font-size: 16px;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

li.dropdown > a {
  color: white;
}

.pending-count-circle {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  background-color: #9b3f6f;
  position: absolute;
  top: 7px;
  right: 19px;
}

.pre-number {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  background-color: #9b3f6f;
  position: relative;
  top: -10px;
  right: 5px;
}

@media screen and (max-width: 767px) {
  .spinner {
    display: none;
  }

  .top-buttons {
    margin-top: 10px;
  }
}

.active-session {
  font-weight: 600;
}

.inactive-session {
  color: gray;
}

.inactive-session:hover {
  color: gray;
}

.entity-dropdown {
  min-width: 120px;
  max-width: 180px;
}

.row {
  margin: 0px !important;
}

.ag-theme-balham {
  padding: 0px 15px;
}

.custom-select {
  width: 150px;
}

.dropdown-height {
  width: 160px;
  max-width: 160px;
}

.dropdown-toggle-entity-label {
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .hide-on-desktop {
    display: none !important;
  }
}
.button-curve {
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
._registered-applicant-list .alert-sm {
  height: 2.35rem;
  margin-bottom: 0px;
}
</style>
