<template>
  <div class="content">
    <common-header
      :data="['home', 'campusDrive', 'entityDetails', 'viewEntityDetails']"
      :componentName="entityData.entity_name"
    >
      <router-link
        v-if="
          hasAccess({ permissions: [BASE_PERMISSION.UPDATE] }) && entityData
        "
        target="_blank"
        class="btn ml-2 btn-basic"
        :to="{ name: 'editEntityForm' }"
        ><i class="fa fa-pencil-square-o cst-color" aria-hidden="true"></i
      ></router-link>
    </common-header>
    <div class="row mx-0">
      <div class="col-md-7 col-xs-7">
        <entity-event-modal
          ref="entityModal"
          :entityId="calenderEntityId"
          :onHidden="onHidden"
          :setEditEvent="setEditEvent"
          :showEditEvent="showEditEvent"
          :setEventModalData="setEventModalData"
        />
      </div>
      <div
        class="col-md-5 col-xs-5 border-left mt-4 mt-md-0"
        v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
      >
        <div
          class="d-none d-sm-block mb-3 position-relative"
          v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
        >
          <applicant-search-bar
            :apiPromise="searchApiPromise"
            :routerLink="searchRouterLink"
            :isCampusRecruitment="true"
            color="#8f1452"
          />
        </div>
        <div>
          <div class="m-0 my-1">
            <div
              class="d-flex align-items-center flex-wrap justify-content-end"
            >
              <label class="checkbox-inline m-0 d-flex align-items-center mx-2">
                <input
                  style="margin-right: 5px"
                  type="checkbox"
                  v-model="includeAll"
                />
                <span
                  title="Differed, Joined, Talent Pool"
                  class="pe-none"
                  tabindex="-1"
                  aria-disabled="true"
                  >Include All</span
                >
              </label>
              <div class="mx-1" v-if="entityData?.entity_type === 'University'">
                <select
                  id="entityPicker"
                  class="select selectpicker"
                  data-live-search="true"
                  data-width="100%"
                  v-model="selectedEntityId"
                  :data-dropdown-align-right="true"
                >
                  <option :value="null">All Entities</option>
                  <option
                    :value="option.id"
                    v-for="(option, index) in entityData.childEntities"
                    :key="index"
                  >
                    {{ option.entity_name }}
                  </option>
                </select>
              </div>
              <div
                class="mx-1"
                v-if="
                  entityData.entity_type != 'University' ||
                  (entityData.entity_type == 'University' && selectedEntityId)
                "
              >
                <select
                  id="recruitmentPicker"
                  class="select selectpicker"
                  data-live-search="true"
                  data-width="100%"
                  v-model="selectedRecruitmentId"
                  :data-dropdown-align-right="true"
                >
                  <option :value="null">All Recruitments</option>
                  <option
                    :value="option.id"
                    v-for="(option, index) in allRecruitments"
                    :key="index"
                  >
                    {{ option.recruitment_session_name }}
                  </option>
                </select>
              </div>
              <div class="my-1">
                <button class="btn btn-cp mx-1" @click="refreshData()">
                  <i class="fa fa-repeat px-0" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <common-chart
            chartId="status-chart"
            chartTitle="Status-wise"
            :colorIdx="0"
            :dataSlices="statusCountSlices"
            v-on:filter-data="filterApplicationStatus($event)"
          >
            <div class="filter-text" v-if="applicationStatus">
              <span v-if="applicationStatus"
                ><em class="fa fa-filter"></em> Application Status :
                {{ applicationStatus }}</span
              ><br />
            </div>
          </common-chart>
        </div>
        <b-modal
          ref="eventModal"
          :title="eventModalData.modalTitle"
          :size="eventModalData.modalSize"
          :hide-footer="eventModalData.hideFooter"
          :showEditEvent="showEditEvent"
        >
          <div class="d-flex justify-content-center align-items-center">
            <h4 class="text-center edit-or-create" v-if="eventData">
              Recruitment drive - {{ eventData.end | dateFormatFilter }}
            </h4>
          </div>
          <hr />
          <b-tabs content-class="mt-3" pills v-if="eventData">
            <b-tab title="Event Details">
              <div class="well container">
                <table
                  class="table table-condensed"
                  aria-label="Entity Details"
                  aria-hidden="true"
                >
                  <tbody>
                    <tr>
                      <td class="col-md-4 col-6">
                        <strong>Title</strong>
                      </td>
                      <td>{{ eventData.round_title }}</td>
                    </tr>
                    <tr>
                      <td class="col-md-4 col-6">
                        <strong>Status</strong>
                      </td>
                      <td>{{ eventData.status }}</td>
                    </tr>
                    <tr>
                      <td class="col-md-4 col-6">
                        <strong>Date</strong>
                      </td>
                      <td>
                        {{ eventData.start | dateFormatFilter }}{{ " - "
                        }}{{ eventData.end | dateFormatFilter }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-4 col-6">
                        <strong>Type</strong>
                      </td>
                      <td>{{ eventData.round_name }}</td>
                    </tr>
                    <tr>
                      <td class="col-md-4 col-6">
                        <strong>Dependency</strong>
                      </td>
                      <td>
                        {{
                          eventData.dependency && eventData.dependency[0]
                            ? getDependencyData(eventData.dependency[0])
                                .round_title
                            : " - "
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
            <b-tab title="Campus Details" active>
              <div class="well container">
                <table
                  class="table table-condensed"
                  aria-label="Campus Details"
                  aria-hidden="true"
                >
                  <tbody>
                    <tr>
                      <td class="col-md-4 col-6">
                        <strong>Recruitment</strong>
                      </td>
                      <td>
                        {{ eventData.campusDetail?.recruitment_session_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-4 col-6">
                        <strong>Status</strong>
                      </td>
                      <td>{{ eventData.campusDetail?.status }}</td>
                    </tr>

                    <tr>
                      <td class="col-md-4 col-6">
                        <strong>Last Date</strong>
                      </td>
                      <td>{{ eventData.end | dateFormatFilter }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
          </b-tabs>
        </b-modal>
      </div>
    </div>

    <div class="container-fluid">
      <CollapseCard
        class="theme-default my-2"
        title="Entity Details"
        :midDivider="false"
        header-class="px-0 py-0"
        :collapse="true"
        title-class="h5 m-0"
      >
        <div class="entity-details table-responsive mb-0">
          <table class="table" aria-hidden="true">
            <caption style="display: none">
              This table displays the basic details of the selected entity.
            </caption>
            <tbody>
              <tr>
                <td class="cell">
                  <strong style="font-weight: 600">Entity Type</strong>
                </td>
                <td class="cell">{{ entityData.entity_type }}</td>
                <td class="cell">
                  <strong style="font-weight: 600">Entity Name</strong>
                </td>
                <td class="cell">{{ entityData.entity_name }}</td>
              </tr>
              <tr>
                <td class="cell">
                  <strong style="font-weight: 600">Entity Parent</strong>
                </td>
                <td class="cell">
                  {{ parentName }}
                </td>
                <td class="cell">
                  <strong style="font-weight: 600">City</strong>
                </td>
                <td class="cell">{{ entityData.city }}</td>
              </tr>
              <tr>
                <td class="cell">
                  <strong style="font-weight: 600">State</strong>
                </td>
                <td class="cell">{{ entityData.state }}</td>
                <td class="cell">
                  <strong style="font-weight: 600">Pincode</strong>
                </td>
                <td class="cell">{{ entityData.pincode }}</td>
              </tr>
              <tr>
                <td class="cell">
                  <strong style="font-weight: 600">Website</strong>
                </td>
                <td class="cell">
                  {{ entityData.website ? entityData.website : "NA" }}
                </td>
                <td class="cell">
                  <strong style="font-weight: 600">Status</strong>
                </td>
                <td class="cell">
                  {{
                    entityData.status ? formatStatus(entityData.status) : "NA"
                  }}
                </td>
              </tr>
              <tr>
                <td class="cell">
                  <strong style="font-weight: 600">Remarks</strong>
                </td>
                <td class="cell">
                  {{ entityData.remarks ? entityData.remarks : "NA" }}
                </td>
                <td class="cell">
                  <strong style="font-weight: 600">Ratings</strong>
                </td>
                <td class="cell">
                  <span
                    class="fa fa-star fa-lg mx-1"
                    :class="{
                      'star-checked':
                        rating.rating <= ratingConverter(entityData.ratings),
                    }"
                    v-for="(rating, index) in ratingsArray"
                    :key="index"
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card_footer text-right border-top"></div>
      </CollapseCard>
      <CollapseCard
        class="theme-default my-2"
        title="Contact Persons"
        :midDivider="false"
        header-class="px-0 py-0"
        :collapse="true"
        :open="false"
        title-class="h5 m-0"
      >
        <div class="entity-details table-responsive mb-0">
          <div class="table-responsive mb-0">
            <caption style="display: none">
              This table displays the Contact Persons of the selected entity.
            </caption>
            <table class="table" aria-hidden="true">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(contact, index) in entityData.contactPersonDetails"
                  :key="index"
                >
                  <td class="col-3">{{ contact.name }}</td>
                  <td class="col-3">{{ contact.mobile_no }}</td>
                  <td class="col-3">{{ contact.email }}</td>
                  <td>
                    <i
                      class="fa fa-circle"
                      aria-hidden="true"
                      :style="[
                        contact.status === 'Active'
                          ? { color: 'green' }
                          : { color: 'red' },
                      ]"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card_footer text-right border-top"></div>
      </CollapseCard>
      <CollapseCard
        class="theme-default my-2"
        title="Course Details"
        :midDivider="false"
        header-class="px-0 py-0"
        :collapse="true"
        :open="false"
        title-class="h5 m-0"
      >
        <div class="entity-details table-responsive mb-0">
          <div class="table-responsive mb-0">
            <caption style="display: none">
              This table displays the Course details of the selected entity.
            </caption>
            <table class="table" aria-hidden="true">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Strength</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(course, index) in entityData.courseDetails"
                  :key="index"
                >
                  <td class="col-6">{{ course.course_name }}</td>
                  <td class="col-3">{{ course.course_strength }}</td>
                  <td class="col-3">
                    <i
                      class="fa fa-circle"
                      aria-hidden="true"
                      :style="[
                        course.status === 'Active'
                          ? { color: 'green' }
                          : { color: 'red' },
                      ]"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card_footer text-right border-top"></div>
      </CollapseCard>
      <CollapseCard
        v-if="entityData.entity_type === 'University'"
        class="theme-default my-2"
        title="Child Entities"
        :midDivider="false"
        header-class="px-0 py-0"
        :collapse="true"
        :open="false"
        title-class="h5 m-0"
      >
        <div class="p-2">
          <div v-if="entityData.childEntities.length > 0">
            <div v-for="item in entityData.childEntities" :key="item.id">
              <b-card no-body class="mt-2 smaller-card">
                <b-card-header
                  v-b-toggle="'collapse-' + item.id"
                  class="pointer custom-header"
                >
                  {{ item.entity_name }}
                </b-card-header>

                <b-collapse
                  :id="'collapse-' + item.id"
                  accordion="entity-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-tabs>
                      <b-tab title="Entity Details">
                        <table class="table table-striped">
                          <tbody>
                            <tr>
                              <td><strong>Entity Type</strong></td>
                              <td>{{ item.entity_type }}</td>
                              <td><strong>Entity Name</strong></td>
                              <td>{{ item.entity_name }}</td>
                            </tr>
                            <tr>
                              <td><strong>City</strong></td>
                              <td>{{ item.city }}</td>
                              <td><strong>State</strong></td>
                              <td>{{ item.state }}</td>
                            </tr>
                            <tr>
                              <td><strong>Pincode</strong></td>
                              <td>{{ item.pincode }}</td>
                              <td><strong>Status</strong></td>
                              <td>{{ item.status }}</td>
                            </tr>
                            <tr>
                              <td class="cell">
                                <strong style="font-weight: 600"
                                  >Remarks</strong
                                >
                              </td>
                              <td class="cell">
                                {{ item.remarks ? item.remarks : "NA" }}
                              </td>
                              <td class="cell">
                                <strong style="font-weight: 600"
                                  >Ratings</strong
                                >
                              </td>
                              <td class="cell">
                                <span
                                  class="fa fa-star fa-lg mx-1"
                                  :class="{
                                    'star-checked':
                                      rating.rating <=
                                      ratingConverter(item.ratings),
                                  }"
                                  v-for="(rating, index) in ratingsArray"
                                  :key="index"
                                ></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-tab>

                      <b-tab title="Contact Persons">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Contact</th>
                              <th>Email</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                contact, idx
                              ) in item.contactPersonDetails"
                              :key="idx"
                            >
                              <td>{{ contact.name }}</td>
                              <td>{{ contact.mobile_no }}</td>
                              <td>{{ contact.email }}</td>
                              <td>
                                <i
                                  class="fa fa-circle"
                                  aria-hidden="true"
                                  :style="[
                                    contact.status === 'Active'
                                      ? { color: 'green' }
                                      : { color: 'red' },
                                  ]"
                                ></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-tab>

                      <b-tab title="Course Details">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Strength</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(course, idx) in item.courseDetails"
                              :key="idx"
                            >
                              <td>{{ course.course_name }}</td>
                              <td>{{ course.course_strength }}</td>
                              <td class="col-3">
                                <i
                                  class="fa fa-circle"
                                  aria-hidden="true"
                                  :style="[
                                    course.status === 'Active'
                                      ? { color: 'green' }
                                      : { color: 'red' },
                                  ]"
                                ></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-tab>
                    </b-tabs>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div v-else>
            <p>No child entities found.</p>
          </div>
        </div>
      </CollapseCard>
      <div class="row mx-0 pl-0 pb-3 pt-3 flex-wrap justify-content-between">
        <div class="d-flex flex-wrap">
          <div class="status-pagesize mx-1 my-1">
            <select
              v-model="filterStatus"
              id="filter-status"
              class="select-cp px-2 form-control"
            >
              <option class="selectoptions" value="" selected="">All</option>
              <option class="selectoptions" value="joined">Joined</option>
              <option class="selectoptions" value="shortlisted">
                Shortlisted
              </option>
              <option class="selectoptions" value="failed">
                Differed/Failed
              </option>
            </select>
          </div>
          <div class="status-pagesize mx-1 my-1">
            <select
              id="eventPicker"
              class="bt-select"
              data-live-search="true"
              data-width="100%"
              v-model="eventFilter"
              :data-dropdown-align-left="true"
            >
              <option class="selectoptions" value="" selected="">
                All Events
              </option>
              <option
                :value="option.id"
                v-for="(option, index) in filteredEventList"
                :key="index"
              >
                {{ option.round_title }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex my-1 ml-auto">
          <div class="ml-2">
            <select
              @change="onPageSizeChanged($event)"
              class="select-cp form-control"
            >
              <option class="pagesizeoption" value="50">50</option>
              <option class="pagesizeoption" value="100" selected="">
                100
              </option>
              <option class="pagesizeoption" value="200">200</option>
              <option class="pagesizeoption" value="500">500</option>
              <option class="pagesizeoption" value="1000">1000</option>
              <option class="pagesizeoption" value="2000">2000</option>
            </select>
          </div>
        </div>
      </div>
      <div class="py-1">
        <ag-grid-vue
          v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
          class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
          style="height: 70vh"
          :enableBrowserTooltips="true"
          :gridOptions="gridOptions"
          @selection-changed="onSelectionChanged"
          @filter-changed="onFilterChanged"
          @sort-changed="onSortChanged"
          @gridReady="onGridReady"
          :key="paginationSize"
        ></ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import api from "../_api/index";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import axios from "axios";
import EntityEventModal from "./entity-event-modal.vue";
import ApplicantSearchBar from "../../Common/_components/applicant-search-bar.vue";
import { AgGridVue } from "ag-grid-vue";
import ResolveDuplicateLink from "./resolve-duplicate-link.vue";
import { APPLICANTS_DATA_COL_DEFS } from "../_utils/campus_application_data_col_defs";
import EditApplicantLink from "../../Common/_components/edit-applicant-link.vue";
import { getFilterColumns } from "@/modules/Common/_plugin/validation";
import CommonChart from "../../Common/_components/common-highchart.vue";
import getFilterByType from "@/modules/Common/_utils/get-filter-by-type";

/**
 * @memberof module:campusRecruitment
 * @namespace components.entity_details
 */
export default {
  /**
   * @memberof module:campusRecruitment.components.entity_details
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      loadingTemplate: "Loading...",
      noRowsTemplate: "No applicants found",
      applicationStatus: null,
      selectedEntityId: null,
      calenderEntityId: this.$route.params.entityId,
      selectedRecruitmentId: null,
      rowCountOnCurrentPage: -1,
      selectedRowCount: 0,
      filterStatus: "",
      eventFilter: "",
      includeAll: true,
      paginationSize: 100,
      totalVisibleRows: null,
      showRowStyles: true,
      gridOptions: null,
      ratingsArray: [
        { rating: 1, value: "poor" },
        { rating: 2, value: "moderate" },
        { rating: 3, value: "good" },
        { rating: 4, value: "very good" },
        { rating: 5, value: "excellent" },
      ],
      data: {
        id: null,
        entity_name: null,
        entity_type: "College",
        parent_entity: null,
        website: null,
        state: "Gujarat",
        city: null,
        pincode: null,
        status: "active",
        ratings: null,
        remarks: null,
        contactList: [],
        courseList: [],
      },
      contact: {},
      course: null,
      eventData: null,
      columnDefs: APPLICANTS_DATA_COL_DEFS.map((col, index) =>
        index === 3 ? { ...col, checkboxSelection: false } : col
      ),
      eventModalData: {
        modalTitle: "Show Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      },
      eventList: [],
      parentName: "",
    };
  },

  components: {
    CollapseCard,
    EntityEventModal,
    ApplicantSearchBar,
    CommonChart,
    // eslint-disable-next-line vue/no-unused-components
    "resolve-link": ResolveDuplicateLink,
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{name:'applicantprofile', params:{slug:params.data.slug}}"><i title="View Profile" class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "edit-profile-data": EditApplicantLink,
  },
  computed: {
    ...mapGetters({
      entityData: "$_campus_recruitment/fetchEntityModalData",
      statusCount: "$_campus_recruitment/applicationStatusCount",
    }),
    allRecruitments() {
      if (!this.entityData.id) return [];
      if (this.entityData.entity_type !== "University")
        return this.entityData.campusRecruitments;
      if (!this.selectedEntityId) return [];
      return this.entityData.childEntities.find(
        (en) => en.id === this.selectedEntityId
      ).campusRecruitments;
    },
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT;
    },
    statusCountSlices() {
      return this.statusCount.map((status) => {
        if (parseInt(status.status_count) !== 0) {
          return {
            name: status.status_name,
            y: parseInt(status.status_count),
          };
        }
      });
    },
    filteredEventList() {
      if (this.selectedRecruitmentId) {
        return this.eventList.filter(
          (option) => option.campusId === this.selectedRecruitmentId
        );
      }
      return this.eventList;
    },
  },

  /**
   * @memberof module:campusRecruitment.components.entity_details
   * @description lifecycle method - fetch entity
   */
  updated() {
    $("#entityPicker").selectpicker("refresh");
    $("#recruitmentPicker").selectpicker("refresh");
    $("#eventPicker").selectpicker("refresh");
  },
  filters: {
    dateFormatFilter(date) {
      return moment(date).format(" MMMM Do YYYY, h:mm a");
    },
  },
  beforeMount() {
    this.configGridOptions();
  },
  async mounted() {
    const entityId = parseInt(this.$route.params.entityId);
    await api.entityId(entityId).then((res) => {
      this.$store.dispatch(
        "$_campus_recruitment/setUpdateEntityModalData",
        res.data
      );
    });
    this.parentName = await this.getParentEntityName();
    this.getEventList(entityId);
  },
  methods: {
    getApplicationStatusCount(filters) {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      this.$store.dispatch(
        "$_campus_recruitment/fetchApplicationStatusCount",
        filters
      );
    },
    async filterApplicationStatus(event) {
      const applicationStatus = event.point.name;
      const selected = !event.point.selected;
      const instance = await this.gridOptions.api.getColumnFilterInstance(
        "application_status"
      );
      if (instance) {
        if (selected) {
          const data = {};
          data.filterType = "text";
          data.type = "equals";
          data.filter = applicationStatus;
          await instance.setModel(data);
          this.applicationStatus = applicationStatus;
        } else {
          const data = await instance.getModel();
          if (data) {
            data.filterType = "text";
            data.type = "equals";
            data.filter = "";
            await instance.setModel(data);
          }
          this.applicationStatus = "";
        }
        this.gridOptions.api.onFilterChanged();
      }
    },
    /**
     * @memberof module:campusRecruitment.components.entity_details
     * @description Called everytime the selected rows get changed in the grid
     */
    onSelectionChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:campusRecruitment.components.entity_details
     * @description Called everytime the filter applied in the grid
     */

    onFilterChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:campusRecruitment.components.entity_details
     * @description Called everytime the column sorted in the grid
     */
    onSortChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    onPageSizeChanged(event) {
      const rowCount = Number(event.target.value);
      this.gridOptions.paginationPageSize = rowCount;
      this.gridOptions.cacheBlockSize = rowCount;
      this.paginationSize = rowCount;
    },
    refreshData() {
      this.currentDate = moment();
      if (this.entityData) {
        const filters = {
          includeAll: this.includeAll,
        };
        if (this.selectedRecruitmentId) {
          filters["campusId"] = this.selectedRecruitmentId;
        }
        if (this.entityData.entity_type === "University") {
          if (this.selectedEntityId) {
            filters["entityId"] = this.selectedEntityId;
          } else {
            filters["parent_entity"] = this.entityData.id;
          }
        } else {
          filters["entityId"] = this.entityData.id;
        }
        this.getApplicationStatusCount(filters);
      }
      this.$refs.entityModal.clickToday();
      this.refreshGrid();
    },

    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.setDataSource();
    },
    refreshGrid() {
      this.gridOptions.api.paginationGoToFirstPage();
      this.gridOptions.api.onFilterChanged();
      this.applicationStatus = null;
    },

    getLastRowValue(rowCount, endRow, defaultValue = -1) {
      if (rowCount < endRow) return rowCount;
      return defaultValue;
    },

    resetRowCountData() {
      this.rowCountOnCurrentPage = -1;
      this.selectedRowCount = 0;
    },
    /**
     * @memberof module:campusRecruitment.components.entity_details
     * @description fetch applicant list
     */
    setDataSource() {
      if (!this.hasMenuAccess()) return;
      let dataSource = {
        rowCount: null,
        rowCountPagination: 0,
        rowCountFiltering: 0,
        _self: this,
        getRows: function (params) {
          if (!this._self.entityData.id) {
            params.successCallback([], -1);
            return;
          }

          let queryParams = {
            includeAll: this._self.includeAll,
            startRow: params.startRow,
            size: params.endRow - params.startRow,
            includeLastRound: true,
          };
          if (this._self.filterStatus) {
            queryParams["status"] = this._self.filterStatus;
          }
          if (this._self.eventFilter) {
            queryParams["event_round_id"] = this._self.eventFilter;
          }
          let queryObj = {};
          if (this._self.selectedRecruitmentId) {
            queryParams["campusId"] = this._self.selectedRecruitmentId;
          }
          if (this._self.entityData.entity_type === "University") {
            if (this._self.selectedEntityId) {
              queryParams["entityId"] = this._self.selectedEntityId;
            }
            queryObj = {
              extraFilters: {
                parent_entity: [this._self.entityData.id],
              },
            };
          } else {
            queryParams["entityId"] = this._self.entityData.id;
          }
          if (params.sortModel) {
            queryObj.sort = params.sortModel[0];
          }
          for (const key in params.filterModel) {
            if (Object.prototype.hasOwnProperty.call(params.filterModel, key)) {
              const queryData = getFilterByType(params.filterModel[key]);
              queryObj[key] = queryData;
            }
          }
          if (
            Object.keys(params.filterModel).length != 0 ||
            Object.keys(params.sortModel).length != 0
          ) {
            this.rowCountPagination = 0;
            if (params.startRow == 0) {
              this.rowCountFiltering = 0;
            }
            axios
              .post(
                "campus-drive/recruitments/applicants/data-list",
                queryObj,
                { params: queryParams }
              )
              .then(
                (data) => {
                  this.rowCountFiltering += data.data.length;
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountFiltering,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.rowCountFiltering = 0;
            axios
              .post(
                "campus-drive/recruitments/applicants/data-list",
                queryObj,
                { params: queryParams }
              )
              .then(
                (data) => {
                  if (params.startRow == 0) {
                    this.rowCountPagination = data.data.length;
                  } else {
                    this.rowCountPagination += data.data.length;
                  }
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountPagination,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
          this._self.resetRowCountData();
        },
      };

      this.gridOptions.api.setGridOption("datasource", dataSource);
    },
    getRowStyle(params) {
      if (params && params.data && this.showRowStyles) {
        if (params.data.hasDuplicate && params.data.hasDuplicate.length > 0) {
          return { background: "#fff396" };
        } else if (params.data.status == "Duplicate") {
          return { background: "#fff396" };
        } else if (/Passed/.test(params.data.application_status)) {
          return { background: "#b2ff8f" };
        } else if (/Failed|Differ/.test(params.data.application_status)) {
          return { background: "#f58f82" };
        } else if (/Shortlisted/.test(params.data.application_status)) {
          return { background: "#3788d8" };
        }
      }
    },
    searchRouterLink(slug) {
      return { name: "applicantprofile", params: { slug } };
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    hasMenuAccess() {
      return this.hasAccess({
        permissions: [
          this.BASE_PERMISSION.MENU,
          this.PERMISSIONS.APPLICANT["CAMPUS-SESSION"],
        ],
        checkAll: false,
      });
    },
    ratingConverter(rating) {
      if (typeof rating === "string") {
        switch (rating) {
          case "poor":
            return 1;
          case "moderate":
            return 2;
          case "good":
            return 3;
          case "very good":
            return 4;
          case "excellent":
            return 5;
          default:
            return null;
        }
      } else {
        return rating;
      }
    },
    setRating(rating) {
      this.data.ratings = rating;
    },

    entityNameValidator(value) {
      return /^[^-\s][a-zA-Z. ]*$/.test(value);
    },
    showEditEvent() {
      this.$refs["eventModal"].show();
    },
    setEditEvent(event) {
      this.eventData = event;
    },
    setEventModalData() {
      this.eventModalData = {
        modalTitle: "Show Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };
    },
    async getEventList(currentEntityId) {
      const data = await api.getEntityRoundSchedules({
        entityId: currentEntityId,
      });
      this.eventList = data.data.map((event) => ({ ...event }));
    },
    getDependencyData(id) {
      const dependecyData = this.eventList.find((event) => {
        return event.id == id;
      });
      return dependecyData;
    },
    closeEventModal() {
      this.$refs["eventModal"].hide();
      this.refreshEvents();
    },
    formatStatus(status) {
      return status.charAt(0).toUpperCase() + status.slice(1);
    },
    searchApiPromise(searchQuery) {
      let params = { searchQuery };
      if (this.entityData.entity_type !== "University") {
        params.entityId = this.entityData.id;
      } else {
        params.parent_entity = this.entityData.id;
      }
      if (this.selectedRecruitmentId) {
        params.campusId = this.selectedRecruitmentId;
      }
      return axios.post("applicants/search", {}, { params: params });
    },
    onHidden() {
      console.log("the modal was hidden");
    },
    configGridOptions() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      this.gridOptions = {
        overlayLoadingTemplate: this.loadingTemplate,
        overlayNoRowsTemplate: this.noRowsTemplate,
        columnDefs: getFilterColumns(this.columnDefs, 2, "lg"),
        rowHeight: 30,
        suppressPropertyNamesCheck: true,
        suppressRowClickSelection: true,
        enableServerSideFilter: true,
        enableCellTextSelection: true,
        enableColResize: true,
        rowSelection: "multiple",
        suppressRowDeselection: true,
        rowModelType: "infinite",
        defaultColDef: {
          flex: 1,
          resizable: true,
          floatingFilter: true,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
          filter: true,
        },
        getRowStyle: (event) => this.getRowStyle(event),
        infiniteInitialRowCount: 0,
        animateRows: true,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        pagination: true,
        paginationPageSize: 100,
        paginationPageSizeSelector: false,
        cacheBlockSize: 100,
        context: {
          componentParent: this,
        },
      };
    },
    async getParentEntityName() {
      if (!this.entityData.parent_entity) return "NA";
      try {
        const res = await api.entityId(this.entityData.parent_entity);
        return res.data.entity_name || "NA";
      } catch (error) {
        console.error(error);
        return "NA";
      }
    },
  },

  watch: {
    selectedEntityId() {
      this.eventFilter = "";
      if (this.selectedEntityId) {
        this.calenderEntityId = this.selectedEntityId;
        this.getEventList(this.selectedEntityId);
      } else {
        this.calenderEntityId = this.entityData.id;
        this.getEventList(this.entityData.id);
      }

      this.selectedRecruitmentId = null;
      this.refreshData();
    },
    selectedRecruitmentId() {
      this.refreshData();
    },
    includeAll() {
      this.refreshData();
    },
    filterStatus() {
      this.gridOptions.api.onFilterChanged();
    },
    eventFilter() {
      this.refreshData();
    },

    entityData() {
      this.refreshData();
    },
  },
};
</script>

<style scoped>
.form_page_wrapper {
  width: 1140px;
  padding-bottom: 20px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
}
.content h1 {
  border-bottom: none !important;
}
.form_page_wrapper .form-footer {
  border: 0px;
}

.updateOptions {
  padding-left: 40px;
}

@media screen and (max-width: 1366px) {
  .form_page_wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .form_page_wrapper {
    width: 90%;
  }
}

.form_page_wrapper .form_header {
  font-size: 26px;
  text-align: center;
  font-weight: 700;
  padding-top: 30px;
  border: 0px;
  margin-bottom: 10px;
}

.form_page_wrapper label {
  font-weight: 600;
  font-size: 14px;
}

.form_page_wrapper input:focus {
  box-shadow: none;
}

.form_page_wrapper select:focus {
  box-shadow: none;
}

.m-10 {
  margin: 5px 10px;
}

.star-checked {
  color: orange;
}
.table-div {
  overflow-x: auto;
}

.table-th {
  min-width: 180px;
  max-width: 200px;
}

.table-th-small {
  min-width: 150px;
  max-width: 150px;
}

.table-th-remarks {
  min-width: 180px;
  white-space: nowrap;
}
.cst-color {
  color: #8f1452;
  font-size: x-large;
}
.smaller-card {
  width: 98%;
  margin: auto;
}
.custom-header {
  padding: 5px 15px;
}
</style>
