<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Mail Recepient</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group">
              <label class="col-sm-3 control-label"
                >To be Mailed <span class="text-danger">*</span></label
              >
              <label class="checkbox-inline">
                <input
                  type="checkbox"
                  value="Coordinator"
                  v-model="isCoordinator"
                />
                Coordinator
              </label>
              <label class="checkbox-inline">
                <input type="checkbox" value="Students" v-model="isStudent" />
                Applicants
              </label>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'SendMail'"
            class="btn btn-cp ml-2"
            @click="sendCampusMail"
            data-dismiss="modal"
          >
            Send Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'ProcessApplicant'"
            class="btn btn-danger"
            @click="processCampus"
            data-dismiss="modal"
          >
            Process and Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'ShortlistApplicant'"
            class="btn btn-success"
            @click="selectCampus"
            data-dismiss="modal"
          >
            Shortlist and Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'JoinedApplicant'"
            class="btn btn-secondary br-32"
            @click="joinedCampus"
            data-dismiss="modal"
          >
            Joined and Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'DifferApplicant'"
            class="btn btn-danger br-32"
            @click="rejectCampus"
            data-dismiss="modal"
          >
            Differ and Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'HoldApplicant'"
            class="btn btn-warning br-32"
            @click="holdCampus"
            data-dismiss="modal"
          >
            Hold and Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'SetNotAppeared'"
            class="btn btn-info br-32"
            @click="notAppearedCampus"
            data-dismiss="modal"
          >
            Not Appeared and Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'MoveToTalentPool'"
            class="btn btn-dark br-32"
            @click="moveTalentPoolCampus"
            data-dismiss="modal"
          >
            Move Talent Pool and Mail
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.to_be_mailed
 * @description To be mailed component
 */
export default {
  props: ["modalId", "applicantData", "roundData", "flag"],
  data() {
    return {
      isCoordinator: false,
      isStudent: false,
    };
  },
  computed: {
    ...mapGetters({
      campusMails: "$_applicants_data/fetchCampusMails",
    }),
  },
  watch: {
    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @param {Object} value
     * @description Fetch Co-ordinator emails as soon as applicant data is fetched
     */
    applicantData(value) {
      if (value.belongsToCampusRecruitement) {
        this.$store.dispatch(
          "$_applicants_data/fetchCampusEmails",
          value.belongsToCampusRecruitement.id
        );
      }
    },
  },
  methods: {
    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Send mail of applicant belonging to campus
     */
    sendCampusMail() {
      let key = this.getKeyForMails();
      this.$store.dispatch("$_applicant_profile/sendCampusRoundMail", {
        data: {
          applicantData: this.applicantData,
          roundData: this.roundData,
          toBeMailed: this.campusMails,
          type: key,
        },
        redirect: this.$route.fullPath,
      });
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description process applicant belonging to campus
     */
    processCampus() {
      this.applicantData.application_status = "Processed";
      this.updateApplicationStatus();
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Shortlist applicant belonging to campus
     */
    selectCampus() {
      this.applicantData.application_status = "Shortlisted";
      this.updateApplicationStatus();
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Differ applicant belonging to campus
     */
    rejectCampus() {
      this.applicantData.application_status = "Differed";
      this.updateApplicationStatus();
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Put on Hold applicant belonging to campus
     */
    holdCampus() {
      this.applicantData.application_status = "Held";
      this.updateApplicationStatus();
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Move to joined applicant belonging to campus
     */
    joinedCampus() {
      this.applicantData.application_status = "Joined";
      this.updateApplicationStatus();
    },
    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Put on not appeared applicant belonging to campus
     */
    notAppearedCampus() {
      this.applicantData.application_status = "Not Appeared";
      this.updateApplicationStatus();
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Move to talent pool applicant belonging to campus
     */
    moveTalentPoolCampus() {
      this.applicantData.application_status = "Talent Pool";
      this.updateApplicationStatus();
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Get key for mailbox
     */
    getKeyForMails() {
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      return key;
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description update status of application belonging to campus
     */
    updateApplicationStatus() {
      let key = this.getKeyForMails();
      this.$store.dispatch("$_applicant_profile/editCampusApplicationStatus", {
        data: {
          applicantData: this.applicantData,
          toBeMailed: this.campusMails,
          type: key,
        },
        redirect: this.$route.fullPath,
      });
    },
  },
};
</script>
