<template>
  <div>
    <common-calendar
      ref="calendar"
      :filterObj="filters"
      :api="apiCall"
      :calendarOption="calendarOptions"
      :classes="'fullcalendar-header-style-lg'"
      :onEventClick="handleEventClick"
      :setEventsandUsers="setEventsandUsers"
      :filters="{ setCustomButtons: true }"
    ></common-calendar>
  </div>
</template>

<script>
import api from "../_api/index";
import { mapGetters } from "vuex";
import moment from "moment";
import datePickerIcons from "../../Common/_utils/date-picker-icons";
import CommonCalendar from "../../Common/_components/common-calendar.vue";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  props: [
    "selectSessionEvents",
    "selectedEventModal",
    "onHidden",
    "setEditEvent",
    "showEditEvent",
    "setEventModalData",
  ],
  data() {
    return {
      ovserver: null,
      date: null,
      config: {
        format: "DD MMMM YYYY",
        inline: true,
        // format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        sideBySide: false,
        // // minDate: moment(),
        // minDate: new Date("Thu Nov 11 2022 16:59:00 GMT+0530 (India Standard Time)"),
        // // maxDate: true,
        icons: datePickerIcons,
      },
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right:
            "weekends,viewAll dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        contentHeight: 460,
      },
      pendingEvents: null,
      events: null,
    };
  },
  components: {
    CommonCalendar,
  },
  computed: {
    ...mapGetters({
      selectedCampus: "$_campus_recruitment/getSelectedCampus",
    }),
    filters() {
      return {
        campusId: this.selectedCampus?.id,
        isCampus: true,
        isValid: this.selectedCampus?.id ? true : false,
      };
    },
    apiCall() {
      return api.fetchRoundSchedules;
    },
  },
  watch: {
    selectedCampus() {
      this.filterEvents();
    },
  },
  methods: {
    clickToday() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      calendarApi.today();
    },

    setEventsandUsers({ campusEvennts }) {
      this.events = campusEvennts;
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description format date to display
     */
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description extract date from datetime
     */
    extractDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description handle click event and open edit event modal
     */
    handleEventClick(clickInfo) {
      this.setEditEvent(null);
      const foundEvent = this.events.find((event) => {
        return event.id == clickInfo.event.id;
      });
      this.setEditEvent(foundEvent);
      this.setEventModalData();
      this.showEditEvent();
    },

    filterEvents() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },
  },
};
</script>

<style scoped>
.schedule-app-main {
  flex-grow: 1;
  padding-bottom: 10px;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  text-transform: capitalize !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}

.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 16px;
  width: 16px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 16px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.span-circle {
  min-width: 16px;
  min-height: 16px;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-div {
  width: calc(100% - 30px);
}
</style>

<style>
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em !important;
}

.fc .fc-header-toolbar button {
  max-height: 40px;
  padding: 5px;
}

.fc .fc-header-toolbar .fc-toolbar-title {
  font-size: 20px;
}

.fc {
  font-size: 14px;
}

.fc .fc-daygrid-day-number {
  padding: 1px !important;
  font-size: 12px;
  line-height: 14px;
}

.fc .fc-daygrid-day-bottom a {
  font-size: 14px;
}

.fc .fc-daygrid tr td {
  height: 30px !important;
  max-height: 20px !important;
  min-height: 20px !important;
}

.left-side-div {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
</style>
