<template>
  <div>
    <!--job-banner-->
    <div class="job__banner">
      <div class="container">
        <div class="col-lg-6">
          <h1>Unleash your<span> Talent and Grow!</span></h1>
          <h3>join our Team</h3>
        </div>
      </div>
    </div>
    <!--job-banner-END-->
    <!--job_card_lg-->
    <div class="container" style="margin-top: -80px">
      <div class="row justify-content-center mx-0">
        <div class="job_card_lg">
          <img
            src="../../../assets/images/img_1.jpg"
            alt="Fresherimg"
            class="img-fluid"
          />
          <h2>Freshers</h2>
          <ul>
            <li>Training and exponential career growth.</li>
            <li>Flexibility &amp; work-life balance.</li>
            <li>Mentorship from industry experts.</li>
            <li>Compensation &amp; benefits as per industry standards</li>
          </ul>
          <div class="job_card_lg_footer" v-if="fresherOpeningEnabled">
            <router-link
              id="routerLink1"
              :to="{ name: 'applicationForm', query: { isFresher: 'true' } }"
            >
              Apply Now
              <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
            </router-link>
          </div>
          <div v-else>
            <h5 class="job_card_lg_footer noOpening">No openings for now!</h5>
          </div>
        </div>
        <div class="job_card_lg">
          <img
            src="../../../assets/images/img_2.jpg"
            alt="ProfessionalImg"
            class="img-fluid"
          />
          <h2>Experienced Professionals</h2>
          <ul>
            <li>Opportunities for global project experience.</li>
            <li>Hybrid structure.</li>
            <li>
              Office visits on occasion for team-bonding, interactions &amp;
              mentorship opportunities.
            </li>
            <li>Endless possibilities for career growth.</li>
          </ul>
          <div class="job_card_lg_footer" v-if="professionalOpeningEnabled">
            <router-link :to="{ name: 'jobOpenings' }" id="routerLink2">
              Openings <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
            </router-link>
          </div>
          <div v-else>
            <h5 class="job_card_lg_footer noOpening">No openings for now!</h5>
          </div>
        </div>
      </div>
    </div>

    <!--job_card_lg-END-->
    <!--Our Benefits-->
    <div class="container job_top_spacer_100">
      <div class="text-center">
        <h1>Our Benefits</h1>
        <p class="text-size-22">
          Our workforce has grown because we make taking care of our employees a
          priority
        </p>
      </div>
      <div class="row mx-0">
        <div class="col-md-4 col-12">
          <div class="benefits_content">
            <img src="../../../assets/images/sharpe_icon.png" alt="icons" />
            <h3>Shape Your Career</h3>
            <p>
              Bring your ideas and pursue innovative career tracks and growth
              opportunities.
            </p>
          </div>
        </div>

        <div class="col-md-4 col-12">
          <div class="benefits_content">
            <img src="../../../assets/images/learn_icon.png" alt="icons" />
            <h3>Learn And Grow</h3>
            <p>
              Enhance your professional development through education and
              training.
            </p>
          </div>
        </div>

        <div class="col-md-4 col-12">
          <div class="benefits_content">
            <img
              src="../../../assets/images/Keep_current_icon.png"
              alt="icons"
            />
            <h3>Keep Current</h3>
            <p>
              Our skills training helps you keep pace with the changing
              workplace.
            </p>
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div class="col-md-4 col-12">
          <div class="benefits_content">
            <img src="../../../assets/images/give_back_icon.png" alt="icons" />
            <h3>Learn From The Best</h3>
            <p>
              Get hands-on training from industry experts and opportunities to
              gain global project experience.
            </p>
          </div>
        </div>

        <div class="col-md-4 col-12">
          <div class="benefits_content">
            <img src="../../../assets/images/healthy_icon.png" alt="icons" />
            <h3>Stay Healthy</h3>
            <p>
              Be well with health plans that help you support your loved ones.
            </p>
          </div>
        </div>

        <div class="col-md-4 col-12">
          <div class="benefits_content">
            <img src="../../../assets/images/invest_icon.png" alt="icons" />
            <h3>Invest In Yourself</h3>
            <p>
              Plan for the future with our compensation and benefits packages,
              including holidays.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--Our Benefits-END-->

    <!--video-->
    <div class="container job_top_spacer_100">
      <div class="video_play">
        <video controls autoplay loop muted>
          <source
            src="../../../assets/videos/argusoft-intro-new.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
    <!--video-END-->

    <!--argonaut-->
    <div class="container job_top_spacer_100">
      <div class="argonaut pt-5">
        <div class="row mx-0">
          <div class="col-lg-6 col-12">
            <div class="text-center mb-5">
              <h1>working <br />@</h1>
              <img
                src="../../../assets/images/color_logo.png"
                class="large_logo"
                alt="Argusoft Logo"
              />
            </div>
            <div
              class="d-flex flex-wrap justify-content-lg-end justify-content-center"
            >
              <div class="argonaut_img_box">
                <img
                  src="../../../assets/images/argonaut_img01.png"
                  alt="Argusoft Employee 1"
                />
              </div>
              <div class="argonaut_img_box">
                <img
                  src="../../../assets/images/argonaut_img02.png"
                  alt="Argusoft Employee 2"
                />
              </div>
              <div class="argonaut_img_box">
                <img
                  src="../../../assets/images/argonaut_img03.png"
                  alt="Argusoft Employee 3"
                />
              </div>
              <div class="argonaut_img_box">
                <img
                  src="../../../assets/images/argonaut_img04.png"
                  alt="Argusoft Employee 4"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div
              class="d-flex flex-wrap flex-wrap justify-content-lg-start justify-content-center"
            >
              <div class="argonaut_img_box">
                <img
                  src="../../../assets/images/argonaut_img05.png"
                  alt="Argusoft Employee 5"
                />
              </div>
              <div class="argonaut_img_box">
                <img
                  src="../../../assets/images/argonaut_img06.png"
                  alt="Argusoft Employee 6"
                />
              </div>
              <div class="argonaut_img_box">
                <img
                  src="../../../assets/images/argonaut_img07.png"
                  alt="Argusoft Employee 7"
                />
              </div>
              <div class="argonaut_img_box">
                <img
                  src="../../../assets/images/argonaut_img08.png"
                  alt="Argusoft Employee 8"
                />
              </div>
            </div>
            <div>
              <p>
                Life at Argusoft is a healthy mix of work and play, drawing its
                influences from an 'American style entrepreneurial' work
                culture. As an Argonaut, you get access to opportunities for
                career development and mentorship from industry experts. We
                promise a challenging work environment that will draw out the
                best in you and a friendly atmosphere for holistic development.
              </p>
              <p>
                At Argusoft, we recognize that employee happiness is key to
                keeping our clients happy. Interested? Explore our current job
                openings to see how you can become an Argonaut!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--argonaut-END-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

/**
 * @memberof module:Home
 * @namespace components.home
 */
export default {
  components: {
    // 'display-review': displayreview
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({
      fresherOpeningEnabled: "system_config/jobOpeningFresher",
      professionalOpeningEnabled: "system_config/jobOpeningProfessional",
    }),
  },
};
</script>
<style scoped>
body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #62555b;
}

/*job__banner-css*/
.job__banner {
  background-image: url(../../../assets/images/home_banner.jpg);
  height: 500px;
  margin-top: 70px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.job__banner h1 {
  font-size: 60px;
  font-weight: 400;
}
.job__banner h1 span {
  color: #903564;
  display: block;
  font-weight: 800;
}
@media screen and (max-width: 991px) {
  .job__banner h1 {
    font-size: 32px;
  }
}
.job__banner h3 {
  font-size: 40px;
  text-transform: uppercase;
}
@media screen and (max-width: 991px) {
  .job__banner h3 {
    font-size: 24px;
  }
}

/*job__banner-css-END*/
/*job_breadcrumb-css*/
.job_breadcrumb li a {
  color: #62555b;
}
.job_breadcrumb .active {
  color: #903564 !important;
}

/*job_breadcrumb-css*/
/*form_wrapper-css*/
.form_wrapper {
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 40px;
}
.form_wrapper .btn {
  height: 38px;
}
@media screen and (max-width: 991px) {
  .form_wrapper .btn {
    padding: 8px 12px;
  }
}
.form_wrapper a {
  color: #2e3941;
  transition: all 0.2s;
  text-decoration: underline;
  padding-left: 20px;
}
@media screen and (max-width: 991px) {
  .form_wrapper a {
    padding-left: 10px;
  }
}
.form_wrapper a:hover {
  color: #8f1452;
}
.form_wrapper input:focus {
  box-shadow: none;
  border-color: #8f1452;
}
.form_wrapper select:focus {
  box-shadow: none;
  border-color: #8f1452;
}
.job_card_wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.job_card {
  margin: 10px;
  margin-bottom: 30px;
  width: 350px;
  min-height: 440px;
  position: relative;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;
  font-size: 15px;
  line-height: 22px;
}
.job_card .job_card_header {
  margin: 30px 0px;
  text-align: center;
}
.job_card .job_card_header h2 {
  font-size: 22px;
  font-weight: 700;
  margin: 15px 0px;
  color: #2e3941;
  line-height: 24px;
}
.job_card span {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  color: #75828a;
  margin-bottom: 5px;
  font-size: 14px;
}
.job_card .job_card_footer {
  border-top: 1px solid #cccccc;
  padding-top: 30px;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 30px;
  width: calc(100% - 40px);
  text-align: right;
}
.job_card .job_card_footer a {
  color: #903564;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.job_card .job_card_footer a i {
  padding-left: 5px;
}
.job_card .job_card_footer a:hover {
  color: #903564;
}

/*job_card-css*/
/*Job_page_2-css*/
.job_card_lg {
  background: #fff;
  position: relative;
  width: 400px;
  margin: 15px;
  min-height: 580px;
  padding: 20px;
  box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 20%);
}
@media screen and (max-width: 991px) {
  .job_card_lg {
    margin: 15px 0px;
  }
}
@media screen and (max-width: 500px) {
  .job_card_lg {
    width: 300px;
    min-height: 450px;
  }
}
.job_card_lg h2 {
  font-size: 32px;
  margin: 15px 0px;
  font-weight: 700;
  position: relative;
}
@media screen and (max-width: 991px) {
  .job_card_lg h2 {
    font-size: 24px;
  }
}
.job_card_lg h2:after {
  content: "";
  width: 90px;
  height: 5px;
  position: absolute;
  bottom: -8px;
  left: 0px;
  background: #8f1452;
}
.job_card_lg ul {
  margin-top: 25px;
  padding-left: 15px;
}
.job_card_lg ul li {
  line-height: 20px;
  margin-bottom: 10px;
}
.job_card_lg .job_card_lg_footer {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
@media (max-width: 500px) {
  .job_card_lg .job_card_lg_footer {
    bottom: 5px;
  }
}
.job_card_lg .job_card_lg_footer a {
  color: #903564;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.job_card_lg .job_card_lg_footer a i {
  padding-left: 5px;
}
.job_card_lg .job_card_lg_footer a:hover {
  color: #903564;
}

.benefits_content {
  margin-top: 40px;
  text-align: center;
}
.benefits_content h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 12px 0px;
}
.benefits_content p {
  font-size: 16px;
  padding: 0px 40px;
}
.noOpening {
  color: #8f1452;
  font-weight: 600;
}
.video_play {
  padding: 20px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
}
.video_play video {
  width: 100%;
}

.job_top_spacer_100 {
  margin-top: 50px;
}
@media screen and (max-width: 991px) {
  .job_top_spacer_100 {
    margin-top: 35px;
  }
}

.argonaut h1 {
  font-size: 80px;
  font-weight: 100;
  text-transform: uppercase;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 991px) {
  .argonaut h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
.argonaut .argonaut_img_box {
  width: 260px;
  margin-top: 2px;
  margin-right: 2px;
  height: 260px;
  overflow: hidden;
}
.argonaut .argonaut_img_box img {
  height: 100%;
  object-fit: contain;
}
.argonaut p {
  font-size: 18px;
  margin-top: 40px;
  color: #62555b;
}
@media screen and (max-width: 991px) {
  .argonaut .large_logo {
    width: 270px;
  }
}

h1 {
  font-size: 48px;
  color: #2e3941;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  h1 {
    font-size: 32px;
  }
}
.text-size-22 {
  font-size: 22px;
}
@media screen and (max-width: 991px) {
  .text-size-22 {
    font-size: 18px;
  }
}
</style>
