<template>
  <home></home>
</template>

<script>
import Home from "./_components/home.vue";

export default {
  components: {
    Home,
  },
};
</script>
