<template>
  <div>
    <common-calendar
      ref="calendar"
      :filterObj="filters"
      :api="apiCall"
      :setEventsandUsers="setEventsandUsers"
      :calendarOption="calendarOptions"
      :classes="'fullcalendar-header-style-lg'"
      :filters="{ setCustomButtons: true, setTableButton: true }"
    ></common-calendar>
  </div>
</template>

<script>
import api from "../_api/index";
import { mapGetters } from "vuex";
import CommonCalendar from "../../Common/_components/common-calendar.vue";
import datePickerIcons from "../../Common/_utils/date-picker-icons";
/**
 * @memberOf module:Recruiter
 * @namespace components.campus-event-modal
 * @description events for recruiter
 */
export default {
  data() {
    return {
      ovserver: null,
      date: null,
      config: {
        format: "DD MMMM YYYY",
        inline: true,
        // format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        sideBySide: false,
        // // minDate: moment(),
        // minDate: new Date("Thu Nov 11 2022 16:59:00 GMT+0530 (India Standard Time)"),
        // // maxDate: true,
        icons: datePickerIcons,
      },
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right:
            "weekends,viewAll dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        contentHeight: 460,
      },
      events: null,
    };
  },
  components: {
    CommonCalendar,
  },
  computed: {
    ...mapGetters({
      selectedSession: "$_recruiter/getSelectedSession",
      selectedCollege: "$_recruiter/getSelectedCollege",
      sessionList: "$_recruiter/getSessionList",
      entityListDetails: "$_recruiter/getEntityListDetails",
    }),
    filters() {
      return {
        isRecruiter: true,
        campusId: this.selectedSession.id,
        isValid: this.selectedSession.id ? true : false,
      };
    },
    apiCall() {
      return api.getCampusEvents;
    },
  },

  watch: {
    selectedSession(selectedSession) {
      if (selectedSession) {
        this.refetchEvents();
      }
    },
  },

  methods: {
    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description refresh calendar events
     */
    refetchEvents() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },

    clickToday() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      calendarApi.today();
    },

    setEventsandUsers({ campusEvents }) {
      this.events = campusEvents;
    },
    filterEvents() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },
  },
};
</script>

<style scoped>
.schedule-app-main {
  flex-grow: 1;
  padding-bottom: 10px;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  margin: 0 auto;
  text-transform: capitalize !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}

.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 16px;
  width: 16px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 16px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.span-circle {
  min-width: 16px;
  min-height: 16px;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-div {
  width: calc(100% - 30px);
}
</style>
<style>
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em !important;
}

.fc .fc-header-toolbar button {
  max-height: 40px;
  padding: 5px;
}

.fc .fc-header-toolbar .fc-toolbar-title {
  font-size: 20px;
}

.fc {
  font-size: 14px;
}

.fc .fc-daygrid-day-number {
  padding: 1px !important;
  font-size: 12px;
  line-height: 14px;
}

.fc .fc-daygrid-day-bottom a {
  font-size: 14px;
}

.fc .fc-daygrid tr td {
  height: 30px !important;
  max-height: 20px !important;
  min-height: 20px !important;
}
.left-side-div {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
</style>
