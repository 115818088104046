<template>
  <div id="add-event-modal-body">
    <!-- {{ editEventApplicant }} -->
    <div v-if="!isEdit" style="position: relative; margin-bottom: 10px">
      <p>Search a candidate to schedule event for</p>
      <applicant-search-bar
        v-on:candidateSelected="getCandidate($event)"
        :apiPromise="searchApiPromise"
        :routerLink="null"
        :isEmit="true"
      />
    </div>
    <div v-if="applicantInfoData">
      <h4 class="text-center">
        Profile - {{ applicantInfoData.first_name }}
        {{ applicantInfoData.last_name }} &nbsp;<router-link
          target="_blank"
          :to="{
            name: 'applicantprofile',
            params: { slug: applicantInfoData.slug },
          }"
          ><i
            title="View Profile"
            class="fa fa-user-circle-o"
            aria-hidden="true"
            style="color: #832e5b"
          ></i
        ></router-link>
        &nbsp;<router-link
          v-if="
            hasAccess({
              permissions: [PERMISSIONS.APPLICANT.UPDATE],
            })
          "
          target="_blank"
          :to="{
            name: 'editApplicantData',
            params: { slug: applicantInfoData.slug },
          }"
          ><i
            title="Edit Applicant Profile"
            class="fa fa-edit"
            aria-hidden="true"
            style="color: #832e5b"
          ></i
        ></router-link>
      </h4>
      <hr />
      <b-tabs content-class="mt-3" fill pills>
        <b-tab title="Personal Details" active>
          <div class="well container">
            <table
              class="table table-condensed"
              aria-label="Personal Details"
              aria-hidden="true"
            >
              <tbody v-if="applicantInfoData">
                <tr>
                  <td class="col-md-4">
                    <strong>Name</strong>
                  </td>
                  <td>
                    {{ applicantInfoData.first_name }}
                    {{ applicantInfoData.last_name }}
                  </td>
                </tr>
                <tr>
                  <td class="col-md-4">
                    <strong>Email</strong>
                  </td>
                  <td>{{ applicantInfoData.email }}</td>
                </tr>
                <tr>
                  <td class="col-md-4">
                    <strong>Mobile No.</strong>
                  </td>
                  <td>{{ applicantInfoData.mobile_no }}</td>
                </tr>
                <tr v-if="applicantInfoData.alternate_mobile_no">
                  <td class="col-md-4">
                    <strong>Alt. Mobile No.</strong>
                  </td>
                  <td>{{ applicantInfoData.alternate_mobile_no }}</td>
                </tr>
                <tr>
                  <td class="col-md-4">
                    <strong>Date of Birth</strong>
                  </td>
                  <td>{{ applicantInfoData.dob | dateFormat }}</td>
                </tr>
                <tr>
                  <td class="col-md-4">
                    <strong>Gender</strong>
                  </td>
                  <td>{{ applicantInfoData.gender }}</td>
                </tr>
                <tr v-if="applicantInfoData.address_line_1">
                  <td class="col-md-4">
                    <strong>Permanent Address</strong>
                  </td>
                  <td>
                    {{ applicantInfoData.address_line_1 }},
                    {{ applicantInfoData.address_line_2 }},
                    {{ applicantInfoData.city }},
                    {{ applicantInfoData.state }}
                  </td>
                </tr>
                <tr v-if="applicantInfoData.current_address_line_1">
                  <td class="col-md-4">
                    <strong>Current Address</strong>
                  </td>
                  <td>
                    {{ applicantInfoData.current_address_line_1 }},
                    {{ applicantInfoData.current_address_line_2 }},
                    {{ applicantInfoData.current_city }},
                    {{ applicantInfoData.current_state }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Professional Details">
          <div class="well container">
            <table
              class="table table-condensed"
              aria-label="Professional Details"
            >
              <thead>
                <th colspan="10">
                  <h4>
                    <p style="float: right">
                      &nbsp;&nbsp;
                      <safe-link
                        v-if="applicantInfoData.resume !== 'none'"
                        :link="resumeLink"
                      >
                        <em title="Resume" class="fa fa-book"></em>&nbsp;Resume
                      </safe-link>
                    </p>
                  </h4>
                </th>
              </thead>
              <tbody v-if="applicantInfoData">
                <tr v-if="applicantInfoData.employment_status">
                  <td class="col-md-4">
                    <strong>Employment Status </strong>
                  </td>
                  <td>{{ applicantInfoData.employment_status }}</td>
                </tr>
                <tr v-if="applicantInfoData.experience">
                  <td class="col-md-4">
                    <strong>Experience</strong>
                  </td>
                  <td>{{ applicantInfoData.experience }} years</td>
                </tr>
                <tr v-if="applicantInfoData.relevant_experience">
                  <td class="col-md-4">
                    <strong>Relevant Experience</strong>
                  </td>
                  <td>{{ applicantInfoData.relevant_experience }} years</td>
                </tr>
                <tr v-if="applicantInfoData.skills">
                  <td class="col-md-4">
                    <strong>Skills</strong>
                  </td>
                  <td>{{ applicantInfoData.skills.join(", ") }}</td>
                </tr>
                <tr v-if="applicantInfoData.current_ctc">
                  <td class="col-md-4">
                    <strong>Current CTC</strong>
                  </td>
                  <td>{{ applicantInfoData.current_ctc }} LPA</td>
                </tr>
                <tr v-if="applicantInfoData.previous_ctc">
                  <td class="col-md-4">
                    <strong>Previous CTC</strong>
                  </td>
                  <td>{{ applicantInfoData.previous_ctc }} LPA</td>
                </tr>
                <tr v-if="applicantInfoData.expected_ctc">
                  <td class="col-md-4">
                    <strong>Expected CTC</strong>
                  </td>
                  <td>{{ applicantInfoData.expected_ctc }} LPA</td>
                </tr>
                <tr v-if="applicantInfoData.current_role">
                  <td class="col-md-4">
                    <strong>Current Role</strong>
                  </td>
                  <td>{{ applicantInfoData.current_role }}</td>
                </tr>
                <tr v-if="applicantInfoData.previous_role">
                  <td class="col-md-4">
                    <strong>Previous Role</strong>
                  </td>
                  <td>{{ applicantInfoData.previous_role }}</td>
                </tr>
                <tr v-if="applicantInfoData.notice_period">
                  <td class="col-md-4">
                    <strong>Notice Period</strong>
                  </td>
                  <td>{{ applicantInfoData.notice_period }} days</td>
                </tr>
                <tr v-if="applicantInfoData.reason_for_leaving">
                  <td class="col-md-4">
                    <strong>Reasons to look for New Opportunity</strong>
                  </td>
                  <td>{{ applicantInfoData.reason_for_leaving }}</td>
                </tr>
                <template v-if="applicantInfoData.project_links">
                  <tr v-if="applicantInfoData.project_links.length">
                    <td class="col-md-4">
                      <strong>Project links</strong>
                    </td>
                    <td>{{ applicantInfoData.project_links.join(", ") }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            <template v-if="applicantInfoData">
              <template v-if="applicantInfoData.references">
                <template
                  v-if="
                    applicantInfoData.references.ref1.name ||
                    applicantInfoData.references.ref2.name
                  "
                >
                  <h5>
                    <strong>References</strong>
                  </h5>
                  <table class="table table-condensed" aria-label="References">
                    <thead>
                      <th id="refName">Name</th>
                      <th id="refDesignation">Designation</th>
                      <th id="refContactNo">Contact No</th>
                    </thead>
                    <tbody>
                      <tr v-if="applicantInfoData.references.ref1.name">
                        <td>{{ applicantInfoData.references.ref1.name }}</td>
                        <td>
                          {{ applicantInfoData.references.ref1.designation }}
                        </td>
                        <td>
                          {{ applicantInfoData.references.ref1.contact_no }}
                        </td>
                      </tr>
                      <tr v-if="applicantInfoData.references.ref2.name">
                        <td>{{ applicantInfoData.references.ref2.name }}</td>
                        <td>
                          {{ applicantInfoData.references.ref2.designation }}
                        </td>
                        <td>
                          {{ applicantInfoData.references.ref2.contact_no }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </template>
            </template>
          </div>
        </b-tab>
        <b-tab title="Application Details">
          <div class="well container section-head mx-0">
            <div class="row mx-0">
              <div class="col-md-5 px-0">
                Applied {{ applicationReceivedAgoFromNow }} ({{
                  applicantInfoData.createdAt | dateFormat
                }})
              </div>
              <div class="col-md-7 text-right px-0">
                <span>
                  {{ applicantInfoData.application_status }}
                </span>
              </div>
            </div>
          </div>
          <router-link
            :to="{
              name: 'resolveduplicate',
              params: { id: applicantInfoData.id },
            }"
          >
            <div class="text-center">
              <span
                v-if="
                  applicantInfoData &&
                  applicantInfoData.hasDuplicate &&
                  applicantInfoData.hasDuplicate.length > 0
                "
                class="alert alert-danger"
                style="padding-top: 7px; padding-bottom: 7px"
              >
                <em class="fa fa-link"></em>Please resolve duplicates for this
                profile first</span
              >
            </div>
          </router-link>
          <div class="well container">
            <table class="table table-condensed" aria-label="Applicant Details">
              <thead>
                <th colspan="10">
                  <h4>
                    <template
                      v-if="applicantInfoData && applicantInfoData.hasDuplicate"
                    >
                      <div class="float-right">
                        <safe-link
                          v-if="applicantInfoData.linkedWithRecord"
                          :link="previousRecord"
                        >
                          <em
                            class="fa fa-chevron-circle-left"
                            aria-hidden="true"
                          ></em>
                          &nbsp;Previous Record
                        </safe-link>
                      </div>
                    </template>
                  </h4>
                </th>
              </thead>
              <tbody v-if="applicantInfoData">
                <tr>
                  <td class="col-md-2">
                    <strong>Last Updated On</strong>
                  </td>
                  <td class="col-md-4">
                    {{ applicantInfoData.updatedAt | dateTimeFormat }}
                  </td>
                </tr>
                <tr>
                  <td class="col-md-2">
                    <strong>Created On</strong>
                  </td>
                  <td class="col-md-4">
                    {{ applicantInfoData.createdAt | dateTimeFormat }}
                  </td>
                </tr>
                <tr>
                  <td class="col-md-2">
                    <strong>Applied for Position</strong>
                  </td>
                  <td class="col-md-4">{{ applicantInfoData.position }}</td>
                </tr>
                <tr>
                  <td class="col-md-2">
                    <strong>Application Status</strong>
                  </td>
                  <td class="col-md-4">
                    {{ applicantInfoData.application_status }}
                  </td>
                </tr>
                <tr>
                  <td class="col-md-2">
                    <strong>Application Comments</strong>
                  </td>
                  <td class="col-md-4">
                    <div id="application_comments">
                      <safe-content
                        v-if="applicantInfoData.application_comments"
                        :content="applicantInfoData.application_comments"
                        tag="p"
                      ></safe-content>
                      <p v-else>N/A</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Email Details">
          <div class="well container" v-if="mails.length > 0">
            <div
              class="accordion"
              :class="[
                mail.type === 'received' ? 'received-mail' : 'sent-mail',
              ]"
              role="tablist"
              v-for="(mail, index) in mails"
              :key="index"
            >
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div
                    block
                    v-b-toggle="'accordion-' + index"
                    :class="[
                      mail.type === 'received'
                        ? 'received-title'
                        : 'sent-title',
                    ]"
                  >
                    <div class="row p-1">
                      <div class="col-8" v-if="!isUnread(mail.labels)">
                        <i
                          class="fa fa-paperclip"
                          v-if="mail.attachments.length > 0"
                        ></i>
                        &nbsp; {{ mail.subject }}
                      </div>
                      <div class="col-8" v-if="isUnread(mail.labels)">
                        <strong
                          ><i
                            class="fa fa-paperclip"
                            v-if="mail.attachments.length > 0"
                          ></i>
                          &nbsp; {{ mail.subject }}</strong
                        >
                      </div>
                      <div class="col-4 text-right text-secondary">
                        {{ formatDate(mail.date) }}
                      </div>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse
                  :id="'accordion-' + index"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <div>
                        <p v-if="mail.to" class="mb-2">
                          To : &nbsp;&nbsp;&nbsp;&nbsp;{{ mail.to }}
                        </p>
                        <p v-if="mail.from" class="mb-2">
                          From : &nbsp;&nbsp;&nbsp;&nbsp;{{ mail.from }}
                        </p>
                        <p v-if="mail.cc" class="mb-2">
                          Cc : &nbsp;&nbsp;&nbsp;&nbsp;{{ mail.cc }}
                        </p>
                        <p v-if="mail.bcc" class="mb-2">
                          Bcc : &nbsp;&nbsp;&nbsp;&nbsp;{{ mail.bcc }}
                        </p>
                      </div>
                      <mail-component :mailData="mail.body"></mail-component>
                      <div class="mt-4" v-if="mail.attachments.length > 0">
                        <div
                          class="mt-1"
                          v-for="attachment in mail.attachments"
                          :key="attachment.attachmentId"
                        >
                          <i class="fa fa-paperclip"></i> &nbsp;
                          <span
                            class="attachment"
                            @click="
                              getAttachment(
                                mail.messageId,
                                attachment.id,
                                attachment.mimeType
                              )
                            "
                            >{{ attachment.fileName }}</span
                          >
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div v-else class="text-center well container">No Emails To show</div>
        </b-tab>
      </b-tabs>
      <hr />
      <div v-if="!isEdit">
        <div v-if="applicantRoundsData">
          <div
            v-if="
              !(
                applicantRoundsData.length === 0 ||
                (applicantRoundsData[applicantRoundsData.length - 1]
                  .round_result !== 'Pending' &&
                  applicantRoundsData.length > 0)
              ) ||
              (applicantDataForConflict.hasDuplicate &&
                applicantDataForConflict.hasDuplicate.length > 0)
            "
          >
            <h6 class="text-danger">
              * An active event is already exist for this Applicant. You can not
              schedule multiple events.
            </h6>
          </div>
          <div v-else>
            <div v-if="title === 'FollowUp'">
              <button class="btn btn-cp float-right" @click="generateFollowUp">
                <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
                <strong>Generate Follow Up</strong>
              </button>
            </div>
            <div v-else>
              <button
                v-if="
                  hasAccess({
                    permissions: [PERMISSIONS.APPLICANT.ROUND.CREATE],
                  })
                "
                class="btn btn-cp float-right"
                @click="generateRound"
              >
                <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
                <strong>Generate Round</strong>
              </button>
            </div>
          </div>
        </div>
        <div v-else>...loading applicant's round schedule</div>
      </div>
      <div v-else class="float-right">
        <template
          v-if="
            hasAccess({ permissions: [PERMISSIONS.APPLICANT.ROUND.UPDATE] })
          "
        >
          <button class="btn btn-dark" @click="updateEvent">
            <i class="fa fa-ban fa-lg" aria-hidden="true"></i>
            <strong>Cancel Round</strong>
          </button>
          <button class="btn btn-cp ml-2" @click="updateEvent">
            <i class="fa fa-calendar fa-lg" aria-hidden="true"></i>
            <strong>Reschedule Round</strong>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ApplicantSearchBar from "../../Common/_components/applicant-search-bar.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import config from "../../../config";
import MailComponent from "../../ApplicantProfile/_components/mail-component.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  components: {
    ApplicantSearchBar,
    MailComponent,
  },
  props: {
    isEdit: Boolean,
    editEventApplicant: Object,
    title: String,
  },
  data() {
    return {
      config,
      applicantInfoData: null,
      applicationReceivedAgoFromNow: null,
      mails: [],
      attachmentData: null,
      mimeType: "",
      attachmentId: "",
      resumeLink: "",
      previousRecord: null,
    };
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    fetchEmails(value) {
      if (!this.hasAccess({ permissions: [PERMISSIONS.MAIL.MENU] })) return;
      this.$store.dispatch("$_applicants_data/receiveUserMails", value.email);
    },
    getCandidate(applicant) {
      this.applicantInfoData = applicant;
      this.$store.dispatch(
        "$_applicant_profile/fetchRoundDetails",
        applicant.id
      );
      let applicationDate = new Date(applicant.createdAt);
      this.applicationReceivedAgoFromNow = moment([
        applicationDate.getFullYear(),
        applicationDate.getMonth(),
        applicationDate.getDate(),
        applicationDate.getHours(),
        applicationDate.getMinutes(),
        applicationDate.getSeconds(),
      ]).fromNow();
    },
    generateRound() {
      this.$store.dispatch("$_applicants_data/storeSelectedApplicants", [
        this.applicantInfoData,
      ]);
      this.$emit("generateRound", this.applicantInfoData);
    },
    generateFollowUp() {
      this.$store.dispatch("$_applicants_data/storeSelectedApplicants", [
        this.applicantInfoData,
      ]);
      this.$emit("generateFollowUp", this.applicantInfoData);
    },
    updateEvent() {
      this.$emit("updateEvent", this.applicantInfoData);
      this.$store.dispatch(
        "$_applicant_profile/setModalRoundData",
        this.applicantRoundsData[this.applicantRoundsData.length - 1]
      );
    },
    formatDate(date) {
      return moment(date).format("llll");
    },

    formatMailBody(msg) {
      if (msg.includes("wrote:")) {
        const subStr = msg.substring(0, msg.indexOf("wrote"));
        const msgStr = subStr.substring(0, subStr.indexOf("On"));
        return msgStr.trim();
      }
      return msg;
    },

    isUnread(labels) {
      return labels.includes("UNREAD");
    },

    navigateToMailbox() {
      const finalData = {
        data: [{ email: this.applicantInfoData.email }],
      };
      setTimeout(() => {
        this.$store.dispatch("$_mailbox/setApplicantsData", finalData, {
          root: true,
        });
      }, 300);
      this.$router.push({
        name: "mailbox",
        query: { key: "MailTo", redirect: this.$route.fullPath },
      });
    },

    getAttachment(msgId, id, mimeType) {
      if (this.attachmentId === id) {
        this.openAttachment();
      } else {
        this.attachmentId = id;
        this.mimeType = mimeType;
        this.$store.dispatch("$_applicants_data/fetchAttachment", {
          msgId: msgId,
          id: id,
        });
      }
    },

    openAttachment() {
      const data = this.decode(this.attachmentData);
      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: this.mimeType + ";base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },

    decode(input) {
      input = input.replace(/-/g, "+").replace(/_/g, "/");
      let pad = input.length % 4;
      if (pad) {
        if (pad === 1) {
          throw new Error(
            "InvalidLengthError: Input base64url string is the wrong length to determine padding"
          );
        }
        input += new Array(5 - pad).join("=");
      }

      return input;
    },
    searchApiPromise(searchQuery) {
      return axios.post("applicants/search?searchQuery=" + searchQuery);
    },
  },

  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      applicantRoundsData: "$_applicant_profile/fetchRoundDetails",
      applicantDataForConflict: "$_applicant_profile/fetchApplicantData",
      id: "$_applicant_profile/fetchID",
      userMails: "$_applicants_data/fetchUserMailData",
      attachment: "$_applicants_data/fetchMailAttachment",
    }),
  },
  watch: {
    editEventApplicant(value) {
      this.applicantInfoData = value;
    },
    applicantInfoData(value) {
      this.resumeLink =
        this.config.ROOT_API + "/applicants/resume/" + value.slug;

      this.previousRecord = value.linkedWithRecord
        ? `./${value.linkedWithRecord.slug}`
        : null;
      this.$store.dispatch("$_applicant_profile/fetchRoundDetails", value.id);
      this.$store.dispatch(
        "$_applicant_profile/fetchApplicantData",
        value.slug
      );
      this.fetchEmails(value);
    },
    userMails(value) {
      if (value) {
        if (value.error) {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Refresh Google token!",
            },
            {
              root: true,
            }
          );
          this.mails = [];
        } else {
          this.mails = [...value.receivedMails, ...value.sentMails];
          this.mails.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
        }
      }
    },
    attachment(value) {
      this.attachmentData = value;
      this.openAttachment();
    },
  },
};
</script>

<style scoped>
#add-event-modal-body {
  min-height: 400px;
}

.container {
  padding: 10px;
  margin-bottom: 15px;
  display: table;
}

.received-title {
  background: rgba(108, 117, 125, 0.1);
  border-color: rgba(108, 117, 125, 0.1);
  color: black;
}

.sent-title {
  background: rgba(0, 123, 255, 0.3);
  border-color: rgba(0, 123, 255, 0.3);
  color: black;
}

.sent-mail {
  width: 100%;
  float: left;
}

.received-mail {
  width: 90%;
  float: right;
}

.msg {
  word-break: break-all;
  white-space: pre-wrap;
}

.attachment {
  cursor: pointer;
}

.attachment:hover {
  color: blue;
  text-decoration: underline;
}
@media (max-width: 768px) {
  .container {
    padding: 5px;
    margin-bottom: 10px;
  }
  .float-right {
    float: none !important;
    margin-top: 10px;
    text-align: center;
  }
  .btn {
    margin-bottom: 5px;
  }
}
</style>
