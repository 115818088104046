<template>
  <div id="app" class="app-style">
    <router-view />
    <snack-bar></snack-bar>
    <loading-overlay></loading-overlay>
    <search-modal v-if="loggedInUser"></search-modal>
  </div>
</template>

<script>
import store from "./modules/Home/_store";
import SnackBar from "./modules/Common/_components/snackbar";
import { mapGetters } from "vuex";
import LoadingOverlay from "./modules/Common/_components/loading-overlay";
import searchModal from "./modules/Common/_components/search-modal.vue";
export default {
  name: "App",
  components: {
    SnackBar,
    LoadingOverlay,
    searchModal,
  },
  computed: {
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
    }),
  },
  created() {
    const STORE_KEY = "$_home";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto");

.app-style {
  height: 100%;
}
.main-app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
