<template>
  <div
    class="d-flex p-0 m-0 custom-input-style-parent custom-input-style-parent-div"
  >
    <form
      v-if="params.data && eventData && interviewData && valuesAssigned"
      class="custom-input-style-parent"
      @submit.prevent="onSubmit"
    >
      <component
        class="custom-input-style-parent"
        :ref="eventData.bodyData.key"
        :is="inputType"
        v-bind="eventData.bodyData"
        v-model="model[eventData.bodyData.key]"
        :data="params.data"
        :disabled="!isEditable"
        :showLabels="false"
        :showErrors="false"
        :inputAttrs="inputAttrs"
      ></component>
    </form>
    <div v-else class="blankk"></div>
  </div>
</template>

<script>
import { hasAccess } from "../../User/_utils/actions";
import LineInputRenderer from "../../FormTemplate/_components/renderers/LineInputRenderer.vue";
import ParagraphInputRenderer from "../../FormTemplate/_components/renderers/ParagraphInputRenderer.vue";
import RatingInputRenderer from "../../FormTemplate/_components/renderers/RatingInputRenderer.vue";
import DropDownInputRenderer from "../../FormTemplate/_components/renderers/DropDownInputRenderer.vue";
import CheckboxInputRenderer from "../../FormTemplate/_components/renderers/CheckboxInputRenderer.vue";
import RadioInputRenderer from "../../FormTemplate/_components/renderers/RadioInputRenderer.vue";
import { debounce } from "lodash";
import api from "../_api/index";
import PERMISSIONS from "../../Common/permissions";
import { mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import {
  getInterviewData,
  getNoFormInterviewData,
  getRoundData,
  getRoundInterviewData,
  parseJsonData,
} from "../_utils/my-campus-helper";

export default {
  components: {
    "line-renderer": LineInputRenderer,
    "paragraph-renderer": ParagraphInputRenderer,
    "rating-renderer": RatingInputRenderer,
    "dropdown-renderer": DropDownInputRenderer,
    "checkbox-renderer": CheckboxInputRenderer,
    "radio-renderer": RadioInputRenderer,
  },
  data() {
    return {
      model: {},
      eventData: {},
      valuesAssigned: false,
      isEditable: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
      systemConfigs: "system_config/systemConfigs",
    }),
    isDataValid() {
      if (this.eventData.noForm || this.eventData.isCustom) {
        return true;
      }
      let remarks = this.interviewData.interviewer_remarks;
      let remark = parseJsonData(remarks);
      return typeof remark !== "string";
    },
    inputType() {
      if (this.isDataValid) {
        let type = this.eventData.bodyData.type;
        if (type === "paragraph") type = "line";
        return type + "-renderer";
      }
      return "line-renderer";
    },
    disabled() {
      if (this.eventData?.isCustom) {
        const interviewersIds = this.eventData.interviewersIds || [];
        return !(
          this.hasAccess({
            permissions: [this.BASE_PERMISSION.ROUND.UPDATE],
          }) || interviewersIds.includes(this.loggedInUser.user_id)
        );
      }
      return !(
        this.loggedInUser.user_id == this.eventData.interviewerId ||
        this.hasAccess({
          permissions: [this.BASE_PERMISSION.ROUND.UPDATE],
        })
      );
    },
    inputAttrs() {
      let data = {};
      let inputClass = "";
      let parentClass = "";
      let wrapperClass = "";
      if (this.inputType === "line-renderer") {
        inputClass = "form-control-sm mb-0 custom-input-style";
        data["@keydown"] = this.handleKeydown;
      } else if (this.inputType === "paragraph-renderer") {
        inputClass = "form-control-sm mb-0 custom-input-style";
        data["@keydown"] = this.handleKeydown;
      } else if (this.inputType === "rating-renderer") {
        inputClass = "mb-0 custom-input-style w-100 h-100";
      } else if (this.inputType === "dropdown-renderer") {
        inputClass = "form-control-sm mb-0 custom-input-style";
      } else if (this.inputType === "checkbox-renderer") {
        inputClass = "my-0 mr-2";
        parentClass = "d-flex h-100 px-2";
        wrapperClass = "mb-0 d-flex align-items-center mr-3";
      } else if (this.inputType === "radio-renderer") {
        parentClass = "d-flex h-100 px-2";
        wrapperClass = "mb-0 d-flex align-items-center mr-3";
        inputClass = "my-0 mr-2";
      }
      data["class"] = inputClass;
      data["parentClass"] = parentClass;
      data["wrapperClass"] = wrapperClass;
      return data;
    },
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    popover_target() {
      return `popover-target-${this.params.data.id}`;
    },
    roundData() {
      return getRoundData(this.params.data, this.eventData);
    },
    interviewData() {
      if (this.eventData.noForm) {
        const interData =
          this.roundData["mappedInterviewers"][this.eventData.interviewerId];
        if (interData && !interData.formVersionId && !interData.formMasterId)
          return interData;
        return null;
      }
      if (this.eventData?.isCustom) return true;
      if (this.roundData && this.roundData["mappedInterviewers"])
        return getRoundInterviewData(this.roundData, this.eventData);
      return null;
    },
  },
  methods: {
    handleKeydown(event) {
      const allowedKeys = [
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Home",
        "End",
        "Backspace",
        "Delete",
        "Enter",
        "Escape",
        "Tab",
      ];
      const isCtrlA = event.key === "a" && (event.ctrlKey || event.metaKey);
      if (allowedKeys.includes(event.key) || isCtrlA) {
        event.stopPropagation();
      }
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    updateRow(inputKey, val) {
      if (this.disabled) return;
      const roundData = this.params?.data?.hasRounds[this.eventData.eventId];
      if (this.params?.eventData?.noForm) {
        const { interviewData } = getNoFormInterviewData(
          this.params.data,
          this.params.eventData
        );
        interviewData["interviewer_remarks"] = val;
        this.handleApiCall({
          id: interviewData.id,
          interviewer_remarks: val,
        });
      } else if (this.params?.eventData?.isCustom) {
        roundData.grade = val;
        api
          .updateGrade({
            roundId: roundData.id,
            grade: val,
          })
          .then(() => {
            this.$store.dispatch(
              "openSnackbar",
              { type: "success", message: "Data updated successfully!" },
              { root: true }
            );
          })
          .catch(() => {});
      } else {
        const { interviewData } = getInterviewData(
          this.params.data,
          this.params.eventData
        );
        const { formMasterId, formVersionId } = interviewData;

        let interviewer_remarks = {};
        let newRemark = "";
        if (!this.isDataValid) {
          newRemark = val;
          try {
            let marks = JSON.parse(newRemark);
            if (marks == null) marks = {};
            if (this.eventData.bodyData) {
              this.model[this.eventData.bodyData.key] =
                marks[this.eventData.bodyData.key];
            }
          } catch {
            //
          }
        } else {
          try {
            interviewer_remarks = JSON.parse(interviewData.interviewer_remarks);
          } catch {
            interviewer_remarks = {};
          }
          if (interviewer_remarks?.model) {
            newRemark = JSON.stringify({
              ...interviewer_remarks,
              model: { ...interviewer_remarks.model, [inputKey]: val },
              label: {
                ...interviewer_remarks.label,
                [this.eventData.bodyData.label]: val,
              },
            });
          } else {
            newRemark = JSON.stringify({
              ...interviewer_remarks,
              [inputKey]: val,
            });
          }
        }
        interviewData["interviewer_remarks"] = newRemark;
        this.handleApiCall({
          id: interviewData.id,
          interviewer_remarks: newRemark,
          formMasterId,
          formVersionId,
        });
      }
    },
    handleApiCall(data) {
      api
        .interviewRemarkApi(data)
        .then(() => {
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Data updated successfully!" },
            { root: true }
          );
        })
        .catch(() => {});
    },
    onSubmit(event) {
      const inputs = event.target.elements;
      for (const element of inputs) {
        const input = element;
        if (input.type === "text") {
          input.blur();
        }
      }
    },

    fetchData(val, inputKey) {
      this.updateRow(inputKey, val);
    },

    modelWatch(newValue) {
      const inputKey = this.eventData.bodyData.key;
      const val = newValue[inputKey];
      this.fetchData(val, inputKey);
    },
    getDebounceDelay() {
      return this.eventData.bodyData.type === "line" ||
        this.eventData.bodyData.type === "paragraph"
        ? 800
        : 300;
    },
  },
  beforeMount() {
    this.isEditable = cloneDeep(this.params.isEditable);
    this.eventData = cloneDeep(this.params.eventData);
    this.model = {
      [this.eventData.bodyData.key]: cloneDeep(this.params.value),
    };

    this.debouncedModelWatch = debounce(
      this.modelWatch,
      this.getDebounceDelay()
    );
    // Watch the model for changes, triggering the debounced method
    this.$watch(
      "model",
      (newValue) => {
        this.debouncedModelWatch(newValue);
      },
      { immediate: false, deep: true }
    );

    this.valuesAssigned = true;
  },
};
</script>

<style scoped>
.btn:focus {
  outline: none;
  box-shadow: none;
  color: white;
}

.custom-li {
  margin: 0;
  padding: 0;
  border: 0;
}
</style>
<style>
.ag-cell-wrapper {
  height: 100%;
}
.ag-cell-value {
  height: 100%;
}

.blankk {
  height: 100%;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.151);
}
</style>
