<script>
export default {
  template: `
        <div class="ag-header-group-cell-label">
            <div class="customHeaderLabel">{{params.displayName}}</div>
            <div class="customExpandButton" v-on:click="expandOrCollapse">
                <i class="fa" :class="groupExpanded ? 'fa-chevron-left' : 'fa-chevron-right'"></i>
            </div>
            </div>
        </div>
    `,
  data: function () {
    return {
      groupExpanded: false,
    };
  },
  beforeMount() {},
  mounted() {
    this.params.columnGroup
      .getProvidedColumnGroup()
      .addEventListener("expandedChanged", this.syncExpandButtons.bind(this));

    this.syncExpandButtons();
  },
  methods: {
    expandOrCollapse() {
      let currentState = this.params.columnGroup
        .getProvidedColumnGroup()
        .isExpanded();
      this.params.setExpanded(!currentState);
    },
    syncExpandButtons() {
      this.groupExpanded = this.params.columnGroup
        .getProvidedColumnGroup()
        .isExpanded();
    },
  },
};
</script>

<style scoped>
.customExpandButton {
  float: right;
  margin-top: 2px;
  margin-left: 3px;
}
.customExpandButton i {
  font-size: 11px;
  font-weight: 2;
  opacity: 0.7 !important;
}

.expanded {
  animation-name: toExpanded;
  animation-duration: 1s;
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}

.fa-arrow-right {
  color: #000000;
}

.collapsed {
  animation-name: toCollapsed;
  animation-duration: 1s;
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.ag-header-group-cell-label {
  display: flex;
  gap: 0.25rem;
  overflow: hidden;
}

.customHeaderLabel {
  overflow: hidden;
  text-overflow: ellipsis;
}

.customHeaderMenuButton,
.customHeaderLabel,
.customSortDownLabel,
.customSortUpLabel,
.customSortRemoveLabel {
  margin-top: 2px;
  margin-left: 4px;
  float: left;
}

.customSortDownLabel {
  margin-left: 10px;
}

.customSortUpLabel {
  margin-left: 1px;
}

.customSortRemoveLabel {
  float: left;
  font-size: 11px;
}

@keyframes toExpanded {
  from {
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }
}

@keyframes toCollapsed {
  from {
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
  }
}
</style>
