<template>
  <div>
    <common-calendar
      ref="calendar"
      :calendarOption="calendarOptions"
      :classes="'fullcalendar-header-style-md'"
      :onEventClick="handleEventClick"
      :filterObj="filters"
      :api="apiCall"
      :filters="{ setCustomButtons: true, isSlot: true }"
    ></common-calendar>
  </div>
</template>

<script>
import api from "../_api/index";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import CommonCalendar from "../../Common/_components/common-calendar.vue";
import datePickerIcons from "@/modules/Common/_utils/date-picker-icons";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  props: ["selectedEvent"],
  data() {
    return {
      ovserver: null,
      date: null,
      config: {
        format: "DD MMMM YYYY",
        inline: true,
        useCurrent: false,
        showClear: false,
        showClose: false,
        sideBySide: false,
        icons: datePickerIcons,
      },
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "weekends,viewAll dayGridMonth,timeGridWeek,timeGridDay",
        },
        contentHeight: 460,
      },
      pendingEvents: null,
      events: null,
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    filters() {
      return {
        eventId: this.selectedEvent,
        isValid: this.selectedEvent ? true : false,
      };
    },
    apiCall() {
      return api.getMyScheduleEventSlots;
    },
  },
  components: {
    CommonCalendar,
  },

  watch: {
    selectedEvent(value) {
      if (value) {
        this.refetchEvents();
      }
    },
  },

  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description refresh calendar events
     */
    refetchEvents() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },

    clickToday() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      calendarApi.today();
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description handle click event and open edit event modal
     */
    handleEventClick(clickInfo) {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.MENU],
        })
      )
        return;
      this.$router.push({
        name: "scheduleEventSlot",
        params: { eventId: clickInfo.event.extendedProps.eventId },
        query: { slot: clickInfo.event.extendedProps.slotId },
      });
    },

    filterEvents() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },
  },
};
</script>

<style scoped>
.slot-div {
  cursor: pointer;
}
.schedule-app-main {
  flex-grow: 1;
  padding-bottom: 10px;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  margin: 0 auto;
  text-transform: capitalize !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}

.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 16px;
  width: 16px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 16px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.span-circle {
  min-width: 16px;
  min-height: 16px;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-div {
  width: 100%;
}
</style>
<style>
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em !important;
}

.fc .fc-header-toolbar button {
  max-height: 40px;
  padding: 5px;
}

.fc .fc-header-toolbar .fc-toolbar-title {
  font-size: 20px;
}

.fc {
  font-size: 14px;
}

.fc .fc-daygrid-day-number {
  padding: 1px !important;
  font-size: 12px;
  line-height: 14px;
}

.fc .fc-daygrid-day-bottom a {
  font-size: 14px;
}

.fc .fc-daygrid tr td {
  height: 30px !important;
  max-height: 20px !important;
  min-height: 20px !important;
}
</style>
