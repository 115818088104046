import axios from "axios";

/**
 * Fetches all authorization groups.
 * @returns {Promise} Axios promise with the response.
 */
const getAllAuthGroups = () => {
  return axios.get("/all-groups");
};

/**
 * Fetches permissions for a specific group.
 * @param {number} groupId - ID of the group.
 * @returns {Promise} Axios promise with the response.
 */
const getPermissionsByGroup = (groupId) => {
  return axios.get(`/permissions-by-group`, {
    params: { groupId },
    meta: { abortRequests: false },
  });
};

/**
 * Toggles the `is_active` status of a permission.
 * @param {number} permissionId - ID of the permission.
 * @returns {Promise} Axios promise with the response.
 */
const togglePermissionActive = (permissionId) => {
  return axios.put(`/toggle-permission-active`, { permissionId });
};

/**
 * Toggles the `is_active` status of a group permission.
 * @param {number} groupPermissionId - ID of the group permission.
 * @returns {Promise} Axios promise with the response.
 */
const toggleGroupPermissionActive = (groupId, permissionId) => {
  return axios.put(`/toggle-group-permission-active`, {
    groupId,
    permissionId,
  });
};
const toggleUserInGroup = (groupId) => {
  return axios.put("/toggle-user-in-group", {
    groupId,
  });
};
const checkUserInGroup = (groupId) => {
  return axios.post("/is-user-in-group", { groupId });
};

export default {
  getAllAuthGroups,
  getPermissionsByGroup,
  togglePermissionActive,
  toggleGroupPermissionActive,
  toggleUserInGroup,
  checkUserInGroup,
};
