<template>
  <div id="myCampusSchedule" class="content">
    <common-header
      :data="['home', 'mySchedule', 'campusDrive']"
      :componentName="
        campusEventData?.campusDetail.recruitment_session_name +
        ' ~ ' +
        campusEventData?.round_title +
        (campusEventData?.round_name === 'Written Test' ? ' (WT)' : ' (I)')
      "
      :componentTooltip="`
              <div class='d-flex flex-column align-items-start'>
                  <div class='my-1'>Recruitment: <strong>${campusEventData?.campusDetail.recruitment_session_name}</strong></div>
                  <div class='my-1'>Event: <strong>${campusEventData?.round_title}</strong></div>
                  <div class='my-1'>Type: <strong>${campusEventData?.round_name}</strong></div>
              </div>`"
    >
      <div class="d-flex justify-content-end flex-wrap">
        <div class="mr-2">
          <p class="m-0 p-0 font-size-small text-right">
            {{ formatDate(campusEventData?.round_schedule) }}
            &nbsp;&nbsp;-&nbsp;&nbsp;
            {{ formatDate(campusEventData?.round_schedule_end) }}
          </p>
          <div class="p-0 d-flex align-items-center justify-content-end">
            <div class="p-0 m-0">
              <div v-if="campusEventData" class="d-flex">
                <p class="font-size-small m-0 p-0 font-weight-bold">
                  Dependent Event -&nbsp;
                </p>
                <p class="p-0 m-0 font-size-small">
                  {{
                    campusEventData?.dependentEventDetails
                      ? campusEventData?.dependentEventDetails.round_title
                      : "No Dependency"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </common-header>
    <div class="container-fluid">
      <div class="row mx-0">
        <div
          class="col-md-7 col-xs-7 mb-2 px-0 pr-md-2"
          v-if="selectedCampusEvent"
        >
          <slots-calendar
            ref="slotsCalendarModal"
            :selectedEvent="selectedCampusEvent"
          ></slots-calendar>
        </div>
        <div class="col-md-5 col-xs-5 border-left px-0 pl-md-2">
          <div class="d-flex flex-row-reverse">
            <router-link
              v-if="
                selectedCampusEvent &&
                hasAccess({
                  permissions: [PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU],
                })
              "
              class="btn btn-sm btn-cp text-nowrap float-right d-flex align-items-center"
              :to="{
                name: 'scheduleEventSlot',
                params: { eventId: selectedCampusEvent },
              }"
              >View Selected Event<i
                class="fa fa-arrow-circle-right ml-1"
                aria-hidden="true"
              ></i
            ></router-link>
            <div class="mx-2 my-schedule-event-select" style="width: 300px">
              <select
                id="mycampuseventpicker"
                class="form-control"
                data-live-search="true"
                data-width="100%"
                :data-dropdown-align-right="true"
                v-model="selectedCampusEvent"
              >
                <option
                  v-for="(event, index) in campusEvents"
                  :value="event.id"
                  :key="index"
                >
                  {{
                    event.status == "Pending"
                      ? event.round_title
                      : `${event.round_title}(${event.status})`
                  }}
                </option>
              </select>
            </div>
          </div>
          <common-chart
            chartId="my-schedule-status-chart"
            chartTitle="Status-wise"
            :colorIdx="0"
            :dataSlices="statusCountSlices"
            v-on:filter-data="filterApplicationStatus($event)"
          >
            <div class="filter-text" v-if="currentRoundStatus">
              <span v-if="currentRoundStatus"
                ><em class="fa fa-filter"></em>
                Round Result :
                {{ currentRoundStatus }}</span
              ><br />
            </div>
          </common-chart>
        </div>
      </div>
      <div
        class="d-flex flex-wrap justify-content-between m-0 mt-4"
        style="width: 100%"
      >
        <div class="d-flex mb-1 mb-md-0">
          <div class="btn-group dropup float-left">
            <button
              class="btn dropdown-toggle btn-cp"
              type="button"
              data-toggle="dropdown"
              id="dropdownMenuButtonli"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-tasks" aria-hidden="true"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonli">
              <li class="dropdown-header">Manage</li>
              <template>
                <li
                  class="dropdown-item"
                  @click="openPassQualified"
                  v-if="
                    campusEventData &&
                    campusEventData?.round_name === 'Written Test'
                  "
                >
                  Pass Qualified
                </li>
                <li
                  class="dropdown-item"
                  @click="sendGoogleMeet"
                  v-if="
                    campusEventData &&
                    campusEventData?.round_name === 'Interview'
                  "
                >
                  Send Google Meet
                </li>
              </template>
            </ul>
          </div>
          <div class="mx-2" style="width: 120px">
            <select
              v-model="currentRoundStatus"
              class="form-control select-cp"
              @change="changeCurrentRoundStatus()"
            >
              <option class="pagesizeoption" value="" selected="">All</option>
              <option class="pagesizeoption" value="Pending">Pending</option>
              <option class="pagesizeoption" value="Passed">Passed</option>
              <option class="pagesizeoption" value="Failed">Dropped</option>
            </select>
          </div>
        </div>
        <div class="">
          <div class="float-right">
            <select
              @change="onPageSizeChanged($event)"
              class="form-control select-cp"
            >
              <option class="pagesizeoption" value="50">50</option>
              <option class="pagesizeoption" value="100" selected="">
                100
              </option>
              <option class="pagesizeoption" value="200">200</option>
              <option class="pagesizeoption" value="500">500</option>
              <option class="pagesizeoption" value="1000">1000</option>
              <option class="pagesizeoption" value="2000">2000</option>
            </select>
          </div>
          <div
            class="refresh-div float-right d-flex"
            v-if="selectedCampusEvent"
          >
            <p
              class="alert alert-info alert-sm"
              v-if="selectedApplicants && selectedApplicants.length > 0"
            >
              <span class="hide-on-mobile">
                {{ selectedApplicants.length }} selected.
              </span>
              <span class="hide-on-desktop px-2">
                {{ selectedApplicants.length }}
              </span>
            </p>
            <div class="mx-2">
              <button
                class="btn btn-cp"
                v-if="totalVisibleRows > 0 && selectedApplicants.length == 0"
                @click="selectAllOnCurrentPage"
              >
                <i class="fa fa-square" aria-hidden="true"></i>
                <span class="hide-on-mobile">Select all</span>
              </button>
              <button
                class="btn btn-cp"
                v-else-if="
                  totalVisibleRows > 0 &&
                  selectedApplicants.length > 0 &&
                  selectedApplicants.length < maxApplicantCountToCurrentPage &&
                  selectedApplicants.length < totalVisibleRows
                "
                @click="selectAllOnCurrentPage"
              >
                <i class="fa fa-minus-square pl-1" aria-hidden="true"></i>
                <span class="hide-on-mobile">Select all</span>
              </button>
              <button
                class="btn btn-cp"
                v-else-if="totalVisibleRows > 0"
                @click="deselectAllOnCurrentPage"
              >
                <i class="fa fa-check-square pl-1" aria-hidden="true"></i>
                <span class="hide-on-mobile">Deselect all</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="my-3">
        <div id="grid-theme-wrapper" class="w-100">
          <ag-grid-vue
            class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
            v-if="gridOptions"
            style="height: 80vh"
            @selection-changed="onSelectionChanged"
            rowSelection="multiple"
            @filter-changed="onFilterChanged"
            @sort-changed="onSortChanged"
            :columnDefs="columns"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :enableBrowserTooltips="true"
            :gridOptions="gridOptions"
            :tooltipShowDelay="0"
            @gridReady="onGridReady"
            :key="paginationPageSize"
            singleClickEdit="true"
            enableCellEditingOnBackspace="true"
            stopEditingWhenCellsLoseFocus="true"
          >
          </ag-grid-vue>
        </div>
      </div>
      <add-score-modal
        ref="addEventRoundScore"
        :roundData="roundData"
        :interviewData="interviewData"
        :setIterviewData="setIterviewData"
        :refreshPage="refreshPage"
      ></add-score-modal>
      <pass-qualified-modal
        :isEventView="true"
        :pendingApplicantIds="pendingApplicantIds"
        :selectedSlotData="selectedSlotData"
        :eventId="campusEventData ? campusEventData.id : null"
        :selectedCampusEventData="campusEventData"
        @submitted="onPassQualified"
      ></pass-qualified-modal>
      <common-modal
        :id="commonModalData.id"
        :modal-title="commonModalData.title"
        :modal-body="commonModalData.body"
        :show-cancel="commonModalData.showCancel"
        :modal-color="commonModalData.color"
        :modal-size-class="commonModalData.sizeClass"
        @actionPerformed="emitOnCurrent"
      />
      <MyScheduleAddDateModal :handleSendMeeting="handleSendMeeting" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import campusRecruitmentApi from "../../CampusRecruitment/_api/index";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import { SCHEDULE_APPLICANTS_DATA_COL_DEFS } from "../_utils/schedule_applicant_data_list";
import CommonChart from "../../Common/_components/common-highchart.vue";
import SlotsCalendar from "./slots-calendar.vue";
import myScheduleApplicantActionCellVue from "./my-schedule-applicant-action-cell.vue";
import customFormCell from "./custom-form-cell.vue";
import CommonModal from "../../Common/_components/modal";
import addScoreModal from "./my-schedule-add-score-modal.vue";
import PassQualifiedModal from "../../CampusRecruitment/_components/pass-qualified-modal.vue";
import moment from "moment";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import MyScheduleAddDateModal from "./my-schedule-add-date-modal.vue";
import CustomHeaderGroup from "./customHeaderGroup.vue";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
ModuleRegistry.registerModules([ClientSideRowModelModule]);
import {
  getInterviewData,
  getNoFormInterviewData,
  gradeBodyData,
  noFormBodyData,
} from "../_utils/my-campus-helper";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

export default {
  components: {
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{name:'applicantprofile', params:{slug:params.data.slug}}"><i title="View Profile" class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    CommonChart,
    SlotsCalendar,
    // eslint-disable-next-line vue/no-unused-components
    "slot-applicant-action": myScheduleApplicantActionCellVue,
    // SelectTemplateModal,
    addScoreModal,
    // eslint-disable-next-line vue/no-unused-components
    "custom-form-cell": customFormCell,
    CommonModal,
    PassQualifiedModal,
    MyScheduleAddDateModal,
    // eslint-disable-next-line vue/no-unused-components
    CustomHeaderGroup,
  },
  data() {
    return {
      paginationPageSize: 100,
      selectedSlotData: null,
      interviewData: null,
      applicationStatus: "",
      statusCount: [],
      gridOptions: null,
      getSelectedCampusEvent: this.$route.params.event,
      columnDefs: SCHEDULE_APPLICANTS_DATA_COL_DEFS,
      loadingTemplate: "No applicants found",
      noRowsTemplate: "No applicants found",
      selectedApplicants: [],
      selectedRowCount: null,
      campusEvents: [],
      applicantList: [],
      selectedCampusEvent: null,
      maxApplicantCountToCurrentPage: null,
      totalSelectable: null,
      filterStatus: "",
      showRowStyles: true,
      response: null,
      totalVisibleRows: null,
      roundData: null,
      roundDataAction: "",
      currentRoundStatus: "Pending",
      pendingApplicants: null,
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    pendingApplicantIds() {
      if (!this.campusEventData || !this.response) return [];
      const filterApplicants = this.response.applicants.filter((applicant) => {
        return (
          applicant.currentRoundData &&
          applicant.currentRoundData.round_result == "Pending"
        );
      });
      return filterApplicants.map((applicant) => applicant.id);
    },
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
      formTemplates: "$_form_template/getForms",
    }),
    campusEventData() {
      if (!this.selectedCampusEvent) return null;
      else
        return this.campusEvents.find(
          (event) => event.id == this.selectedCampusEvent
        );
    },
    columns() {
      let eventsCols = [];
      if (this.response && this.response.events) {
        for (let index = this.response.events.length - 1; index >= 0; index--) {
          const event = this.response.events[index];
          const lastEvent = index === this.response.events.length - 1;
          let children = [];
          let isCurrentRound = false;
          if (event.round_name === "Interview") {
            if (lastEvent) isCurrentRound = true;
            if (this.response.formIds && this.response.formIds[event.id]) {
              for (const form of this.response.formIds[event.id]) {
                let childElement = this.getChildElements(
                  event,
                  form,
                  isCurrentRound
                );
                children.push(...childElement);
              }
            }
          } else {
            children = [this.getScoreColumn(event.id)];
          }
          const interviewersIds = Object.keys(event.interviewers);
          children.push(
            this.getGradeColumn(event.id, interviewersIds, isCurrentRound)
          );
          if (lastEvent) children.push(this.getActionColumn(event.id));
          const name =
            event.round_title +
            " - " +
            ` [${event.round_name === "Interview" ? " I " : " W "}] `;
          const col = {
            headerName: name,
            headerTooltip: name,
            children,
          };
          eventsCols.push(col);
        }
      }
      return [...this.columnDefs, ...eventsCols];
    },
    statusCountSlices() {
      return this.statusCount.map((status) => {
        if (parseInt(status.status_count) !== 0) {
          return {
            name: status.status_name,
            y: parseInt(status.status_count),
          };
        }
      });
    },
  },
  methods: {
    getScoreColumn(eventDataId) {
      return {
        headerName: "Score",
        width: 100,
        minWidth: 100,
        maxWidth: 600,
        suppressHeaderMenuButton: true,
        valueGetter: function (params) {
          if (params.data) {
            const roundData = params.data.hasRounds[eventDataId];
            if (roundData.score || roundData.outOf)
              return `${roundData.score || "NA"} / ${roundData.outOf || "NA"}`;
          }
          return "NA";
        },
        cellRendererParams: {
          eventData: {
            eventId: eventDataId,
          },
        },
      };
    },
    getGradeColumn(eventDataId, interviewersIds, isCurrentRound) {
      return {
        headerName: "Grade",
        width: 120,
        minWidth: 100,
        maxWidth: 650,
        suppressHeaderMenuButton: true,
        cellRenderer: "custom-form-cell",
        cellStyle: { padding: 0, height: "100%" },
        cellRendererParams: (params) => {
          return {
            eventData: {
              bodyData: gradeBodyData,
              isCustom: true,
              eventId: eventDataId,
              interviewersIds,
            },
            isEditable: this.checkIsEditable(
              isCurrentRound,
              params,
              interviewersIds
            ),
          };
        },
        valueGetter: function (params) {
          if (params.data) {
            const roundData = params.data.hasRounds[eventDataId];
            return roundData?.grade;
          }
          return null;
        },
      };
    },
    getActionColumn(eventDataId) {
      return {
        headerName: "Action",
        field: "current_round_status",
        headerTooltip: "Current Round Status",
        suppressHeaderMenuButton: true,
        width: 100,
        maxWidth: 650,
        minWidth: 100,
        filter: true,
        cellRenderer: "slot-applicant-action",
        cellStyle: (params) => {
          if (params.value === "Failed" || params.value === "Differ") {
            return { color: "black", backgroundColor: "#ff817e" };
          } else if (params.value === "Duplicate") {
            return { color: "black", backgroundColor: "#fff396" };
          } else if (params.value === "Shortlisted") {
            return { color: "black", backgroundColor: "#b2ff8f" };
          } else if (params.value === "Pending") {
            return { color: "black", backgroundColor: "#FFFFFF" };
          } else {
            return { color: "black", backgroundColor: "#abf7b1" };
          }
        },
        cellRendererParams: {
          eventData: {
            eventId: eventDataId,
          },
        },
        pinned: "right",
        valueGetter: function (params) {
          if (params.data) {
            const roundData = params.data.hasRounds[eventDataId];
            return roundData?.round_result;
          }
          return null;
        },
      };
    },
    getChildElements(event, form, isCurrentRound) {
      // let editableIndex = 0;
      const { formMasterId, formVersionId, interviewers } = form;
      return Object.keys(event.interviewers)
        .filter((interId) => interviewers[interId])
        .map((k) => {
          const data = event.interviewers[k];
          const formMasterIdData = (this.formTemplates || []).find(
            (f) => f.id === formMasterId
          );
          const commonData = {
            formMasterId: formMasterId,
            formVersionId: formVersionId,
            eventId: event.id,
            interviewerId: k,
          };
          if (!formMasterId) {
            const eventData = {
              ...commonData,
              bodyData: noFormBodyData,
              noForm: true,
            };
            const response = {
              headerName: "No Form Response",
              headerTooltip: "No Form Response",
              width: 120,
              minWidth: 100,
              maxWidth: 650,
              suppressHeaderMenuButton: true,
              cellRenderer: "custom-form-cell",
              cellStyle: { padding: 0, height: "100%" },
              cellRendererParams: (params) => {
                return {
                  eventData: eventData,
                  isEditable: this.checkIsEditable(
                    isCurrentRound,
                    params,
                    commonData.interviewerId
                  ),
                };
              },
              valueGetter: (params) => {
                const { value } = getNoFormInterviewData(
                  params.data,
                  eventData,
                  true
                );
                return value;
              },
            };

            return {
              headerName:
                data.first_name + " " + data.last_name + " - [ No form ]",
              headerTooltip:
                data.first_name + " " + data.last_name + " - [ No form ]",
              children: [response],
            };
          } else {
            const formData = formVersionId
              ? formMasterIdData.formVersion.find((d) => d.id === formVersionId)
              : formMasterIdData.formVersion[0];
            const sss = [];
            formData.form_json.sections.forEach((s, index) => {
              s.body.forEach((b, index2) => {
                sss.push({
                  sectionIndex: index,
                  bodyIndex: index2,
                  bodyData: b,
                  formMasterId,
                  formVersionId,
                });
              });
            });
            return {
              headerName:
                data.first_name +
                " " +
                data.last_name +
                " - " +
                formMasterIdData.title,
              headerTooltip:
                data.first_name +
                " " +
                data.last_name +
                " - " +
                formMasterIdData.title +
                ` [ master ${formMasterId} - version ${formVersionId}]`,
              children: sss.map((formData) => {
                const eventData = {
                  bodyData: {
                    ...formData.bodyData,
                    properties: {
                      ...formData.bodyData.properties,
                      placeholder: formData.bodyData.properties.placeholder
                        ? formData.bodyData.properties.placeholder
                        : formData.bodyData.label,
                    },
                  },
                  sectionIndex: formData.sectionIndex,
                  bodyIndex: formData.bodyIndex,
                  ...commonData,
                };
                return {
                  headerName: formData.bodyData.label,
                  headerTooltip: formData.bodyData.label,
                  suppressHeaderMenuButton: true,
                  width: this.getColWidth(formData.bodyData),
                  minWidth: 150,
                  maxWidth: 600,
                  filter: true,
                  cellRenderer: "custom-form-cell",
                  cellRendererParams: (params) => {
                    return {
                      eventData: eventData,
                      isEditable: this.checkIsEditable(isCurrentRound, params),
                    };
                  },
                  colSpan: (params) => {
                    const data = params?.data;
                    if (!data) return 1;
                    const { interviewData, valueValid } = getInterviewData(
                      params.data,
                      eventData,
                      true
                    );
                    if (!interviewData) {
                      return sss.length;
                    }
                    if (!valueValid) {
                      return sss.length;
                    }
                    return 1;
                  },
                  cellStyle: { padding: 0, height: "100%" },
                  valueGetter: (params) => {
                    const { value } = getInterviewData(
                      params.data,
                      eventData,
                      true
                    );
                    return value;
                  },
                };
              }),
            };
          }
        });
    },
    checkIsEditable(res, params, interviewer_id) {
      const interviewerIds = Array.isArray(interviewer_id)
        ? interviewer_id
        : [interviewer_id];

      if (
        params.data?.currentRoundData.round_result === "Pending" &&
        res &&
        (hasAccess({ permissions: [this.BASE_PERMISSION.ROUND.UPDATE] }) ||
          interviewerIds.some(
            (id) => parseInt(id) === parseInt(this.loggedInUserId)
          ))
      ) {
        return true;
      }
      return false;
    },
    getColWidth(bodyData) {
      const calculatedWidth = (options) => {
        const basePadding = 35;
        const totalWidth = options.reduce((width, option) => {
          return width + option.label.length * 8 + basePadding;
        }, 0);
        return totalWidth;
      };
      if (bodyData.type === "checkbox" || bodyData.type === "radio") {
        if (bodyData.properties.otherOption) {
          return calculatedWidth(bodyData.properties.options) + 120;
        }
        return calculatedWidth(bodyData.properties.options);
      }

      return 180;
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    formatDate(date) {
      return moment(date).format("MMM Do YYYY");
    },
    onPassQualified() {
      this.refreshData();
    },
    openPassQualified() {
      $("#passQualified").modal("show");
    },
    sendGoogleMeet() {
      const pendingApplicants = [];
      for (const applicant of this.selectedApplicants) {
        if (
          applicant.currentRoundData &&
          applicant.currentRoundData.round_result == "Pending"
        ) {
          pendingApplicants.push(applicant.email);
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Please select only pending applicants",
            false,
            "danger",
            "modal-m"
          );
          return;
        }
      }
      if (pendingApplicants.length == 0) {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select atleast one applicant",
          false,
          "danger",
          "modal-m"
        );
        return;
      }
      pendingApplicants.push(this.loggedInUser.email_address);
      this.pendingApplicants = pendingApplicants;
      $("#sendMeetLink").modal("show");
    },
    handleSendMeeting(time) {
      const { from_time, to_time } = time;
      const data = {
        emails: [...this.pendingApplicants],
        from_time: from_time,
        to_time: to_time,
        position: "Fresher",
      };
      campusRecruitmentApi
        .sendGoogleMeetInvitation(data)
        .then(() => {
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Google meet link sent successfully!" },
            { root: true }
          );
        })
        .catch((err) => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message:
                err.response && err.response.data.message
                  ? err.response.data.message
                  : "Something went wrong",
            },
            { root: true }
          );
        });
    },
    refreshPage(callback) {
      this.selectedApplicants = [];
      this.refreshData(callback);
    },
    setIterviewData(data) {
      this.interviewData = data;
    },
    passApplicantRound(applicant) {
      if (
        applicant.currentRoundData &&
        applicant.currentRoundData.round_result === "Pending"
      ) {
        this.roundData = applicant.currentRoundData;
        this.roundDataAction = "Passed";
        this.$refs.addEventRoundScore.setPassRoundModal(true);
        $("#addEventRoundScore").modal("show");
      }
    },
    onGridReady(params) {
      this.gridOptions.api = params.api;
    },
    failApplicantRound(applicant) {
      if (
        applicant.currentRoundData &&
        applicant.currentRoundData.round_result === "Pending"
      ) {
        this.roundData = applicant.currentRoundData;
        this.roundDataAction = "Failed";
        this.$refs.addEventRoundScore.setPassRoundModal(false);
        $("#addEventRoundScore").modal("show");
      }
    },
    onSelectionChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      if (this.gridOptions.api.paginationGetRowCount() > 0) {
        this.gridOptions.api.hideOverlay();
      } else {
        this.gridOptions.api.showNoRowsOverlay();
      }
    },

    onFilterChanged() {
      const rowData = [];
      let totalVisibleRows = 0;
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
      this.gridOptions.api.forEachNodeAfterFilter(() => {
        totalVisibleRows++;
      });
      this.totalVisibleRows = totalVisibleRows;
      this.selectedApplicants = rowData;
      if (this.gridOptions.api.paginationGetRowCount() > 0) {
        this.gridOptions.api.hideOverlay();
      } else {
        this.gridOptions.api.showNoRowsOverlay();
      }
    },

    onSortChanged() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },
    selectAllOnCurrentPage() {
      const maxNumbers =
        this.gridOptions.api.paginationGetPageSize() *
        (this.gridOptions.api.paginationGetCurrentPage() + 1);
      this.gridOptions.api.forEachNodeAfterFilterAndSort((node, index) => {
        node.setSelected(index < maxNumbers);
      });
    },
    deselectAllOnCurrentPage() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },
    onPageSizeChanged(event) {
      const rowCount = Number(event.target.value);
      this.gridOptions.paginationPageSize = rowCount;
      this.gridOptions.cacheBlockSize = rowCount;
      this.setDataSource();
    },
    changeCurrentRoundStatus() {},
    filterApplicationStatus(event) {
      const selected = !event.point.selected;
      if (selected) {
        this.currentRoundStatus = event.point.name;
      } else this.currentRoundStatus = "";
    },
    generateEventsColumns() {},
    onPaginationChanged() {
      if (this.gridOptions && this.gridOptions.api) {
        this.maxApplicantCountToCurrentPage =
          this.gridOptions.api.paginationGetPageSize() *
          (this.gridOptions.api.paginationGetCurrentPage() + 1);
      }
    },
    getFilters() {
      return {
        eventId: this.selectedCampusEvent,
        ...(this.currentRoundStatus ? { type: this.currentRoundStatus } : {}),
      };
    },
    refreshData(callback) {
      this.setDataSource(callback);
      this.fetchApplicantCountByStatus();
    },
    fetchApplicantCountByStatus() {
      axios
        .get(
          `campus-drive/schedules/count/status/${this.selectedCampusEvent}`,
          { params: {} }
        )
        .then((res) => {
          this.statusCount = res.data;
        })
        .catch(() => (this.statusCount = []));
    },
    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description method to set applicant list in data table
     */
    setDataSource(callback) {
      if (this.selectedCampusEvent) {
        let filters = this.getFilters();
        axios
          .post(
            `applicants/my_schedule/campus_applicant_list/${filters.eventId}`,
            {},
            { params: filters }
          )
          .then(
            (data) => {
              this.totalVisibleRows = data.data.applicants.length;
              if (data.data.applicants.length > 0) {
                this.gridOptions.api.hideOverlay();
              } else {
                this.gridOptions.api.showNoRowsOverlay();
              }
              const mappedEvent = data.data.events.reduce((obj, e) => {
                e.interviewers = {};
                obj[e.id] = e;
                return obj;
              }, {});

              const formIds = {};
              const handleForms = (inter, eventId) => {
                if (!formIds[eventId]) formIds[eventId] = [];
                const exist = formIds[eventId].find(
                  (form) =>
                    form.formMasterId == inter.formMasterId &&
                    form.formVersionId == inter.formVersionId
                );
                if (!exist) {
                  formIds[eventId].push({
                    formMasterId: inter.formMasterId,
                    formVersionId: inter.formVersionId,
                    interviewers: {
                      [inter.user_id]: inter.user_id,
                    },
                  });
                } else {
                  exist["interviewers"][inter.user_id] = inter.user_id;
                }
              };
              data.data.applicants.forEach((a) => {
                a.hasRounds = a.oldDependentEvent.reduce((obj, r) => {
                  if (r.eventSlot.roundId) {
                    obj[r.eventSlot.roundId] = r;
                    obj[r.eventSlot.roundId]["mappedInterviewers"] = {};
                    if (
                      mappedEvent[r.eventSlot.roundId] &&
                      mappedEvent[r.eventSlot.roundId]["round_name"] ===
                        "Interview"
                    ) {
                      for (const it of r.interviewers) {
                        this.handleFormVerson(it);
                        obj[r.eventSlot.roundId]["mappedInterviewers"][
                          it.user_id
                        ] = it;
                        handleForms(it, r.eventSlot.roundId);
                        mappedEvent[r.eventSlot.roundId]["interviewers"][
                          it.user_id
                        ] = it;
                      }
                    }
                  }
                  return obj;
                }, {});
              });
              data.data.formIds = formIds;
              this.response = data.data;
              if (callback) {
                const roundData = data.data.applicants.find(
                  (d) => d.currentRoundData.id === this.roundData.id
                );
                this.roundData = roundData?.currentRoundData;
                callback();
              }
              this.gridOptions.api.setGridOption(
                "rowData",
                data.data.applicants
              );
            },
            (err) => {
              console.log(err);
            }
          );
      }
    },
    handleFormVerson(it) {
      if (it.formMasterId && !it.formVersionId) {
        const formMasterIdData = (this.formTemplates || []).find(
          (f) => f.id === it.formMasterId
        );
        const formData = formMasterIdData.formVersion[0];
        if (formData) {
          it.formVersionId = formData.id;
        } else {
          it.formMasterId = null;
        }
      } else if (it.formVersionId && !it.formMasterId) {
        const formMasterIdData = (this.formTemplates || []).find((f) => {
          const childVersion = f.formVersion.find(
            (cv) => cv.id === it.formVersionId
          );
          return !!childVersion;
        });
        if (formMasterIdData) {
          it.formMasterId = formMasterIdData.id;
        } else {
          it.formMasterId = null;
          it.formVersionId = null;
        }
      } else if (!it.formMasterId && !it.formVersionId) {
        it.formMasterId = null;
        it.formVersionId = null;
      }
    },
    getFormsTemplates() {
      this.$store.dispatch("$_form_template/getAllFormMasters");
    },
    getRounds() {
      campusRecruitmentApi
        .fetchMyCampusRoundSchedules()
        .then((res) => {
          if (this.getSelectedCampusEvent) {
            let selectedEventData = false;
            const filterData = res.data.filter((event) => {
              if (event.id == this.getSelectedCampusEvent)
                selectedEventData = true;
              return (
                event.id == this.getSelectedCampusEvent ||
                event.status == "Pending"
              );
            });
            this.campusEvents = filterData;
            if (selectedEventData) {
              this.selectedCampusEvent = this.getSelectedCampusEvent;
            } else {
              this.$store.dispatch(
                "openSnackbar",
                {
                  type: "danger",
                  message:
                    "Selected event is not present in your scheduled list!",
                },
                { root: true }
              );
            }
          } else {
            this.campusEvents = res.data;
            this.selectedCampusEvent = this.campusEvents[0].id;
          }
        })
        .catch((error) => {
          console.log("error", error.message);
        });
    },
  },
  created() {
    this.getRounds();
  },
  updated() {
    $("#mycampuseventpicker").selectpicker("refresh");
  },
  watch: {
    formTemplates() {
      this.setDataSource();
    },
    selectedCampusEvent(value) {
      if (
        this.$route.name !== "myCampusSchedule" ||
        (this.$route.name === "myCampusSchedule" &&
          this.$route.params.event !== value)
      ) {
        this.$router.replace({
          name: "myCampusSchedule",
          params: { event: value },
        });
      }

      this.refreshData();
    },
    currentRoundStatus() {
      this.setDataSource();
    },
  },
  beforeDestroy() {
    this.$store.dispatch("$_schedule_dashboard/setSelectedCampusEvent", null);
  },
  beforeMount() {
    this.getFormsTemplates();
    this.gridOptions = {
      applyColumnDefOrder: true,
      rowData: null,
      rowHeight: 30,
      headerHeight: 35,
      suppressPropertyNamesCheck: true,
      suppressRowClickSelection: true,
      enableServerSideFilter: true,
      enableCellTextSelection: true,
      enableColResize: true,
      suppressMovableColumns: true,
      suppressHeaderFocus: true,
      suppressCellFocus: true,
      rowSelection: "multiple",
      // rowDeselection: true,
      onPaginationChanged: (event) => this.onPaginationChanged(event),
      // rowModelType: "infinite",
      defaultColDef: {
        // flex: 1,
        resizable: true,
        floatingFilter: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        // set filtering on for all columns
        filter: true,
      },
      getRowId: (params) => {
        return params.data.id;
      },
      infiniteInitialRowCount: 0,
      animateRows: true,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      pagination: true,
      paginationPageSize: 100,
      paginationPageSizeSelector: false,
      context: {
        componentParent: this,
      },
      modules: [ClientSideRowModelModule], // Include the client-side row model module
      components: {
        CustomHeaderGroup: CustomHeaderGroup, // Register the custom header group
      },
    };
  },
};
</script>
<style>
.font-size-small {
  font-size: 12px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
/* .ag-header-group-cell:last-child {
  border-right: none; /* Remove separator after the last group */
/* } */

/* Apply separator to the group cells */
.ag-theme-alpine .group-separator .ag-header-group-cell {
  border-right: 2px solid #1ec927 !important; /* Strong specificity with !important */
  padding: 0px;
}

/* Target general header group cells for padding */
.ag-theme-alpine .ag-header-group-cell {
  padding: 0px !important;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .hide-on-desktop {
    display: none !important;
  }
}

.ag-theme-alpine {
  --ag-row-border-style: solid;
  --ag-row-border-width: 0.5px;
  --ag-row-border-color: lightgray;
  --ag-cell-horizontal-border: solid lightgray;
}
</style>
