/**
 * @memberof module:MailBox.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} templates template data to be added
 * @description stores fetched templates to the list
 */
const GET_TEMPLATES = (state, templates) => {
  state.storeTemplates = templates;
};

/**
 * @memberof module:MailBox.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} template template data to be added
 * @description adds template to the list
 */
const ADD_TEMPLATE = (state, template) => {
  state.storeTemplates.push(template);
};

/**
 * @memberof module:MailBox.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Number} id ID of template data to be deleted
 * @description deletes template from the list
 */
const DELETE_TEMPLATE = (state, id) => {
  const templateIndex = state.storeTemplates.findIndex(
    (template) => template.id === id
  );
  state.storeTemplates.splice(templateIndex, 1);
};

/**
 * @memberof module:MailBox.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data template data to be updated
 * @description updates template in the list
 */
const UPDATE_TEMPLATE = (state, data) => {
  const templateIndex = state.storeTemplates.findIndex(
    (temp) => temp.id === data.id
  );
  state.storeTemplates[templateIndex].bcc = data.bcc;
  state.storeTemplates[templateIndex].cc = data.cc;
  state.storeTemplates[templateIndex].name = data.name;
  state.storeTemplates[templateIndex].template_body = data.template_body;
  state.storeTemplates[templateIndex].template_subject = data.template_subject;
};

const UPDATE_TEMPLATE_ORDER = (state, data) => {
  for (const index in state.storeTemplates) {
    if (state.storeTemplates[index]) {
      state.storeTemplates[index].order = data[index].order;
    }
  }
};

/**
 * @memberof module:MailBox.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} applicantsData data of applicants to be mailed
 * @description sets applicants data to be used in mailbox component for mailing purposes
 */
const SET_APPLICANTS_DATA = (state, applicantsData) => {
  state.applicantsData = applicantsData;
};

const SET_ENTITIES_DATA = (state, entitiesData) => {
  state.entitiesData = entitiesData;
};

/**
 * @memberof module:MailBox.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} mailStatus status of mail sending
 * @description sets mail sending status
 */
const SET_MAIL_STATUS = (state, mailStatus) => {
  state.mailStatus = mailStatus;
};

/**
 * @memberof module:MailBox.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} campusData data of campus applicants to be mailed
 * @description sets campus data to be used in mailbox component for mailing purposes
 */
const SET_CAMPUS_DATA = (state, campusData) => {
  state.campusData = campusData;
};

/**
 * @memberof module:MailBox.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} availableMailBodies contains id of available mail bodies
 * @description sets array of available mail body ids to be used in template component for generating checkbox of target bodies
 */
const SET_AVAILABLE_MAIL_BODIES = (state, availableMailBodies) => {
  state.availableMailBodies = availableMailBodies;
};

const RESET_MAIL_STATUS = (state) => {
  state.mailStatus = "INITIAL";
};

const MAIL_UPDATE_SUCCESS_STATUS = (state) => {
  state.mailUpdateSuccessStatus = !state.mailUpdateSuccessStatus;
};

/**
 * @memberof module:MailBox.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} campuses contains object of campus data
 * @description sets array of active campuses to campusSessionData
 */
const SET_CAMPUS_SESSION_DATA = (state, campuses) => {
  state.campusSessionData = campuses;
};

/**
 * @memberof module:MailBox
 * @namespace store.mutations
 * @description mutations for mailbox module
 */
export default {
  GET_TEMPLATES,
  ADD_TEMPLATE,
  DELETE_TEMPLATE,
  UPDATE_TEMPLATE,
  SET_APPLICANTS_DATA,
  SET_ENTITIES_DATA,
  SET_MAIL_STATUS,
  SET_CAMPUS_DATA,
  SET_AVAILABLE_MAIL_BODIES,
  RESET_MAIL_STATUS,
  MAIL_UPDATE_SUCCESS_STATUS,
  SET_CAMPUS_SESSION_DATA,
  UPDATE_TEMPLATE_ORDER,
};
