<template>
  <div class="content">
    <common-header :data="['home', 'mySchedule']" />
    <div class="container-fluid">
      <div
        v-if="isSmallScreen"
        class="d-flex w-100 justify-content-between mb-3"
      >
        <button
          class="btn btn-primary d-md-none"
          type="button"
          data-toggle="collapse"
          data-target="#collapseFilters"
          aria-expanded="false"
          aria-controls="collapseFilters"
          style="background-color: #8f1452; color: white; border: #8f1452"
        >
          Filters
        </button>
        <button
          class="btn btn-primary"
          type="button"
          data-toggle="collapse"
          data-target="#collapsePendingEvents"
          aria-expanded="false"
          aria-controls="collapsePendingEvents"
          style="background-color: #8f1452; color: white; border: #8f1452"
        >
          Pending Events
        </button>
      </div>
      <div class="collapse d-md-none w-100 mb-2" id="collapseFilters">
        <div class="card card-body">
          <div class="applicant-schedule-sidebar-section row pl-4">
            <div class="col-12 col-sm-6">
              <input
                v-if="$refs.calendar"
                id="includeWeekends"
                type="checkbox"
                class="form-check-input"
                :checked="
                  $refs['calendar']
                    ? $refs['calendar'].defaultCalendarOptions.weekends
                    : true
                "
                @change="weekendToggle"
              />
              <label for="includeWeekends"> Include Weekends</label>
            </div>
            <div class="col-12 col-sm-6">
              <input
                v-if="$refs.calendar"
                id="showAll"
                type="checkbox"
                class="form-check-input"
                :checked="
                  $refs['calendar']
                    ? !$refs['calendar'].defaultCalendarOptions.dayMaxEvents
                    : true
                "
                @change="dayToggle"
              />
              <label for="showAll"> Show All Events</label>
            </div>
          </div>
          <div class="applicant-schedule-sidebar-section">
            <h5>Filters</h5>
            <div class="col-12 p-0">
              <label htmlFor="events">Include Events</label><br />
              <select
                v-model="selectedType"
                class="form-control"
                @change="filterEvents"
              >
                <option value="">All Events</option>
                <option value="Aptitude">Aptitude Only</option>
                <option value="Written Test">Written Test Only</option>
                <option value="Interview">Interviews Only</option>
                <option value="Review">Reviews Only</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse d-md-none w-100 mb-2" id="collapsePendingEvents">
        <div class="card card-body p-2">
          <div
            class="applicant-schedule-sidebar-section"
            v-if="pendingEvents.length > 0 || pendingCampusEvents.length > 0"
          >
            <div class="pending-events-section m-0">
              <h5>Pending A. Events - ({{ pendingEvents.length }})</h5>
              <div class="mb-2" v-for="event in pendingEvents" :key="event.id">
                <span
                  style="margin-top: 3px"
                  class="event-circle"
                  :title="event.roundName"
                >
                  {{ event.round }}
                </span>
                <p class="mb-0 font-weight-bold">
                  {{ event.start | formatDateTime }}
                </p>
                <div style="margin-left: 30px">
                  <i :title="event.withUsers">
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'applicantprofile',
                        params: { slug: event.slug },
                      }"
                      class="link-class"
                      >{{ event.title | truncate(30, "..") }}</router-link
                    >
                  </i>
                </div>
              </div>
              <h5 class="mt-4">
                Pending C. Events - ({{ pendingCampusEvents.length }})
              </h5>
              <div
                class="schedule-app-list"
                v-for="(event, index) in pendingCampusEvents"
                :key="index"
              >
                <span
                  style="margin-top: 3px"
                  class="event-circle"
                  :title="event.round_name"
                  >{{ event.round }}</span
                >
                <b>
                  {{ formatCampusDate(event.round_schedule) }} -
                  {{ formatCampusDate(event.round_schedule_end) }}
                </b>
                <div
                  v-if="
                    hasAccess({
                      permissions: [PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU],
                      checkAll: false,
                    })
                  "
                >
                  <i>
                    <span
                      style="margin-top: 3px"
                      class="event-circle event-type-circle"
                      :title="'Campus Event'"
                      >C</span
                    >
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'scheduleEventSlot',
                        params: { eventId: event.id },
                      }"
                      class="link-class"
                    >
                      {{ event.title | truncate(30, "..") }}</router-link
                    >
                  </i>
                </div>
                <div v-else>
                  <i>
                    <span
                      style="margin-top: 3px"
                      class="event-circle event-type-circle"
                      :title="'Campus Event'"
                      >C</span
                    >{{ event.title | truncate(30, "..") }}</i
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center">
            <h5 class="m-0">No Pending Events</h5>
          </div>
        </div>
      </div>
      <div class="applicant-schedule parent-class d-flex">
        <div v-if="!isSmallScreen" class="schedule-sidebar-parent p-0">
          <div
            class="applicant-schedule-sidebar mr-3 rounded d-flex flex-column"
          >
            <div class="applicant-schedule-sidebar-section">
              <div class="col-12">
                <input
                  v-if="$refs.calendar"
                  id="includeWeekends"
                  type="checkbox"
                  class="form-check-input"
                  :checked="
                    $refs['calendar']
                      ? $refs['calendar'].defaultCalendarOptions.weekends
                      : true
                  "
                  @change="weekendToggle"
                />
                <label for="includeWeekends"> Include Weekends</label>
              </div>
              <div class="col-12">
                <input
                  id="showAll"
                  v-if="$refs.calendar"
                  type="checkbox"
                  class="form-check-input"
                  :checked="
                    $refs['calendar']
                      ? !$refs['calendar'].defaultCalendarOptions.dayMaxEvents
                      : true
                  "
                  @change="dayToggle"
                />
                <label for="showAll"> Show All Events</label>
              </div>
            </div>
            <div class="applicant-schedule-sidebar-section">
              <h5>Filters</h5>
              <div class="col-12 p-0">
                <label htmlFor="events">Include Events</label><br />
                <select
                  v-model="selectedType"
                  class="form-control"
                  @change="filterEvents"
                >
                  <option value="">All Events</option>
                  <option value="Aptitude">Aptitude Only</option>
                  <option value="Written Test">Written Test Only</option>
                  <option value="Interview">Interviews Only</option>
                  <option value="Review">Reviews Only</option>
                </select>
              </div>
            </div>
            <div
              class="applicant-schedule-sidebar-section"
              style="min-height: 0"
              v-if="pendingEvents.length > 0 || pendingCampusEvents.length > 0"
            >
              <div class="pending-events-section">
                <h5>Pending A. Events - ({{ pendingEvents.length }})</h5>
                <div
                  class="mb-2"
                  v-for="event in pendingEvents"
                  :key="event.id"
                >
                  <span
                    style="margin-top: 3px"
                    class="event-circle"
                    :title="event.roundName"
                  >
                    {{ event.round }}
                  </span>
                  <p class="mb-0 font-weight-bold">
                    {{ event.start | formatDateTime }}
                  </p>
                  <div style="margin-left: 30px">
                    <i :title="event.withUsers">
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'applicantprofile',
                          params: { slug: event.slug },
                        }"
                        class="link-class"
                        >{{ event.title | truncate(30, "..") }}</router-link
                      >
                    </i>
                  </div>
                </div>
                <h5 class="mt-4">
                  Pending C. Events - ({{ pendingCampusEvents.length }})
                </h5>
                <div
                  class="schedule-app-list"
                  v-for="(event, index) in pendingCampusEvents"
                  :key="index"
                >
                  <span
                    style="margin-top: 3px"
                    class="event-circle"
                    :title="event.round_name"
                    >{{ event.round }}</span
                  >
                  <b>
                    {{ formatCampusDate(event.round_schedule) }} -
                    {{ formatCampusDate(event.round_schedule_end) }}
                  </b>
                  <div
                    v-if="
                      hasAccess({
                        permissions: [
                          PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                        ],
                        checkAll: false,
                      })
                    "
                  >
                    <i>
                      <span
                        style="margin-top: 3px"
                        class="event-circle event-type-circle"
                        :title="'Campus Event'"
                        >C</span
                      >
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'scheduleEventSlot',
                          params: { eventId: event.id },
                        }"
                        class="link-class"
                      >
                        {{ event.title | truncate(30, "..") }}</router-link
                      >
                    </i>
                  </div>
                  <div v-else>
                    <i>
                      <span
                        style="margin-top: 3px"
                        class="event-circle event-type-circle"
                        :title="'Campus Event'"
                        >C</span
                      >{{ event.title | truncate(30, "..") }}</i
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <common-calendar
          class="rounded border p-2"
          ref="calendar"
          :is-calendar-view="isCalendarView"
          :calendarOption="calendarOptions"
          :filterObj="filters"
          :api="apiCall"
          :setEventsandUsers="setEventsandUsers"
          :setStyle="{ fontSize: toolbarFontSize }"
          :classes="'fullcalendar-header-style-md'"
          :onEventClick="handleCalendarClick"
          :filters="{ setTableButton: true }"
        ></common-calendar>
      </div>

      <b-modal
        ref="eventModal"
        :title="eventModalData.modalTitle"
        :size="eventModalData.modalSize"
        :hide-footer="eventModalData.hideFooter"
      >
        <EventModal
          :isEdit="eventModalData.isEdit"
          v-on:generateRound="generateRound($event)"
          v-on:updateEvent="updateEvent($event)"
          :editEventApplicant="editEventApplicantData"
        />
      </b-modal>
      <b-modal
        ref="campusEventModal"
        :title="eventModalData.modalTitle"
        :size="eventModalData.modalSize"
        :hide-footer="eventModalData.hideFooter"
      >
        <CampusEventModal
          :campusSchedule="false"
          v-on:closeEventModal="closeEventModal"
          :isEdit="eventModalData.isEdit"
          :selectedDate="selectedDate"
          :editEvent="editEvent"
          v-on:generateRound="generateRound($event)"
          v-on:updateEvent="updateEvent($event)"
        />
      </b-modal>
      <edit-round-modal
        v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.UPDATE] })"
        :showApplicantName="true"
        :applicantData="this.applicantData"
      />
      <common-modal
        :id="commonModalData.id"
        :modal-title="commonModalData.title"
        :modal-body="commonModalData.body"
        :show-cancel="commonModalData.showCancel"
        :modal-color="commonModalData.color"
        :modal-size-class="commonModalData.sizeClass"
        @actionPerformed="emitOnCurrent"
      />
      <schedule-round-modal
        v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.CREATE] })"
        :applicant="applicantData"
        :calendarDateTime="selectedDate"
        :inPlaceAction="true"
      />
    </div>
  </div>
</template>
<script>
import campusRecruitmentApi from "../../CampusRecruitment/_api/index";
import apiApplicantProfile from "../../ApplicantProfile/_api";
import applicantApi from "../../Applications/_api/index";
import EventModal from "../../Applications/_components/event-modal.vue";
import CommonModal from "../../Common/_components/modal.vue";
import scheduleRoundModal from "../../Applications/_components/schedule-round-modal.vue";
import EditRoundModal from "../../ApplicantProfile/_components/edit-round-modal.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import CampusEventModal from "../../CampusRecruitment/_components/event-modal.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import CommonCalendar from "../../Common/_components/common-calendar.vue";

export default {
  components: {
    EventModal,
    CommonModal,
    scheduleRoundModal,
    EditRoundModal,
    // AgGridVue,
    CampusEventModal,
    CommonCalendar,
  },
  data: function () {
    return {
      isSmallScreen: false,
      myRounds: true,
      ovserver: null,
      users: [],
      campusEvents: [],
      editEvent: null,
      selectedType: "",
      calendarOptions: {
        height: "100%",
        select: this.handleDateSelect,
      },
      claimedByMe: false,
      pendingEvents: [],
      pendingCampusEvents: [],
      allEvents: [],
      eventModalData: {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      },
      applicantData: null,
      selectedDate: null,
      isCalendarView: true,
      editEventApplicantData: null,
      currentDate: new Date(),
      rowSelection: null,
      columnDefs: [
        {
          headerName: "Round",
          field: "roundName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Candidate",
          field: "title",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Status",
          field: "status",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Position",
          field: "position",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Time",
          field: "start",
          valueFormatter: this.formatDate,
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            comparator: function (filterDate, cellValue) {
              const formattedDate = moment(cellValue).format("DD/MM/YY");
              const filterText = moment(filterDate).format("DD/MM/YY");
              return formattedDate.includes(filterText) ? 0 : 1;
            },
          },
        },
        {
          headerName: "Interviewer/Reviewer",
          field: "interviewers",
          filter: "agTextColumnFilter",
        },
      ],
    };
  },
  created() {
    this.rowSelection = "single";
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      applicant_Profile_Data: "$_applicant_profile/fetchApplicantData",
      applicantRoundsData: "$_applicant_profile/fetchRoundDetails",
      confirmRoundScheduleStatus:
        "$_applicants_data/fetchConfirmRoundScheduleStatus",
      loggedInUser: "user/loggedInUser",
    }),
    toolbarFontSize() {
      return window.innerWidth <= 650 ? "60%" : "";
    },
    filters() {
      return {
        round: this.selectedType,
        myRounds: this.myRounds,
        isValid: true,
      };
    },
    apiCall() {
      return applicantApi.fetchAllSchedule;
    },
    weekendToggle() {
      return this.$refs.calendar.handleWeekendsToggle;
    },
    dayToggle() {
      return this.$refs.calendar.handleDayMaxEventsToggle;
    },
  },
  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth < 768; // Example breakpoint for small screens
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    closeEventModal() {
      this.$refs["campusEventModal"].hide();
    },
    handleCalendarClick(event, type) {
      if (type === "event") this.handleEventClick(event);
      if (type === "campus") this.handleCampusEventClick(event);
    },
    /**
     * @memberof module:CampusRecruitment.components.campus_schedule
     * @description handle click event and open edit event modal
     */
    handleCampusEventClick(clickInfo) {
      this.editEvent = null;
      const foundEvent = this.campusEvents.find((event) => {
        return event.id == clickInfo.event.id;
      });
      this.editEvent = foundEvent;
      this.$store.dispatch("$_campus_recruitment/resetSelectedCampus");
      const filter = "?campusId=" + clickInfo.event.extendedProps.campusId;
      campusRecruitmentApi
        .fetchCampuses(filter)
        .then((res) => {
          this.$store.dispatch(
            "$_campus_recruitment/setSelectedCampus",
            res.data[0]
          );
          this.eventModalData = {
            modalTitle: "Edit Event",
            modalSize: "xl",
            hideFooter: true,
            isEdit: true,
          };
          this.$refs["campusEventModal"].show();
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem retrieving data!",
            },
            { root: true }
          );
        });
    },
    toggleView() {
      this.isCalendarView = !this.isCalendarView;
    },
    handleDateSelect(selectInfo) {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.eventModalData = {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      };
      const selectDate = new Date(selectInfo.startStr).setHours(0, 0, 0, 0);
      const currentDate = new Date().setHours(0, 0, 0, 0);
      if (selectDate < currentDate) {
        // show invalid modal
        this.openDialog(
          "invalidEventModal",
          "Invalid Input",
          "Please select a future date & time for the event!",
          false,
          "danger",
          "modal-md"
        );
        return;
      }
      this.selectedDate = selectInfo;
      this.$refs["eventModal"].show();
    },

    tabularCreateEvent() {
      this.eventModalData = {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      };
      this.$refs["eventModal"].show();
    },
    tabularEventClick(data) {
      if (data.node.data.status !== "Pending") {
        return;
      }

      this.eventModalData = {
        modalTitle: "Edit Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };

      apiApplicantProfile
        .fetchApplicantData(data.node.data.slug)
        .then((response) => {
          this.editEventApplicantData = response.data;
        });
      this.$refs["eventModal"].show();
    },
    handleEventClick(clickInfo) {
      if (clickInfo.event.extendedProps.status !== "Pending") {
        return;
      }

      this.eventModalData = {
        modalTitle: "Edit Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };

      apiApplicantProfile
        .fetchApplicantData(clickInfo.event.extendedProps.slug)
        .then((response) => {
          this.editEventApplicantData = response.data;
        });
      this.$refs["eventModal"].show();
    },
    generateRound(applicant) {
      this.$refs["eventModal"].hide();
      this.applicantData = applicant;
      this.selectedDate = $("#addRound").modal("show");
    },
    updateEvent(applicant) {
      this.$refs["eventModal"].hide();
      this.applicantData = applicant;
      this.selectedDate = $("#editRound").modal("show");
    },
    formatDate(date) {
      if (date.value === null) return null;
      else if (date.value) return moment(date.value).format("llll");
      else return moment(date).format("llll");
    },
    formatCampusDate(dateString) {
      return moment(dateString).format("DD/MMM/YY");
    },
    filterEvents() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },
    setEventsandUsers({
      users,
      campusEvents,
      pendingApplicantEvents,
      pendingCampusEvents,
    }) {
      this.users = users;
      this.campusEvents = campusEvents;
      this.pendingEvents = pendingApplicantEvents;
      this.pendingCampusEvents = pendingCampusEvents;
    },
    getMoodleInstances() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"]["MOODLE"].MENU],
        })
      )
        return;
      this.$store.dispatch("$_applicant_profile/getMoodleInstanceList");
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantsData.components.applicant_schedule
     * @param {String} value
     * @description Checks whether round has been added successfully or not then call filter event
     */
    confirmRoundScheduleStatus(value) {
      if (value === "SUCCESS") {
        this.filterEvents(null, null);
      }
    },
  },
  mounted() {
    this.getMoodleInstances();
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.title-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.schedule-app-list {
  margin: 1em 0;
  padding: 0;
}

.schedule-app {
  display: flex;
  min-height: 35rem;
}

.schedule-sidebar-parent {
  width: 350px;
  height: 100%;
  padding: 10px;
}
.schedule-app-sidebar {
  height: 100%;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 5px;
}

.btn-margin {
  margin-top: 12px;
}

.fa-table,
.fa-calendar {
  background-color: #8f1452;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  margin-right: 3px;
}

.fa-angle-left {
  background-color: #2c3e50;
  font-size: 22px;
  padding: 0.8vw 1.5vw;
  color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.fa-angle-left:hover {
  background-color: #2e3941;
}

.fa-angle-right {
  background-color: #2c3e50;
  font-size: 22px;
  padding: 0.8vw 1.5vw;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -4px;
}

.fa-angle-right:hover {
  background-color: #2e3941;
}
.schedule-app-main {
  margin-left: 310px;
  flex-grow: 1;
  padding: 10px 0px 0px 20px;
  height: 100%;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  margin: 0 auto;
  text-transform: capitalize !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}
::v-deep .fc-event {
  margin-top: 4px;
}

.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  /* position: relative; */
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-type-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: gray;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-side-div {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
.applicant-schedule {
  width: 100%;
  padding: 0px;
}
.applicant-schedule-sidebar {
  height: 100%;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.applicant-schedule-sidebar-section {
  padding: 5px;
}
.pending-events-section {
  overflow: auto;
  height: 100%;
  margin: 10px 0px;
}

.parent-class {
  min-height: 500px;
  height: calc(100vh - 135px);
}

/* MEDIA QUERY FOR MEDIUM(MD) SCREEN */
@media screen and (max-width: 767px) {
  .schedule-app-calendar .fc-toolbar .fc-header-toolbar {
    font-size: 60% !important;
  }
  .pending-events-section {
    height: 17vh;
  }
  .parent-class {
    min-height: 500px;
    height: calc(100vh - 187px);
  }
}

/* MEDIA QUERY FOR SMALL(SM) SCREEN */
@media screen and (max-width: 576px) {
  .parent-class {
    min-height: 360px;
    /* height: calc(100vh - 187px); */
  }
}

/* MEDIA QUERY FOR SMALLER SCREEN */
@media screen and (max-width: 250px) {
  .parent-class {
    min-height: 200px;
    height: calc(100vh - 235px);
  }
}
</style>
