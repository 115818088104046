var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"calendarParent",staticStyle:{"flex-grow":"1"}},[_c('FullCalendar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCalendarView ? _vm.isCalendarView : true),expression:"isCalendarView ? isCalendarView : true"}],ref:"calendar",staticClass:"fullcalendar schedule-app-calendar",class:_vm.classes,style:(_vm.setStyle),attrs:{"id":"calendar","name":"calendar","options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [_c('div',{staticStyle:{"width":"100%"}},[(arg.event.extendedProps.eventType === 'applicantEvent')?_c('div',{class:{
            'event-title-inactive':
              arg.event.extendedProps.status != 'Pending',
          },staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.handleOnEventClick(arg, 'event')}}},[_c('div',[_c('b',{staticClass:"event-time"},[_vm._v(_vm._s(arg.timeText))]),_c('span',{staticClass:"event-circle",class:{
                'event-circle-inactive':
                  arg.event.extendedProps.status != 'Pending',
              },attrs:{"title":arg.event.extendedProps.roundName}},[_vm._v(" "+_vm._s(arg.event.extendedProps.round)+" ")])]),_c('div',{staticClass:"d-flex justify-content-between"},[(arg.event.extendedProps.status !== 'Pending')?_c('i',{staticClass:"title-overflow",attrs:{"title":arg.event.extendedProps.withUsers}},[_c('router-link',{staticClass:"link-class",attrs:{"target":"_blank","to":{
                  name: 'applicantprofile',
                  params: { slug: arg.event.extendedProps.slug },
                }}},[_vm._v(" "+_vm._s(arg.event.title)+" ")])],1):_c('i',{staticClass:"title-overflow",attrs:{"title":arg.event.extendedProps.withUsers}},[_vm._v(" "+_vm._s(arg.event.title)+" ")]),(arg.view.type == 'listMonth')?_c('span',[_vm._v(" "+_vm._s(arg.event.extendedProps.interviewers)+" ")]):_vm._e()])]):(_vm.filters.isSlot)?_c('div',{staticClass:"w-100 slot-div",attrs:{"data-toggle":"tooltip","data-placement":"top","title":"go to event slot"},on:{"click":function($event){return _vm.handleOnEventClick(arg, 'campus')}}},[_c('div',{staticClass:"d-flex",class:{
              'event-title-inactive':
                arg.event.extendedProps.status != 'Pending' ||
                !_vm.scheduleDateValid(arg.event.extendedProps.to_time),
            },staticStyle:{"width":"100%"}},[_c('div',{staticClass:"right-div d-flex justify-content-between pr-1"},[_c('div',{staticClass:"event-time px-1",attrs:{"data-toggle":"tooltip","data-placement":"top","title":arg.event.start}},[_vm._v(" "+_vm._s(_vm.extractTime(arg.event.extendedProps.from_time))+" ")]),_c('div',{staticClass:"event-time",attrs:{"data-toggle":"tooltip","data-placement":"top","title":arg.event.start}},[_vm._v(" "+_vm._s(_vm.extractTime(arg.event.extendedProps.to_time))+" ")])])])]):_c('div',{staticClass:"w-100",staticStyle:{"overflow":"hidden"},on:{"click":function($event){return _vm.handleOnEventClick(arg, 'campus')}}},[_c('div',{staticClass:"d-flex justify-content-between",class:{
              'event-title-inactive':
                arg.event.extendedProps.status != 'Pending' ||
                !_vm.scheduleDateValid(
                  arg.event.extendedProps.round_schedule_end
                ),
            },staticStyle:{"width":"100%"}},[_c('div',{staticClass:"left-side-div"},[_c('span',{staticClass:"event-circle span-circle",class:{
                  'event-circle-inactive':
                    arg.event.extendedProps.status != 'Pending' ||
                    !_vm.scheduleDateValid(
                      arg.event.extendedProps.round_schedule_end
                    ),
                },attrs:{"title":arg.event.extendedProps.round_name}},[_vm._v(" "+_vm._s(arg.event.extendedProps.round)+" ")]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},attrs:{"title":arg.event.extendedProps.withUsers}},[_vm._v(" "+_vm._s(arg.event.title)+" ")])]),_c('div',{staticClass:"d-flex"},[(arg.view.type == 'listMonth')?_c('div',[_vm._v(" "+_vm._s(arg.event.extendedProps.interviewers)+" ")]):_vm._e(),(arg.view.type != 'listMonth')?_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.extractTime(arg.event.extendedProps.round_schedule))+" ")]):_vm._e()])])])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }