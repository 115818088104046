<template>
  <div class="content">
    <common-header :data="['home', 'campusDrive', 'campusSchedules']" />
    <div class="container-fluid">
      <div
        v-if="isSmallScreen"
        class="d-flex w-100 justify-content-between mb-3"
      >
        <button
          class="btn btn-primary d-md-none"
          type="button"
          data-toggle="collapse"
          data-target="#collapseFilters"
          aria-expanded="false"
          aria-controls="collapseFilters"
          style="background-color: #8f1452; color: white; border: #8f1452"
        >
          Filters
        </button>
        <button
          class="btn btn-primary"
          type="button"
          data-toggle="collapse"
          data-target="#collapsePendingEvents"
          aria-expanded="false"
          aria-controls="collapsePendingEvents"
          style="background-color: #8f1452; color: white; border: #8f1452"
        >
          Pending Events
        </button>
      </div>
      <div class="collapse d-md-none w-100 mb-2" id="collapseFilters">
        <div class="card card-body">
          <div class="campus-schedule-sidebar-section row pl-3">
            <div class="col-12 col-sm-6 p-1 pl-4">
              <input
                v-if="$refs.calendar"
                type="checkbox"
                class="form-check-input"
                :checked="
                  $refs.calendar
                    ? $refs.calendar.defaultCalendarOptions.weekends
                    : false
                "
                @change="weekendsToggle"
              />
              Include Weekends
            </div>
            <div class="col-12 col-sm-6 p-1 pl-4">
              <input
                v-if="$refs.calendar"
                type="checkbox"
                class="form-check-input"
                :checked="
                  $refs['calendar']
                    ? !$refs['calendar'].defaultCalendarOptions.dayMaxEvents
                    : true
                "
                @change="dayToggle"
              />
              Show All Events
            </div>
          </div>
          <div class="campus-schedule-sidebar-section">
            <h4>Filters</h4>
            <div class="col-12 p-0 mb-2">
              <label htmlFor="round">Include Round Events</label><br />
              <select
                v-model="selectedType"
                class="form-control"
                @change="filterEvents"
              >
                <option value="">All Round Events</option>
                <option value="Written Test">Written Test</option>
                <option value="Interview">Interview</option>
              </select>
            </div>
            <div class="col-12 p-0">
              <label htmlFor="includeStatus">Include status Events</label><br />
              <select
                v-model="status"
                class="form-control"
                @change="filterEvents"
              >
                <option value="">All Status Events</option>
                <option value="Pending">Pending Only</option>
                <option value="Completed">Completed Only</option>
                <option value="Cancelled">Cancelled Only</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse d-md-none w-100 mb-2" id="collapsePendingEvents">
        <div class="card card-body p-2">
          <div
            class="campus-schedule-sidebar-section"
            v-if="pendingEvents.length > 0"
          >
            <div class="pending-events-section">
              <h5>Pending Events - ({{ pendingEvents.length }})</h5>
              <div
                class=""
                v-for="(event, index) in pendingEvents"
                :key="index"
              >
                <span
                  style="margin-top: 3px"
                  class="event-circle"
                  :title="event.round_name"
                  >{{ event.round }}</span
                ><b>
                  {{ formatDate(event.round_schedule) }} -
                  {{ formatDate(event.round_schedule_end) }} </b
                ><br />
                <div
                  style="margin-left: 30px"
                  v-if="
                    hasAccess({
                      permissions: [PERMISSIONS['CAMPUS-DRIVE'].SLOT.READ],
                    })
                  "
                >
                  <i>
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'scheduleEventSlot',
                        params: { eventId: event.id },
                      }"
                      class="link-class"
                    >
                      {{ event.title | truncate(30, "..") }}</router-link
                    >
                  </i>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center">
            <h5 class="m-0">No Pending Events</h5>
          </div>
        </div>
      </div>
      <div class="d-flex mx-0 parent-class">
        <div v-if="!isSmallScreen" class="campus-schedule-sidebar-parent p-0">
          <div class="campus-schedule-sidebar mr-3 rounded d-flex flex-column">
            <div class="campus-schedule-sidebar-section">
              <div class="col-12 p-1 pl-4">
                <input
                  v-if="$refs.calendar"
                  type="checkbox"
                  class="form-check-input"
                  :checked="
                    $refs.calendar
                      ? $refs.calendar.defaultCalendarOptions.weekends
                      : false
                  "
                  @change="weekendsToggle"
                />
                Include Weekends
              </div>
              <div class="col-12 p-1 pl-4">
                <input
                  v-if="$refs.calendar"
                  type="checkbox"
                  class="form-check-input"
                  :checked="
                    $refs['calendar']
                      ? !$refs['calendar'].defaultCalendarOptions.dayMaxEvents
                      : false
                  "
                  @change="dayToggle"
                />
                Show All Events
              </div>
            </div>
            <div class="campus-schedule-sidebar-section">
              <h4>Filters</h4>
              <div class="col-12 p-0 mb-2">
                <label htmlFor="includeRound">Include Round Events</label><br />
                <select
                  v-model="selectedType"
                  class="form-control"
                  @change="filterEvents"
                >
                  <option value="">All Round Events</option>
                  <option value="Written Test">Written Test</option>
                  <option value="Interview">Interview</option>
                </select>
              </div>
              <div class="col-12 p-0">
                <label htmlFor="includeStatus">Include status Events</label
                ><br />
                <select
                  v-model="status"
                  class="form-control"
                  @change="filterEvents"
                >
                  <option value="">All Status Events</option>
                  <option value="Pending">Pending Only</option>
                  <option value="Completed">Completed Only</option>
                  <option value="Cancelled">Cancelled Only</option>
                </select>
              </div>
            </div>
            <div
              style="flex-grow: 1; min-height: 0"
              class="campus-schedule-sidebar-section"
              v-if="pendingEvents.length > 0 || true"
            >
              <div class="pending-events-section">
                <h5>Pending Events - ({{ pendingEvents.length }})</h5>
                <div
                  class=""
                  v-for="(event, index) in pendingEvents"
                  :key="index"
                >
                  <span
                    style="margin-top: 3px"
                    class="event-circle"
                    :title="event.round_name"
                    >{{ event.round }}</span
                  ><b>
                    {{ formatDate(event.round_schedule) }} -
                    {{ formatDate(event.round_schedule_end) }} </b
                  ><br />
                  <div
                    style="margin-left: 30px"
                    v-if="
                      hasAccess({
                        permissions: [PERMISSIONS['CAMPUS-DRIVE'].SLOT.READ],
                      })
                    "
                  >
                    <i>
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'scheduleEventSlot',
                          params: { eventId: event.id },
                        }"
                        class="link-class"
                      >
                        {{ event.title | truncate(30, "..") }}</router-link
                      >
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <common-calendar
          class="border rounded p-2"
          ref="calendar"
          :filterObj="filters"
          :api="apiCall"
          :calendarOption="calendarOptions"
          :classes="'fullcalendar-header-style-md'"
          :setStyle="{ fontSize: toolbarFontSize }"
          :onEventClick="handleEventClick"
          :setEventsandUsers="setEventsandUsers"
          :filters="{ setTableButton: true }"
        ></common-calendar>
      </div>
    </div>

    <b-modal
      ref="eventModal"
      :title="eventModalData.modalTitle"
      :size="eventModalData.modalSize"
      :hide-footer="eventModalData.hideFooter"
    >
      <EventModal
        v-on:closeEventModal="closeEventModal"
        :isEdit="eventModalData.isEdit"
        :selectedDate="selectedDate"
        :editEvent="editEvent"
        v-on:generateRound="generateRound($event)"
        v-on:updateEvent="updateEvent($event)"
      />
    </b-modal>
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
    <schedule-event-modal
      v-if="
        hasAccess({
          permissions: [
            PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.CREATE,
            PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.UPDATE,
          ],
          checkAll: false,
        })
      "
      v-on:refreshEvents="refreshEvents"
      :editEvent="editEvent"
      :calendarDateTime="selectedDate"
      ref="scheduleRoundModel"
      :inPlaceAction="true"
    />
  </div>
</template>
<script>
import api from "../_api";
import { mapGetters } from "vuex";
import CommonModal from "../../Common/_components/modal.vue";
import EventModal from "./event-modal.vue";
import scheduleEventModal from "./schedule-event-modal.vue";
import moment from "moment";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import CommonCalendar from "../../Common/_components/common-calendar.vue";

export default {
  components: {
    CommonModal,
    EventModal,
    scheduleEventModal,
    CommonCalendar,
  },
  data: function () {
    return {
      isSmallScreen: false,
      ovserver: null,
      editEvent: null,
      events: null,
      pendingEvents: [],
      selectedType: "",
      status: "",
      calendarOptions: {
        height: "100%",
        select: this.handleDateSelect,
        dateClick: (selectedInfo) => {
          this.handleDateSelect(selectedInfo, true);
        },
      },
      eventModalData: {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      },
      selectedDate: null,
      editEventData: null,
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"]["SCHEDULE"];
    },

    ...mapGetters({
      activeCampuses: "$_campus_recruitment/getActiveCampuses",
    }),
    toolbarFontSize() {
      return window.innerWidth <= 650 ? "60%" : "";
    },

    filters() {
      return {
        status: this.status,
        round: this.selectedType,
        isCampus: true,
        isValid: true,
      };
    },
    apiCall() {
      return api.fetchRoundSchedules;
    },
    weekendsToggle() {
      return this.$refs.calendar.handleWeekendsToggle;
    },
    dayToggle() {
      return this.$refs.calendar.handleDayMaxEventsToggle;
    },
  },

  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth < 768; // Example breakpoint for small screens
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:CampusRecruitment.components.campus-schedule
     * @description format date to display
     */
    formatDate(dateString) {
      return moment(dateString).format("DD/MMM/YY");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-schedule
     * @description close event modal and fetch events
     */
    closeEventModal() {
      this.$refs["eventModal"].hide();
      this.refreshEvents();
    },

    scheduleDateValid(date) {
      return moment(date).isAfter(new Date());
    },

    extractTime(value) {
      return moment(value).format("h:mm A");
    },
    /**
     * @memberof module:CampusRecruitment.components.campus-schedule
     * @description check if last is over compare to current date
     */
    checkLastDateOver(last_date) {
      const currentDate = moment();
      return moment(last_date).isBefore(currentDate);
    },

    /**
     * @memberof module:CampusRecruitment.components.campus_schedule
     * @description select date range
     */
    handleDateSelect(selectInfo, forPhone) {
      if (forPhone && window.innerWidth >= 768) return;
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.CREATE] }))
        return;
      this.editEvent = null;
      const selectDate = new Date(selectInfo.startStr).setHours(0, 0, 0, 0);
      const currentDate = new Date().setHours(0, 0, 0, 0);
      this.$store.dispatch("$_campus_recruitment/resetSelectedCampus");
      this.eventModalData = {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      };
      if (selectDate < currentDate) {
        // show invalid modal
        this.openDialog(
          "invalidEventModal",
          "Invalid Input",
          "Please select a future date & time for the event!",
          false,
          "danger",
          "modal-md",
          true
        )
          .then(() => {})
          .catch(() => {});
        return;
      }
      const filter = "?status=inProgress";
      this.$store.dispatch("$_campus_recruitment/fetchActiveCampuses", filter);
      this.selectedDate = selectInfo;
      this.$refs["eventModal"].show();
    },

    /**
     * @memberof module:CampusRecruitment.components.campus_schedule
     * @description handle click event and open edit event modal
     */
    handleEventClick(clickInfo) {
      this.editEvent = null;
      const foundEvent = this.events.find((event) => {
        return event.id == clickInfo.event.id;
      });
      this.editEvent = foundEvent;
      this.$store.dispatch("$_campus_recruitment/resetSelectedCampus");
      const filter = "?campusId=" + clickInfo.event.extendedProps.campusId;
      api
        .fetchCampuses(filter)
        .then((res) => {
          this.$store.dispatch(
            "$_campus_recruitment/setSelectedCampus",
            res.data[0]
          );
          this.eventModalData = {
            modalTitle: "Edit Event",
            modalSize: "xl",
            hideFooter: true,
            isEdit: true,
          };
          this.$refs["eventModal"].show();
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem retrieving data!",
            },
            { root: true }
          );
        });
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-schedule
     * @description open event modal to generate
     */
    generateRound() {
      $("#addEvent").modal("show");
      this.$refs["eventModal"].hide();
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-schedule
     * @description open event round modal to update
     */
    updateEvent() {
      this.$refs["eventModal"].hide();
      $("#addEvent").modal("show");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus_schedule
     * @description refresh list of events
     */
    refreshEvents() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
      const filter = "?status=inProgress";
      this.$store.dispatch("$_campus_recruitment/fetchActiveCampuses", filter);
    },

    setEventsandUsers({ allEvents, pendingCampusEvents }) {
      this.pendingEvents = pendingCampusEvents;
      this.events = allEvents;
    },

    /**
     * @memberof module:CampusRecruitment.components.campus_schedule
     * @description filter event by round type
     */
    filterEvents() {
      let calendarApi = this.$refs.calendar.$children[0].getApi();
      setTimeout(() => {
        calendarApi.refetchEvents();
      }, 10);
    },

    fetchActiveRecruitments() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.CREATE],
        })
      )
        return;
      const filter = "?status=inProgress";
      this.$store.dispatch("$_campus_recruitment/fetchActiveCampuses", filter);
    },
  },

  watch: {
    /**
     * @memberof module:CampusRecruitment.components.campus_schedule
     * @param {String} value
     * @description Checks whether round has been added successfully or not then call filter event
     */
    confirmRoundScheduleStatus(value) {
      if (value === "SUCCESS") {
        this.filterEvents(null, null);
      }
    },
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },

  created() {
    this.fetchActiveRecruitments();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
/* .content {
  margin-top: 0px;
  padding-top: 80px;
} */

.schedule-app-list {
  margin: 0 0;
  padding: 0;
}

.schedule-app {
  display: flex;
  min-height: 100%;
}

.schedule-app-sidebar {
  max-height: 100%;
  width: 300px;
  line-height: 1.5;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  margin-left: 20px;
  margin-top: 0px;
}

.schedule-app-sidebar-section {
  padding: 0.5em;
}

.schedule-app-sidebar-section h2 {
  font-size: 24px;
  font-weight: 400;
}

.schedule-app-sidebar-section label {
  font-weight: 600;
  font-size: 14px;
}

.schedule-app-sidebar-section .btn {
  height: 38px;
}

@media screen and (max-width: 991px) {
  .schedule-app-sidebar-section .btn {
    padding: 8px 12px;
  }
}

.schedule-app-sidebar-section a:hover {
  color: #8f1452;
}

.schedule-app-sidebar-section input:focus {
  box-shadow: none;
  border-color: #8f1452;
}

.schedule-app-sidebar-section .multiselect {
  border: 1px solid #ced4da;
}

.schedule-app-sidebar-section .multiselect--active,
.schedule-app-sidebar-section select:focus {
  box-shadow: none;
  border-color: #8f1452;
}

.schedule-app-main {
  flex-grow: 1;
  padding: 1.5em;
  padding-top: 0px;
  margin-left: 320px;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  margin: 0 auto;
  text-transform: capitalize !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}

.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.span-circle {
  min-width: 20px;
  min-height: 20px;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-div {
  width: calc(100% - 30px);
}

.campus-schedule {
  width: 100%;
  padding: 20px 23px;
}

.campus-schedule-sidebar-parent {
  width: 350px;
  height: 100%;
  padding: 10px;
}
.campus-schedule-sidebar {
  height: 100%;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.campus-schedule-sidebar-section {
  padding: 5px;
}

.pending-events-section {
  overflow: auto;
  margin: 10px 0px;
  height: 100%;
}

.parent-class {
  min-height: 480px;
  height: calc(100vh - 135px);
}
::v-deep .fc-event {
  margin-top: 4px;
}

/* MEDIA QUERY FOR MEDIUM SCREEN */
@media screen and (max-width: 768px) {
  .parent-class {
    height: calc(100vh - 195px);
  }
}

/* MEDIA QUERY FOR SMALL SCREEN */
@media screen and (max-width: 324px) {
  .parent-class {
    height: calc(100vh - 220px);
  }
}

/* MEDIA QUERY FOR SMALLER SCREEN */
@media screen and (max-width: 235px) {
  .parent-class {
    min-height: 200px;
    height: calc(100vh - 250px);
  }
}
</style>
