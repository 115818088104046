<template>
  <div :id="modalId"></div>
</template>

<script>
/* eslint-disable no-undef*/
import axios from "axios";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../permissions";
import { EMOJIS } from "../commonData";
let users = [];
export default {
  props: {
    modalId: [String],
    width: {
      type: String,
      default: "450",
    },
    height: {
      type: String,
      default: "170",
    },
    minHeight: {
      type: String,
      default: "170",
    },
    placeholder: [String],
    haveMentions: {
      type: Boolean,
      default: false,
    },
    updateCode: {
      type: String,
      default: "",
    },
    mentions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
  },
  mounted() {
    if (!this.haveMentions) {
      this.initSummerNote();
    } else {
      // emoji
      window.emojis = Object.keys(EMOJIS);
      window.emojiUrls = EMOJIS;
      // Users
      this.getUsers();
    }
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    getUsers() {
      axios.get("users").then((res) => {
        res.data.forEach((emp) => {
          users.push(`${emp.first_name} ${emp.last_name}`);
        });
        this.initSummerNote();
      });
    },
    /**
     * @memberof module:ManageJob.components.edit_job_modal
     * @description method - initialize summernote
     */
    initSummerNote() {
      $("#" + this.modalId).summernote({
        width: this.width,
        height: this.height,
        minHeight: this.minHeight,
        placeholder: this.placeholder,
        dialogsInBody: true,
        dialogsFade: true,
        fontNames: [
          "Arial",
          "Comic Sans MS",
          "Courier New",
          "Helvetica",
          "Lucida Console",
          "Times New Roman",
          "Roboto",
        ],
        toolbar: [
          ["style", ["bold", "italic", "underline", "clear"]],
          ["font", ["fontname"]],
          ["fontsize", ["fontsize"]],
          ["color", ["color"]],
          ["para", ["style", "ul", "ol", "paragraph"]],
          ["insert", ["table", "picture", "video", "link", "hr"]],
          ["misc", ["fullscreen", "undo", "redo", "help"]],
        ],
        //  mention and emoji hint
        hint: [
          {
            mentions: this.haveMentions ? users : this.mentions,
            match: /\B@(\w*)$/,
            search: function (keyword, callback) {
              callback(
                $.grep(this.mentions, function (item) {
                  return item.toLowerCase().indexOf(keyword.toLowerCase()) == 0;
                })
              );
            },
            content: function (item) {
              return "@" + item;
            },
          },
          {
            match: /:([-+\w]+)$/,
            search: function (keyword, callback) {
              callback(
                $.grep(emojis, function (item) {
                  return item.indexOf(keyword) === 0;
                })
              );
            },
            template: function (item) {
              let content = emojiUrls[item];
              return '<img src="' + content + '" width="20" /> :' + item + ":";
            },
            content: function (item) {
              let url = emojiUrls[item];
              if (url) {
                return $("<img />").attr("src", url).css("width", 20)[0];
              }
              return "";
            },
          },
          {
            match: /(\w+)\$/,
            search: (keyword, callback) => {
              callback(
                $.grep(placeholders, function (item) {
                  return item.indexOf(keyword) === 0;
                })
              );
            },
            template: function (item) {
              return item;
            },
            content: function (item) {
              return "~" + item + "~";
            },
          },
        ],
        callbacks: {
          onKeyup: () => {
            this.$emit("onKeyUp", $("#" + this.modalId).summernote("code"));
          },
          onBlur: () => {
            this.$emit("onBlur", $("#" + this.modalId).summernote("code"));
          },
          // eslint-disable-next-line no-unused-vars
          onChange: (contents, $editable) => {
            this.$emit("onChange", $("#" + this.modalId).summernote("code"));
          },
        },
      });
      $("#" + this.modalId).summernote("code", this.updateCode);
      this.$emit("callBack");
    },
    /**
     * @memberof module:Common._components.common-rich-text-box.Reset
     * @description method - Reset summernote
     */
    resetSummerNote() {
      $("#" + this.modalId).summernote("reset");
    },
  },
};
</script>
