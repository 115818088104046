<template>
  <div style="flex-grow: 1" ref="calendarParent">
    <FullCalendar
      v-show="isCalendarView ? isCalendarView : true"
      id="calendar"
      name="calendar"
      ref="calendar"
      class="fullcalendar schedule-app-calendar"
      :class="classes"
      :options="calendarOptions"
      :style="setStyle"
    >
      <template v-slot:eventContent="arg">
        <div style="width: 100%">
          <div
            style="width: 100%"
            :class="{
              'event-title-inactive':
                arg.event.extendedProps.status != 'Pending',
            }"
            @click="handleOnEventClick(arg, 'event')"
            v-if="arg.event.extendedProps.eventType === 'applicantEvent'"
          >
            <div>
              <b class="event-time">{{ arg.timeText }}</b>
              <span
                class="event-circle"
                :title="arg.event.extendedProps.roundName"
                :class="{
                  'event-circle-inactive':
                    arg.event.extendedProps.status != 'Pending',
                }"
              >
                {{ arg.event.extendedProps.round }}
              </span>
            </div>
            <div class="d-flex justify-content-between">
              <i
                class="title-overflow"
                v-if="arg.event.extendedProps.status !== 'Pending'"
                :title="arg.event.extendedProps.withUsers"
              >
                <router-link
                  target="_blank"
                  :to="{
                    name: 'applicantprofile',
                    params: { slug: arg.event.extendedProps.slug },
                  }"
                  class="link-class"
                >
                  {{ arg.event.title }}
                </router-link>
              </i>
              <i
                class="title-overflow"
                v-else
                :title="arg.event.extendedProps.withUsers"
              >
                {{ arg.event.title }}
              </i>
              <span v-if="arg.view.type == 'listMonth'">
                {{ arg.event.extendedProps.interviewers }}
              </span>
            </div>
          </div>
          <div
            data-toggle="tooltip"
            data-placement="top"
            title="go to event slot"
            class="w-100 slot-div"
            @click="handleOnEventClick(arg, 'campus')"
            v-else-if="filters.isSlot"
          >
            <div
              style="width: 100%"
              class="d-flex"
              :class="{
                'event-title-inactive':
                  arg.event.extendedProps.status != 'Pending' ||
                  !scheduleDateValid(arg.event.extendedProps.to_time),
              }"
            >
              <div class="right-div d-flex justify-content-between pr-1">
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="arg.event.start"
                  class="event-time px-1"
                >
                  {{ extractTime(arg.event.extendedProps.from_time) }}
                </div>
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="arg.event.start"
                  class="event-time"
                >
                  {{ extractTime(arg.event.extendedProps.to_time) }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-100"
            style="overflow: hidden"
            @click="handleOnEventClick(arg, 'campus')"
            v-else
          >
            <div
              style="width: 100%"
              class="d-flex justify-content-between"
              :class="{
                'event-title-inactive':
                  arg.event.extendedProps.status != 'Pending' ||
                  !scheduleDateValid(
                    arg.event.extendedProps.round_schedule_end
                  ),
              }"
            >
              <div class="left-side-div">
                <span
                  class="event-circle span-circle"
                  :title="arg.event.extendedProps.round_name"
                  :class="{
                    'event-circle-inactive':
                      arg.event.extendedProps.status != 'Pending' ||
                      !scheduleDateValid(
                        arg.event.extendedProps.round_schedule_end
                      ),
                  }"
                >
                  {{ arg.event.extendedProps.round }}
                </span>
                <span
                  :title="arg.event.extendedProps.withUsers"
                  class="font-weight-bold"
                  style="cursor: pointer"
                >
                  {{ arg.event.title }}
                </span>
              </div>

              <div class="d-flex">
                <div v-if="arg.view.type == 'listMonth'">
                  {{ arg.event.extendedProps.interviewers }}
                </div>
                <div class="mr-1" v-if="arg.view.type != 'listMonth'">
                  {{ extractTime(arg.event.extendedProps.round_schedule) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import moment from "moment";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  components: {
    FullCalendar,
  },
  props: {
    calendarOption: {},
    isCalendarView: {},
    setStyle: {},
    classes: {},
    onEventClick: {},
    api: {},
    filterObj: {},
    setEventsandUsers: {},
    campusEvent: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultCalendarOptions: {
        customButtons: {
          weekends: {
            text: "weekends",
            hint: "toggle weekends",
            click: () => {
              this.handleWeekendsToggle();
            },
          },
          viewAll: {
            text: "view all",
            hint: "toggle view all",
            click: () => {
              this.handleDayMaxEventsToggle();
            },
          },
        },
        editable: false,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        buttonText: {
          list: "table",
        },
        selectable: true,
        selectMirror: true,
        lazyFetching: false,
        eventSources: [
          {
            events: this.fetchEvents,
          },
        ],
        initialView: "dayGridMonth",
        dayMaxEvents: true,
        weekends: false,
      },
    };
  },
  computed: {
    calendarOptions() {
      if (!this.filters.setTableButton)
        delete this.defaultCalendarOptions.buttonText;
      if (!this.filters.setCustomButtons)
        delete this.defaultCalendarOptions.customButtons;
      return { ...this.defaultCalendarOptions, ...this.calendarOption };
    },
  },
  methods: {
    handleOnEventClick(arg, type) {
      this.onEventClick && this.onEventClick(arg, type);
    },
    extractTime(value) {
      return moment(value).format("h:mm A");
    },
    scheduleDateValid(date) {
      return moment(date).isAfter(new Date());
    },
    checkLastDateOver(last_date) {
      const currentDate = moment();
      return moment(last_date).isBefore(currentDate);
    },
    handleWeekendsToggle() {
      this.defaultCalendarOptions.weekends =
        !this.defaultCalendarOptions.weekends;
    },
    handleDayMaxEventsToggle() {
      this.defaultCalendarOptions.dayMaxEvents =
        !this.defaultCalendarOptions.dayMaxEvents; // update a property
    },
    handleSlotData(calendarApi, response) {
      const events = response.data.map((slot) => {
        let slotData = {
          ...slot,
          eventType: "slotEvent",
          start: slot.from_time,
          end: slot.to_time,
          eventId: slot.roundId,
          slotId: slot.id,
          backgroundColor: this.getSlotEventColor(slot.to_time),
          borderColor: this.getSlotEventColor(slot.to_time),
        };
        if (calendarApi && calendarApi.view.type != "dayGridMonth") {
          return {
            ...slotData,
            startRecur: moment(new Date(slot.from_time)).format("YYYY-MM-DD"),
            endRecur: moment(new Date(slot.to_time))
              .add(1, "days")
              .format("YYYY-MM-DD"),
            startTime: moment(new Date(slot.from_time)).format("HH:mm"),
            endTime: moment(new Date(slot.to_time)).format("HH:mm"),
            roundName: slot.round_name,
            allDay: false,
          };
        } else {
          return {
            ...slotData,
            allDay: true,
          };
        }
      });
      return events;
    },

    handleEventData(calendarApi, events, interviewerId) {
      let users = [];
      let allEvents = [];
      events.forEach((event) => {
        let userNames = [];
        event.interviewers &&
          event.interviewers.forEach((user) => {
            if (user.interviewer_name) {
              const userExists = interviewerId.some(
                (userId) => userId == user.interviewer_id
              );
              if (!userExists) {
                users.push({
                  id: user.interviewer_id,
                  name: user.interviewer_name,
                });
                interviewerId.push(user.interviewer_id);
              }
              userNames.push(user.interviewer_name);
            }
          });
        const allDayEvents = ["dayGridWeek", "dayGridMonth"];
        let eventData = {
          ...event,
          eventType: "campusEvent",
          title:
            event.campusDetail.recruitment_session_name +
            " - " +
            event.round_title,
          start: event.round_schedule,
          end: event.round_schedule_end,
          round: this.getTitleAbbreviation(event.round_name),
          interviewers: userNames.join(", "),
          withUsers: this.getCampusWithUsers(
            event.round_title,
            event.round_name,
            event.status,
            event.campusDetail.recruitment_session_name,
            userNames.join(", ")
          ),
          roundName: event.round_name,
          backgroundColor: this.getCampusEventColor(
            event.status,
            event.round_schedule_end
          ),
          borderColor: this.getCampusEventColor(
            event.status,
            event.round_schedule_end
          ),
          allDay: false,
        };
        const interviewIds = event.slotsInvolved.flatMap(
          (event) => event.interviewerId
        );
        if (
          !this.filters.selectedUsers ||
          !this.filters.selectedUsers.length ||
          this.hasCommonElement(
            interviewIds,
            this.filters.selectedUsers.map((user) => user.id)
          )
        ) {
          if (calendarApi && allDayEvents.includes(calendarApi.view.type)) {
            allEvents.push({
              ...eventData,
              allDay: moment(event.round_schedule).isSame(
                event.round_schedule_end,
                "day"
              ),
            });
          } else {
            allEvents.push({
              ...eventData,
              startRecur: moment(new Date(event.round_schedule)).format(
                "YYYY-MM-DD"
              ),
              endRecur: moment(new Date(event.round_schedule_end))
                .add(1, "days")
                .format("YYYY-MM-DD"),
              startTime: moment(new Date(event.round_schedule)).format("HH:mm"),
              endTime: moment(new Date(event.round_schedule_end)).format(
                "HH:mm"
              ),
              backgroundColor: this.getCampusEventColor(
                event.status,
                event.round_schedule_end
              ),
              borderColor: this.getCampusEventColor(
                event.status,
                event.round_schedule_end
              ),
            });
          }
        }
      });
      return { events: allEvents, users };
    },

    fetchEvents(config, successCallback, failureCallback) {
      let filters = {
        startDate: config.start,
        endDate: config.end,
        ...this.filterObj,
      };
      if (this.filterObj.isValid) {
        this.api(filters)
          .then((response) => {
            let interviewerId = [];
            let allEvents = [];
            let pendingApplicantEvents = [];
            let users = [];
            let pendingCampusEvents = [];
            let campusEvents = [];
            let calendarApi = this.$refs.calendar.getApi();
            if (this.filters.isSlot) {
              allEvents = this.handleSlotData(calendarApi, response);
            } else if (
              this.filterObj.isCampus ||
              this.filterObj.isRecruiter ||
              this.filterObj.entityId
            ) {
              const { events, users: eventUsers } = this.handleEventData(
                calendarApi,
                response.data,
                interviewerId
              );
              allEvents = events;
              users = [...users, ...eventUsers];
              pendingCampusEvents = allEvents.filter((event) => {
                return (
                  event.status == "Pending" &&
                  !this.checkLastDateOver(event.round_schedule_end)
                );
              });
            } else {
              let {
                pendingEvents: appPendingEvent,
                allEvents: appAllEvents,
                users: appUsers,
              } = this.handleApplicantEvents(
                response.data.applicantRounds,
                interviewerId
              );
              pendingApplicantEvents = [
                ...pendingApplicantEvents,
                ...appPendingEvent,
              ];
              allEvents = [...allEvents, ...appAllEvents];
              users = [...users, ...appUsers];

              let { events, users: eventUsers } = this.handleEventData(
                calendarApi,
                response.data.campusRounds,
                interviewerId
              );
              campusEvents = events;
              users = [...users, ...eventUsers];
              pendingCampusEvents = campusEvents.filter((event) => {
                return (
                  event.status == "Pending" &&
                  !this.checkLastDateOver(event.round_schedule_end)
                );
              });
              allEvents = [...allEvents, ...campusEvents];
            }
            if (this.setEventsandUsers)
              this.setEventsandUsers({
                users,
                campusEvents,
                pendingApplicantEvents,
                pendingCampusEvents,
                allEvents,
              });
            successCallback(allEvents);
          })
          .catch(() => {
            failureCallback([]);
          });
      }
    },
    handleApplicantEvents(events, interviewerId) {
      let pendingEvents = [];
      let allEvents = [];
      let users = [];
      events.forEach((event) => {
        let userIds = [];
        let userNames = [];
        event.interviewers.forEach((user) => {
          if (user.interviewer_name) {
            const userExists = interviewerId.some(
              (userId) => userId === user.interviewer_id
            );
            if (!userExists) {
              users.push({
                id: user.interviewer_id,
                name: user.interviewer_name,
              });
              interviewerId.push(user.interviewer_id);
            }
            userIds.push(user.interviewer_id);
            userNames.push(user.interviewer_name);
          }
        });
        event.reviewers.forEach((user) => {
          if (user.reviewer_name) {
            const userExists = interviewerId.some(
              (userId) => userId === user.reviewer_id
            );
            if (!userExists) {
              users.push({
                id: user.reviewer_id,
                name: user.reviewer_name,
              });
              interviewerId.push(user.reviewer_id);
            }
            userIds.push(user.reviewer_id);
            userNames.push(user.reviewer_name);
          }
        });
        let calEvent = {
          eventType: "applicantEvent",
          id: event.id,
          slug: event.applicantIterationMaster.slug,
          title:
            event.applicantIterationMaster.first_name +
            " " +
            event.applicantIterationMaster.last_name,
          withUsers: this.getWithUsers(
            event.round_name,
            event.round_result,
            userNames.toString(),
            event.applicantIterationMaster.position
          ),
          interviewers: userNames.toString(),
          start: event.round_schedule,
          status: event.round_result,
          position: event.applicantIterationMaster.position,
          users: userIds,
          round: this.getTitleAbbreviation(event.round_name),
          roundName: event.round_name,
          allDay: false,
          backgroundColor: this.getEventColor(event.round_result),
          borderColor: this.getEventColor(event.round_result),
        };
        if (event.round_result === "Pending") {
          pendingEvents.push(calEvent);
        }
        this.transformUsers(users);
        if (
          this.filters.selectedUsers &&
          this.filters.selectedUsers.length > 0
        ) {
          if (calEvent.users.length > 0) {
            this.filters.selectedUsers.every((selectedUser) => {
              let result = calEvent.users.find((id) => id === selectedUser.id);
              if (result) {
                allEvents.push(calEvent);
                return false;
              } else {
                return true;
              }
            });
          }
        } else {
          allEvents.push(calEvent);
        }
      });
      return { pendingEvents, allEvents, users };
    },
    getTitleAbbreviation(roundName) {
      let abbreviation = "I";
      if (roundName === "Review") {
        abbreviation = "R";
      } else if (roundName === "Aptitude") {
        abbreviation = "A";
      } else if (roundName === "Written Test") {
        abbreviation = "W";
      }
      return abbreviation;
    },
    hasCommonElement(arrayA, arrayB) {
      return arrayA.some((element) => arrayB.includes(element));
    },
    getWithUsers(roundName, roundStatus, withUsers, position) {
      let message = "";
      if (roundName === "Review") {
        message =
          "Reviewer/s: " +
          withUsers.toString() +
          " \nStatus: " +
          roundStatus +
          " \nPosition: " +
          position;
      } else if (roundName === "Interview") {
        message =
          "Interviewer/s: " +
          withUsers.toString() +
          " \nStatus: " +
          roundStatus +
          " \nPosition: " +
          position;
      } else {
        message = "Status: " + roundStatus + "\nPosition: " + position;
      }

      return message;
    },
    getCampusWithUsers(
      eventTitle,
      eventName,
      eventStatus,
      recruitmentName,
      interviewers,
      fromDate,
      toDate
    ) {
      let message = "";
      if (eventName === "Written Test") {
        message =
          "Title: " +
          eventTitle +
          " \nType: " +
          eventName +
          " \nRecruitmentName: " +
          recruitmentName +
          "\nScheduler/s: " +
          interviewers.toString() +
          " \nStatus: " +
          eventStatus;
      } else if (eventName === "Interview") {
        message =
          "Title: " +
          eventTitle +
          " \nType: " +
          eventName +
          " \nRecruitmentName: " +
          recruitmentName +
          "\nInterviewer/s: " +
          interviewers.toString() +
          " \nStatus: " +
          eventStatus;
      } else {
        message = "Status: " + eventStatus;
      }
      message =
        message +
        "\nfromTime: " +
        moment(fromDate).format("YYYY-MM-DD HH:mm:ss") +
        "\ntoTime: " +
        moment(toDate).format("YYYY-MM-DD HH:mm:ss");
      return message;
    },
    getCampusEventColor(roundStatus, end_time) {
      let color = "line";
      if (roundStatus === "Cancelled") {
        color = "#FEE6AF";
      } else if (
        roundStatus === "Pending" &&
        end_time &&
        !this.scheduleDateValid(end_time)
      ) {
        color = "grey";
      } else if (roundStatus === "Pending") {
        color = "green";
      }
      return color;
    },
    getEventColor(roundStatus) {
      let color = "green";
      if (roundStatus === "Failed" || roundStatus === "Differed") {
        color = "orange";
      } else if (roundStatus === "Cancelled") {
        color = "grey";
      } else if (roundStatus === "Pending") {
        color = "lime";
      }
      return color;
    },
    getSlotEventColor(end_time) {
      let color = "line";
      if (!moment(end_time).isAfter(new Date())) {
        return "grey";
      }
      return color;
    },
    transformUsers(users) {
      users = users.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      users.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    onResize() {
      let calendarApi = this.$refs?.calendar?.getApi();
      if (calendarApi) calendarApi.updateSize();
    },
  },
  mounted() {
    this.ovserver = new ResizeObserver(this.onResize);
    this.ovserver.observe(this.$refs.calendarParent);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.title-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.schedule-app-list {
  margin: 1em 0;
  padding: 0;
}

.schedule-app {
  display: flex;
  min-height: 35rem;
}

.schedule-sidebar-parent {
  width: 350px;
  height: 100%;
  padding: 10px;
}
.schedule-app-sidebar {
  height: 100%;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 5px;
}

.btn-margin {
  margin-top: 12px;
}

.fa-table,
.fa-calendar {
  background-color: #8f1452;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  margin-right: 3px;
}

.fa-angle-left {
  background-color: #2c3e50;
  font-size: 22px;
  padding: 0.8vw 1.5vw;
  color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.fa-angle-left:hover {
  background-color: #2e3941;
}

.fa-angle-right {
  background-color: #2c3e50;
  font-size: 22px;
  padding: 0.8vw 1.5vw;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -4px;
}

.fa-angle-right:hover {
  background-color: #2e3941;
}
.schedule-app-main {
  margin-left: 310px;
  flex-grow: 1;
  padding: 10px 0px 0px 20px;
  height: 100%;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  margin: 0 auto;
  text-transform: capitalize !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}
::v-deep .fc-event {
  margin-top: 4px;
}

.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  /* position: relative; */
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-type-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: gray;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-side-div {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
.applicant-schedule {
  width: 100%;
  padding: 0px;
}
.applicant-schedule-sidebar {
  height: 100%;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.applicant-schedule-sidebar-section {
  padding: 5px;
}
.pending-events-section {
  overflow: auto;
  height: 100%;
  margin: 10px 0px;
}

.parent-class {
  min-height: 500px;
  height: calc(100vh - 135px);
}

/* MEDIA QUERY FOR MEDIUM(MD) SCREEN */
@media screen and (max-width: 767px) {
  .schedule-app-calendar .fc-toolbar .fc-header-toolbar {
    font-size: 60% !important;
  }
  .pending-events-section {
    height: 17vh;
  }
  .parent-class {
    min-height: 500px;
    height: calc(100vh - 187px);
  }
}

/* MEDIA QUERY FOR SMALL(SM) SCREEN */
@media screen and (max-width: 576px) {
  .parent-class {
    min-height: 360px;
    /* height: calc(100vh - 187px); */
  }
}

/* MEDIA QUERY FOR SMALLER SCREEN */
@media screen and (max-width: 250px) {
  .parent-class {
    min-height: 200px;
    height: calc(100vh - 235px);
  }
}

/* .content {
  margin-top: 0px;
  padding-top: 80px;
} */

.schedule-app-list {
  margin: 0 0;
  padding: 0;
}

.schedule-app {
  display: flex;
  min-height: 100%;
}

.schedule-app-sidebar {
  max-height: 100%;
  width: 300px;
  line-height: 1.5;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  margin-left: 20px;
  margin-top: 0px;
}

.schedule-app-sidebar-section {
  padding: 0.5em;
}

.schedule-app-sidebar-section h2 {
  font-size: 24px;
  font-weight: 400;
}

.schedule-app-sidebar-section label {
  font-weight: 600;
  font-size: 14px;
}

.schedule-app-sidebar-section .btn {
  height: 38px;
}

@media screen and (max-width: 991px) {
  .schedule-app-sidebar-section .btn {
    padding: 8px 12px;
  }
}
</style>
