<template>
  <div
    class="modal fade"
    id="selectToBeMailed"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Mail Recepient</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group">
              <label class="col-sm-3 control-label"
                >To be Mailed -
                <span
                  class="text-danger"
                  v-if="
                    flag !== 'ProcessApplicant' && flag !== 'DifferApplicant'
                  "
                  >*</span
                ></label
              >
              <label class="checkbox-inline mr-3">
                <input
                  type="checkbox"
                  value="Coordinator"
                  v-model="isCoordinator"
                />
                Coordinator
              </label>
              <label class="checkbox-inline mr-3">
                <input type="checkbox" value="Students" v-model="isStudent" />
                Applicants
              </label>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center" v-if="selectedCampusApplicants">
          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'SendMail'"
            class="btn btn-cp ml-2"
            @click="sendCampusMail"
            data-dismiss="modal"
          >
            Send Mail
          </button>

          <button
            v-if="flag === 'ProcessApplicant'"
            type="button"
            class="btn btn-cp br-32"
            @click="processCampusRoundAndMail"
            data-dismiss="modal"
          >
            {{
              isCoordinator == false && isStudent == false
                ? "Process Round"
                : "Process Round and Mail"
            }}
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'ShortlistApplicant'"
            class="btn btn-success"
            @click="shortlistCampusRoundAndMail"
            data-dismiss="modal"
          >
            Shortlist and Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'JoinedApplicant'"
            class="btn btn-secondary br-32"
            @click="joinedCampus"
            data-dismiss="modal"
          >
            Joined and Mail
          </button>

          <button
            type="button"
            v-if="flag === 'DifferApplicant'"
            class="btn btn-cp br-32"
            @click="differCampusApplicantsAndMail"
            data-dismiss="modal"
          >
            {{
              isCoordinator == false && isStudent == false
                ? "Differ"
                : "Differ and Mail"
            }}
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'HoldApplicant'"
            class="btn btn-warning br-32"
            @click="holdCampus"
            data-dismiss="modal"
          >
            Hold and Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'SetNotAppeared'"
            class="btn btn-info br-32"
            @click="notAppearedRoundAndMail"
            data-dismiss="modal"
          >
            Not Appeared and Mail
          </button>

          <button
            :disabled="isCoordinator == false && isStudent == false"
            type="button"
            v-if="flag === 'MoveToTalentPool'"
            class="btn btn-dark br-32"
            @click="moveTalentPoolCampus"
            data-dismiss="modal"
          >
            Move Talent Pool and Mail
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import api from "../_api/index";
/**
 * @memberOf module:ApplicantsData
 * @namespace components.select_to_be_mailed
 * @description Schedule round modal component
 */
export default {
  props: ["selectedCampusApplicants", "flag", "refreshParentPage"],
  data() {
    return {
      isCoordinator: false,
      isStudent: false,
      finalCampusMails: [],
      applicationStatus: null,
    };
  },
  computed: {
    ...mapGetters({
      campusMails: "$_applicants_data/fetchCampusMails",
    }),
  },
  methods: {
    /**
     * @memberOf module:ApplicantsData.components.select_to_be_mailed
     * @description process round of multiple applicants belonging to campus
     */
    processCampusRoundAndMail() {
      let dataToBeUpdated = [];
      this.selectedCampusApplicants.forEach((element) => {
        dataToBeUpdated.push({
          id: element.id,
          application_status: "Processed",
        });
      });
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      if (key) {
        this.$store.dispatch("$_applicants_data/processCampusApplicant", {
          data: {
            dataToBeUpdated: dataToBeUpdated,
            selectedApplicants: this.selectedCampusApplicants,
            campusMails: this.campusMails,
            status: key,
          },
          redirect: this.$route.fullPath,
        });
      } else {
        if (this.refreshParentPage) {
          api
            .shortlistApplicants(dataToBeUpdated)
            .then(() => {
              this.refreshParentPage();
              this.$store.dispatch(
                "openSnackbar",
                {
                  type: "success",
                  message: "Applicant Processed Successfully!",
                },
                { root: true }
              );
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      }
    },

    /**
     * @memberOf module:ApplicantsData.components.select_to_be_mailed
     * @description Shortlist multiple applicants belonging to campus and change their main application status to Shortlisted and mail them
     */
    shortlistCampusRoundAndMail() {
      let dataToBeUpdated = [];
      this.selectedCampusApplicants.forEach((element) => {
        dataToBeUpdated.push({
          id: element.id,
          application_status: "Shortlisted",
        });
      });
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      this.$store.dispatch("$_applicants_data/shortlistCampusApplicants", {
        data: {
          dataToBeUpdated: dataToBeUpdated,
          selectedApplicants: this.selectedCampusApplicants,
          campusMails: this.campusMails,
          status: key,
        },
        redirect: this.$route.fullPath,
      });
    },

    /**
     * @memberOf module:ApplicantsData.components.select_to_be_mailed
     * @description Cancel round of multiple applicants belonging to campus
     */
    cancelCampusRoundAndMail() {
      let dataToBeUpdated = [];
      this.selectedCampusApplicants.forEach((element) => {
        dataToBeUpdated.push({
          id: element.id,
          application_status: element.application_status.replace(
            "Scheduled",
            "Cancelled"
          ),
        });
      });
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      this.$store.dispatch("$_applicants_data/cancelCampusLastRound", {
        data: {
          dataToBeUpdated: dataToBeUpdated,
          selectedApplicants: this.selectedCampusApplicants,
          campusMails: this.campusMails,
          status: key,
        },
        redirect: this.$route.fullPath,
      });
    },

    /**
     * @memberOf module:ApplicantsData.components.select_to_be_mailed
     * @description Differ multiple applicants belonging to campus and change their main application status to Differed and mail them
     */
    differCampusApplicantsAndMail() {
      let dataToBeUpdated = [];
      this.selectedCampusApplicants.forEach((element) => {
        dataToBeUpdated.push({
          id: element.id,
          application_status: "Differed",
        });
      });
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      if (key) {
        this.$store.dispatch("$_applicants_data/differCampusApplicants", {
          data: {
            dataToBeUpdated: dataToBeUpdated,
            selectedApplicants: this.selectedCampusApplicants,
            campusMails: this.campusMails,
            status: key,
          },
          redirect: this.$route.fullPath,
        });
      } else {
        if (this.refreshParentPage) {
          api
            .differApplicants(dataToBeUpdated)
            .then(() => {
              this.refreshParentPage();
              this.$store.dispatch(
                "openSnackbar",
                {
                  type: "success",
                  message: "Applicant Differed successfully!",
                },
                { root: true }
              );
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      }
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Move to joined applicant belonging to campus
     */
    joinedCampus() {
      let dataToBeUpdated = [];
      this.selectedCampusApplicants.forEach((element) => {
        dataToBeUpdated.push({
          id: element.id,
          application_status: "Joined",
        });
      });
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      this.$store.dispatch("$_applicants_data/joinedCampusApplicants", {
        data: {
          dataToBeUpdated: dataToBeUpdated,
          selectedApplicants: this.selectedCampusApplicants,
          campusMails: this.campusMails,
          status: key,
        },
        redirect: this.$route.fullPath,
      });
    },
    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Put on not appeared applicant belonging to campus
     */
    notAppearedRoundAndMail() {
      let dataToBeUpdated = [];
      this.selectedCampusApplicants.forEach((element) => {
        dataToBeUpdated.push({
          id: element.id,
          application_status: "Not Appeared",
        });
      });
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      this.$store.dispatch("$_applicants_data/notAppearedRound", {
        data: {
          dataToBeUpdated: dataToBeUpdated,
          selectedApplicants: this.selectedCampusApplicants,
          campusMails: this.campusMails,
          status: key,
        },
        redirect: this.$route.fullPath,
      });
    },

    /**
     * @memberOf module:ApplicantProfile.components.to_be_mailed
     * @description Move to talent pool applicant belonging to campus
     */
    moveTalentPoolCampus() {
      let dataToBeUpdated = [];
      this.selectedCampusApplicants.forEach((element) => {
        dataToBeUpdated.push({
          id: element.id,
          application_status: "Talent Pool",
        });
      });
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      this.$store.dispatch(
        "$_applicants_data/moveCampusApplicantsToTalentPool",
        {
          data: {
            dataToBeUpdated: dataToBeUpdated,
            selectedApplicants: this.selectedCampusApplicants,
            campusMails: this.campusMails,
            status: key,
          },
          redirect: this.$route.fullPath,
        }
      );
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantsData.components.select_to_be_mailed
     * @param {Array} value
     * @description Fetch Co-ordinator emails as soon as applicant is selected from grid
     */
    selectedCampusApplicants(value) {
      if (value[0] && value[0].belongsToCampusRecruitement) {
        this.$store.dispatch(
          "$_applicants_data/fetchCampusEmails",
          value[0].belongsToCampusRecruitement.id
        );
      }
    },
  },
};
</script>
