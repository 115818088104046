var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[(_vm.applicantRoundsData)?_c('div',[_c('div',{staticClass:"well section-head mb-1 w-auto"},[_c('div',{staticClass:"row mx-0"},[_vm._m(0),(_vm.applicantRoundsData)?_c('div',{staticClass:"col-sm-6 col-12 px-0"},[(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.ROUND.CREATE] }))?_c('button',{staticClass:"btn btn-cp float-right",attrs:{"role":"button","data-toggle":"modal","data-target":"#addRound","data-backdrop":"static","data-keyboard":"false","disabled":!(
                _vm.applicantRoundsData?.length === 0 ||
                (_vm.applicantRoundsData[_vm.applicantRoundsData.length - 1]
                  .round_result !== 'Pending' &&
                  _vm.applicantRoundsData.length > 0)
              ) || _vm.applicantDataForConflict?.hasDuplicate?.length > 0}},[_c('i',{staticClass:"fa fa-plus fa-lg",attrs:{"aria-hidden":"true"}}),_c('strong',[_vm._v("Generate Round")])]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"p-0",attrs:{"id":"rounds"}},[_c('div',{staticClass:"row mx-0"},_vm._l((_vm.applicantRoundsData),function(round,index){return _c('div',{key:index,staticClass:"col-md-6 col-12"},[_c('div',{class:[
              round.round_result !== 'Cancelled' ? 'well' : 'cancelled',
              'container',
            ]},[_c('h4',[_c('strong',[_vm._v(_vm._s(round.round_name))]),_vm._v("  "),(
                  round.round_result === 'Passed' ||
                  round.round_result === 'Processed'
                )?[_c('em',{staticClass:"fa fa-thumbs-up fa-lg"})]:_vm._e(),(
                  round.round_result === 'Failed' ||
                  round.round_result === 'Differed'
                )?[_c('em',{staticClass:"fa fa-thumbs-down fa-lg"})]:_vm._e(),(round.round_result === 'Cancelled')?[_c('em',{staticClass:"fa fa-ban"})]:_vm._e(),(round.round_result === 'Pending')?[_c('em',{staticClass:"fa fa-hourglass-half fa-md"})]:_vm._e(),_vm._v("      "),_c('div',{staticStyle:{"float":"right","padding-bottom":"1.5%"}},[(
                    _vm.hasAccess({
                      permissions: [
                        _vm.BASE_PERMISSION.ROUND.UPDATE,
                        _vm.PERMISSIONS.MAIL.CREATE,
                      ],
                    })
                  )?_c('button',{staticClass:"btn btn-cp mr-1",attrs:{"disabled":round.isMailed ||
                    index !== _vm.applicantRoundsData.length - 1 ||
                    (index == _vm.applicantRoundsData.length - 1 &&
                      round.round_result !== 'Pending' &&
                      index == _vm.applicantRoundsData.length - 1 &&
                      round.round_result !== 'Cancelled')},on:{"click":function($event){return _vm.sendMail(index)}}},[_c('em',{staticClass:"fa fa-envelope"}),_vm._v("  "),(!round.isMailed)?[_vm._v(" Send Mail ")]:_vm._e(),(round.isMailed)?[_vm._v(" Mail Sent ")]:_vm._e()],2):_vm._e(),(
                    round.round_result === 'Pending' &&
                    round.round_name != 'Review'
                  )?[_c('div',{staticClass:"btn-group btn-group-xs"},[(
                        round.eventSlot &&
                        round.eventSlot.interviewerId.includes(
                          parseInt(_vm.loggedInUser.user_id)
                        )
                      )?[(
                          round.round_name == 'Aptitude' ||
                          round.round_name == 'Written Test'
                        )?_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.fetchMoodleScore(round.id)}}},[_c('em',{staticClass:"fa fa-refresh",attrs:{"aria-hidden":"true"}}),_vm._v(" Sync ")]):_vm._e()]:_vm._e(),(
                        _vm.hasAccess({
                          permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE],
                        }) ||
                        (round.interviewers &&
                          round.interviewers.some(
                            (interview) =>
                              interview.interviewer_id == _vm.loggedInUser.user_id
                          )) ||
                        ((!round.interviewers ||
                          !round.interviewers.length) &&
                          round.eventSlot &&
                          round.eventSlot.interviewerId.includes(
                            parseInt(_vm.loggedInUser.user_id)
                          ))
                      )?_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.interviewResult(
                          index,
                          round.round_name,
                          round.interviewers,
                          'Passed',
                          round.id
                        )}}},[_c('em',{staticClass:"fa fa-check"}),_vm._v(" Qualify ")]):_vm._e(),(
                        _vm.hasAccess({
                          permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE],
                        }) ||
                        (round.interviewers &&
                          round.interviewers.some(
                            (interview) =>
                              interview.interviewer_id == _vm.loggedInUser.user_id
                          )) ||
                        ((!round.interviewers ||
                          !round.interviewers.length) &&
                          round.eventSlot &&
                          round.eventSlot.interviewerId.includes(
                            parseInt(_vm.loggedInUser.user_id)
                          ))
                      )?_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.interviewResult(
                          index,
                          round.round_name,
                          round.interviewers,
                          'Failed',
                          round.id
                        )}}},[_c('em',{staticClass:"fa fa-times"}),_vm._v(" Differ ")]):_vm._e()],2),_vm._v("   "),(
                      _vm.hasAccess({
                        permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE],
                      })
                    )?[_c('a',{attrs:{"role":"button","data-toggle":"modal","data-target":"#editRound","data-backdrop":"static","data-keyboard":"false"},on:{"click":function($event){return _vm.setModalData(round)}}},[_c('em',{staticClass:"fa fa-edit fa-lg",attrs:{"aria-hidden":"true"}})])]:_vm._e()]:_vm._e(),(
                    round.round_result === 'Pending' &&
                    round.round_name == 'Review'
                  )?[_c('div',{staticClass:"btn-group btn-group-xs"},[(
                        _vm.hasAccess({
                          permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE],
                        }) ||
                        round.reviewers.some(
                          (review) =>
                            review.reviewer_id == _vm.loggedInUser.user_id
                        )
                      )?_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.reviewResult(
                          index,
                          round.round_name,
                          round.reviewers,
                          'Processed',
                          round.id
                        )}}},[_c('em',{staticClass:"fa fa-check"}),_vm._v(" Processed ")]):_vm._e(),(
                        _vm.hasAccess({
                          permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE],
                        }) ||
                        round.reviewers.some(
                          (review) =>
                            review.reviewer_id == _vm.loggedInUser.user_id
                        )
                      )?_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.reviewResult(
                          index,
                          round.round_name,
                          round.reviewers,
                          'Differed',
                          round.id
                        )}}},[_c('em',{staticClass:"fa fa-times"}),_vm._v(" Differed ")]):_vm._e()]),_vm._v("   "),(
                      _vm.hasAccess({
                        permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE],
                      })
                    )?[_c('a',{attrs:{"role":"button","data-toggle":"modal","data-target":"#editRound","data-backdrop":"static","data-keyboard":"false"},on:{"click":function($event){return _vm.setModalData(round)}}},[_c('em',{staticClass:"fa fa-edit fa-lg",attrs:{"aria-hidden":"true"}})])]:_vm._e()]:_vm._e()],2),(
                  round.round_result !== 'Pending' &&
                  index == _vm.applicantRoundsData.length - 1 &&
                  _vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE] })
                )?[_c('div',{staticStyle:{"float":"right","padding-bottom":"1.5%"}},[_c('button',{staticClass:"btn btn-cp",attrs:{"type":"button"},on:{"click":function($event){return _vm.revertResult(index)}}},[_c('em',{staticClass:"fa fa-undo"}),_vm._v(" Revert ")]),_vm._v("   ")])]:_vm._e()],2),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-condensed",attrs:{"aria-describedby":"roundInfo"}},[_c('tbody',[_c('tr',[_vm._m(1,true),_c('td',[_vm._v(_vm._s(_vm._f("dateTimeFormat")(round.round_schedule)))])]),(
                      round.hasMoodle && round.hasMoodle.moodle_user_master
                    )?_c('tr',[_vm._m(2,true),_c('td',[_vm._v(" "+_vm._s(round.hasMoodle.moodle_user_master.moodle_username)+" ")])]):_vm._e(),(
                      round.hasMoodle && round.hasMoodle.moodle_user_master
                    )?_c('tr',[_vm._m(3,true),_c('td',[_vm._v(" "+_vm._s(round.hasMoodle.moodle_user_master.moodle_password)+" ")])]):_vm._e(),_c('tr',[_vm._m(4,true),_c('td',[_vm._v(_vm._s(round.created_by))])]),(round.score)?_c('tr',[_vm._m(5,true),_c('td',[_vm._v(_vm._s(round.score)+"/"+_vm._s(round.outOf))])]):_vm._e(),(round.grade)?_c('tr',[_vm._m(6,true),_c('td',[_vm._v(_vm._s(round.grade))])]):_vm._e(),(round.comments)?_c('tr',[_vm._m(7,true),_c('safe-content',{attrs:{"content":round.comments,"tag":"td"}})],1):_vm._e(),_c('tr',[_vm._m(8,true),_c('td',[_vm._v(_vm._s(_vm._f("dateTimeFormat")(round.createdAt)))])]),_c('tr',[_vm._m(9,true),_c('td',[_vm._v(_vm._s(round.updated_by))])]),_c('tr',[_vm._m(10,true),_c('td',[_vm._v(_vm._s(_vm._f("dateTimeFormat")(round.updatedAt)))])])])])]),(round.interviewers && round.interviewers.length !== 0)?[_vm._m(11,true),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-condensed",attrs:{"aria-describedby":"interviewerReview"}},[_vm._m(12,true),_vm._l((round.interviewers),function(interviewer,index){return _c('tbody',{key:index},[_c('tr',[_c('td',{staticClass:"col-4"},[_vm._v(" "+_vm._s(interviewer.interviewer_name)+" "),_c('br'),(
                            _vm.loggedInUser.user_id ==
                              interviewer.interviewer_id ||
                            _vm.hasAccess({
                              permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE],
                            })
                          )?_c('button',{staticClass:"btn btn-default btn-sm",staticStyle:{"margin-top":"6px"},attrs:{"disabled":round.round_result !== 'Pending'},on:{"click":function($event){return _vm.addReview(
                              interviewer.id,
                              interviewer.interviewer_remarks,
                              round.id,
                              interviewer.interviewer_id,
                              round.round_name,
                              interviewer.formMasterId,
                              interviewer.formVersionId
                            )}}},[_c('i',{staticClass:"fa fa-edit fa-lg",attrs:{"aria-label":"Add or Edit review"}}),_vm._v("Review ")]):_vm._e()]),(
                          interviewer.formMasterId &&
                          interviewer.interviewer_remarks
                        )?_c('td',{staticClass:"col-8"},[_c('table',{staticClass:"w-100",attrs:{"aria-describedby":"interviewerRemarks"}},[_c('tbody',[(
                                _vm.isValidJSON(
                                  interviewer.interviewer_remarks
                                ) &&
                                JSON.parse(interviewer.interviewer_remarks)[
                                  'model'
                                ]
                              )?_vm._l((JSON.parse(
                                  interviewer.interviewer_remarks
                                )['label']),function(value,property){return _c('tr',{key:property},[_c('th',{staticClass:"border-0 p-1 m-0"},[_vm._v(" "+_vm._s(property)+" ")]),_c('td',{staticClass:"border-0 p-1 m-0"},[_vm._v(_vm._s(value))])])}):(
                                _vm.isValidJSON(interviewer.interviewer_remarks)
                              )?_vm._l((JSON.parse(
                                  interviewer.interviewer_remarks
                                )),function(value,property){return _c('tr',{key:property},[(_vm.forms[interviewer.formVersionId])?_c('th',{staticClass:"border-0 p-1 m-0"},[_vm._v(" "+_vm._s(_vm.forms[interviewer.formVersionId] ? _vm.forms[interviewer.formVersionId][ property ] : property)+" ")]):_vm._e(),_c('td',{staticClass:"border-0 p-1 m-0"},[_vm._v(_vm._s(value))])])}):[_c('tr',[_c('th',{staticClass:"border-0 p-1 m-0"},[_vm._v("Response")]),_c('td',{staticClass:"border-0 p-1 m-0"},[_vm._v(" "+_vm._s(interviewer.interviewer_remarks)+" ")])])]],2)])]):_c('safe-content',{attrs:{"className":"col-md-9","content":interviewer.interviewer_remarks,"tag":"td"}})],1)])})],2)])]:_vm._e(),(round.reviewers && round.reviewers.length !== 0)?[_vm._m(13,true),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-condensed",attrs:{"aria-describedby":"reviewerReview"}},[_vm._m(14,true),_vm._l((round.reviewers),function(reviewer,index){return _c('tbody',{key:index},[_c('tr',[_c('td',{staticClass:"col-4"},[_vm._v(" "+_vm._s(reviewer.reviewer_name)+" "),_c('br'),(
                            _vm.loggedInUser.user_id == reviewer.reviewer_id ||
                            _vm.hasAccess({
                              permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE],
                            })
                          )?_c('button',{staticClass:"btn btn-default btn-sm",staticStyle:{"margin-top":"6px"},attrs:{"disabled":round.round_result !== 'Pending'},on:{"click":function($event){return _vm.addReviews(
                              reviewer.id,
                              reviewer.reviewer_remarks,
                              round.id,
                              reviewer.reviewer_id,
                              round.round_name,
                              reviewer.formMasterId,
                              reviewer.formVersionId
                            )}}},[_c('i',{staticClass:"fa fa-edit fa-lg",attrs:{"aria-hidden":"true"}}),_vm._v(" Review ")]):_vm._e()]),(
                          reviewer.formMasterId && reviewer.reviewer_remarks
                        )?_c('td',{staticClass:"col-8"},[_c('table',{staticClass:"w-100",attrs:{"aria-describedby":"reviewerRemarks"}},[_c('tbody',[(
                                JSON.parse(reviewer.reviewer_remarks)['model']
                              )?_vm._l((JSON.parse(
                                  reviewer.reviewer_remarks
                                )['label']),function(value,property){return _c('tr',{key:property},[_c('th',{staticClass:"border-0 p-1 m-0"},[_vm._v(" "+_vm._s(property)+" ")]),_c('td',{staticClass:"border-0 p-1 m-0"},[_vm._v(_vm._s(value))])])}):_vm._l((JSON.parse(
                                  reviewer.reviewer_remarks
                                )),function(value,property){return _c('tr',{key:property},[_c('th',{staticClass:"border-0 p-1 m-0"},[_vm._v(" "+_vm._s(_vm.forms[reviewer.formVersionId] ? _vm.forms[reviewer.formVersionId][ property ] : property)+" ")]),_c('td',{staticClass:"border-0 p-1 m-0"},[_vm._v(_vm._s(value))])])})],2)])]):_c('safe-content',{attrs:{"className":"col-md-9","content":reviewer.reviewer_remarks,"tag":"td"}})],1)])})],2)])]:_vm._e()],2)])}),0),_c('div',{staticClass:"row mx-3"},[(_vm.applicantRoundsData.length == 0)?_c('div',{staticClass:"well my-3"},[_c('p',{staticClass:"col-md-12 pt-3 text-center"},[_vm._v("No rounds created yet.")])]):_vm._e()])]),_c('add-score-modal',{attrs:{"roundData":_vm.roundData}}),_c('to-be-mailed',{attrs:{"modal-id":"ToBeMailedRoundInfo","flag":_vm.flagToBePassed,"applicantData":_vm.applicantDataForConflict,"roundData":_vm.rounDataToBeMailed}}),_c('add-interview-review-model',{attrs:{"interviewData":_vm.interviewData}}),_c('form-render-modal',{attrs:{"interviewData":_vm.interviewData}}),_c('common-modal',{attrs:{"id":_vm.commonModalData.id,"modal-title":_vm.commonModalData.title,"modal-body":_vm.commonModalData.body,"show-cancel":_vm.commonModalData.showCancel,"modal-color":_vm.commonModalData.color,"modal-size-class":_vm.commonModalData.sizeClass},on:{"actionPerformed":_vm.emitOnCurrent}})],1):_c('div'),(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.ROUND.CREATE] }))?_c('add-round-modal',{attrs:{"applicantData":_vm.applicantDataForConflict}}):_vm._e(),(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.ROUND.UPDATE] }))?_c('edit-round-modal',{attrs:{"applicantData":_vm.applicantDataForConflict}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-12 px-0"},[_c('h4',[_c('strong',[_vm._v("Applicant's Rounds")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"col-6 col-md-4",attrs:{"id":"Scheduled On"}},[_c('strong',[_vm._v("Scheduled On")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-6 col-md-4"},[_c('strong',[_vm._v("Moodle Username")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-6 col-md-4"},[_c('strong',[_vm._v("Moodle Password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-6 col-md-4"},[_c('strong',[_vm._v("Created By")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-6 col-md-4"},[_c('strong',[_vm._v("Score")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-6 col-md-4"},[_c('strong',[_vm._v("Grade")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-6 col-md-4"},[_c('strong',[_vm._v("Comments")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-6 col-md-4"},[_c('strong',[_vm._v("Created On")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-6 col-md-4"},[_c('strong',[_vm._v("Last Updated By")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-6 col-md-4"},[_c('strong',[_vm._v("Last Updated On")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('strong',[_vm._v("Reviews")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('th',{attrs:{"id":"Interviewer"}},[_vm._v("Interviewer")]),_c('th',{attrs:{"id":"Review"}},[_vm._v("Review")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('strong',[_vm._v("Reviews")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('th',{attrs:{"id":"Reviewer"}},[_vm._v("Reviewer")]),_c('th',{attrs:{"id":"Review"}},[_vm._v("Review")])])
}]

export { render, staticRenderFns }