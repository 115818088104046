<template>
  <div v-if="params.data && params.data.currentRoundData" style="height: 100%">
    <div
      v-if="this.params.data.currentRoundData['round_result'] === 'Pending'"
      style="height: 100%; display: flex; align-items: center"
    >
      <template>
        <button
          v-if="this.params.data.currentRoundData['round_result'] === 'Pending'"
          @click="passApplicantRound"
          title="pass round"
          class="btn btn-sm p-0 px-1 mx-1 bg-success"
        >
          <i class="fa fa-check" aria-hidden="true"></i>
        </button>
        <button
          v-if="this.params.data.currentRoundData['round_result'] === 'Pending'"
          @click="failApplicantRound"
          title="fail round"
          class="btn btn-sm p-0 px-1 mx-1 bg-danger"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </template>
    </div>
    <div v-else>
      <p
        style="line-height: 24px"
        v-if="this.params.data.currentRoundData['round_result'] === 'Passed'"
      >
        {{ this.params.data.currentRoundData["round_result"] }}
      </p>
      <p
        style="line-height: 24px"
        v-if="this.params.data.currentRoundData['round_result'] === 'Failed'"
      >
        {{ "Dropped" }}
      </p>
    </div>
  </div>
</template>

<script>
import { hasAccess } from "../../User/_utils/actions";

export default {
  computed: {
    popover_target() {
      return `popover-target-${this.params.data.id}`;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    passApplicantRound() {
      if (this.params && this.params.context) {
        this.params.context.componentParent.passApplicantRound(
          this.params.data
        );
      }
    },
    failApplicantRound() {
      if (this.params && this.params.context) {
        this.params.context.componentParent.failApplicantRound(
          this.params.data
        );
      }
    },
    // cancelApplicantRound() {
    //     if (this.params && this.params.context) {
    //         this.params.context.componentParent.cancelApplicantRoundSelectMailTemplate(this.params.data)
    //     }
    // },
  },
  beforeMount() {
    this.value = this.params.value;
  },
};
</script>

<style scoped>
.btn:focus {
  outline: none;
  box-shadow: none;
  color: white;
}

.custom-li {
  margin: 0;
  padding: 0;
  border: 0;
}
</style>
