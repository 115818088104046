import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  applicantsData: [],
  entitiesData: [],
  storeTemplates: [],
  campusData: [],
  availableMailBodies: [],
  mailStatus: "INITIAL",
  key: "",
  mailUpdateSuccessStatus: false,
  campusSessionData: null,
};

/**
 * @memberof module:MailBox
 * @namespace store
 * @description central store for mailbox module
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
